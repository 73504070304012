import React, { useEffect, useRef, useState } from "react";
import BounceLoader from "components/loaders/bounce-loader";

export const PDFPreview = ({ file, width, height, padding }) => {
  const [loading, setLoading] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadAndRenderPDF = async (file) => {
      setLoading(true);
      // eslint-disable-next-line no-undef
      const loadingTask = pdfjsLib.getDocument(file);

      try {
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);

        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 3 });
        context.imageSmoothingEnabled = true;

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const formattedHeight = (viewport.height / viewport.width) * width;
        const formattedWidth = (viewport.width / viewport.height) * height;
        const newCanvasSize = {};
        if (formattedHeight > height) {
          // newCanvasSize.height = height;
          newCanvasSize.height = height;
          newCanvasSize.width = formattedWidth;
        } else {
          // newCanvasSize.width = width;
          newCanvasSize.width = width;
          newCanvasSize.height = formattedHeight;
        }

        setImageDimensions(newCanvasSize);

        await page.render({
          canvasContext: context,
          viewport: viewport,
        });
      } catch (error) {
        console.error("Error loading PDF:", error);
        window.location.reload();
      }
      setLoading(false);
    };
    if (file && width && height) {
      loadAndRenderPDF(file);
    }
  }, [file, width, height]);

  return (
    <>
      {loading && (
        <div style={{ position: "absolute", top: "48%", left: "43%" }}>
          <BounceLoader />
        </div>
      )}
      <canvas
        ref={canvasRef}
        style={{
          display: loading ? "none" : "block",
          position: "absolute",
          // width: width - padding * 2,
          // height: height - padding * 2,
          // width: width - padding * 2,
          // height: height - padding * 2,
          ...imageDimensions,
          top: padding,
          left: padding,
        }}
      />
    </>
  );
};
