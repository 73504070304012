import { CalendarContainer } from "react-datepicker";
import { Icon } from "components/icons";
import CustomSelect from "components/custom-select";
import {
  StyledContainer,
  StyledTopContainer,
  StyledChildrenContainer,
  StyledBottomContainer,
  StyledButtonContainer,
  StyledTimeSelectContainer,
  StyledTitle,
  StyledDescription,
  StyledCloseButton,
  StyledAddButton,
} from "./Container.styled";
import { TOP_TITLE, BOTTOM_TITLE, BOTTOM_DESCRIPTION, TIMELIST, getTimeRange } from "../../utils";

interface IContainerProps {
  children?: React.ReactNode;
  selectedTime: number;
  isDisabled?: boolean;
  onCalendarClose: () => void;
  onClickConfirm: () => void;
  onChangeSelectedTime: (t: number) => void;
  defaultCampaignLimits: number;
}

export const Container = ({
  children,
  selectedTime,
  isDisabled = false,
  onCalendarClose,
  onClickConfirm,
  onChangeSelectedTime,
  defaultCampaignLimits,
}: IContainerProps) => {
  const options = TIMELIST.map((t) => ({
    value: t,
    label: getTimeRange(t),
  }));

  return (
    <StyledContainer>
      <StyledTopContainer>
        <StyledTitle>{TOP_TITLE}</StyledTitle>
        <StyledDescription>
          Select dates for your distributions on the given location. Our scheduling system allows you to choose dates
          starting as soon as {defaultCampaignLimits} days from today.
        </StyledDescription>
        <StyledCloseButton onClick={onCalendarClose}>
          <Icon type="Close" size="m" />
        </StyledCloseButton>
      </StyledTopContainer>

      <CalendarContainer>
        <StyledChildrenContainer>{children}</StyledChildrenContainer>
      </CalendarContainer>

      <StyledBottomContainer>
        <StyledTitle>{BOTTOM_TITLE}</StyledTitle>
        <StyledDescription>{BOTTOM_DESCRIPTION}</StyledDescription>

        <StyledButtonContainer>
          <StyledTimeSelectContainer>
            <CustomSelect
              selectedValue={selectedTime}
              options={options}
              onChange={(val) => onChangeSelectedTime(val as number)}
            />
          </StyledTimeSelectContainer>

          <StyledAddButton disabled={isDisabled} onClick={onClickConfirm}>
            Add
          </StyledAddButton>
        </StyledButtonContainer>
      </StyledBottomContainer>
    </StyledContainer>
  );
};

export default Container;
