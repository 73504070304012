import { TLBounds, TLBoundsWithCenter, TLSnapLine } from "@tldraw/core";
import { defaultTextStyle, defaultEllipseStyle } from "pages/flyer/builder/drawer/state/shapes/shared";
import { DocumentFormats } from "shared/constants";
import {
  AlignStyle,
  PossibleFlyerType,
  DocumentOrientation,
  CutMarkIdsEnum,
  TDShapeType,
  TDShape,
  PAGE_ID,
  DashStyle,
  Decoration,
  ChildIndexEnum,
  StaticAssetsIdsEnum,
  PageSizeIdsEnum,
  PageSideIdsEnum,
} from "./types";
import {
  FORMATS,
  GAP_BETWEEN_PAGES,
  CUT_MARK_LENGTH,
  CUT_MARK_BIAS,
  INF_VAL,
  DOOR_HANGER_TRIANGLE_SIZE,
  DOOR_HANGER_TRIANGLE_POS,
  DOOR_HANGER_CIRCLE_SIZE,
  DOOR_HANGER_CIRCLE_POS,
  BLEED_AREA_SIZE,
  SAFE_AREA_SIZE,
} from "../constants";

const sqr = (x: number) => x * x;

const calcDistance = (aPos: number[], bPos: number[]) => sqr(aPos[0] - bPos[0]) + sqr(aPos[1] - bPos[1]);

/**
 * rotate (x, y) 90 deg around (ox, oy)
 */
const rotatePoint = (pos: number[], origin: number[], rotateToLeft: boolean) => {
  const [x, y] = [pos[0] - origin[0], pos[1] - origin[1]];

  return rotateToLeft ? [origin[0] + y, origin[1] - x] : [origin[0] - y, origin[1] + x];
};

interface ICalculateCutMarkPosition {
  format?: PossibleFlyerType;
  orientation?: DocumentOrientation;
  cutMarkId: CutMarkIdsEnum;
}

export const calculateCutMarkPosition = ({
  format = DocumentFormats.A6 as PossibleFlyerType,
  orientation = DocumentOrientation.Portrait,
  cutMarkId,
}: ICalculateCutMarkPosition) => {
  const isPortraitOrientation = orientation === DocumentOrientation.Portrait;

  const bleedSize = BLEED_AREA_SIZE[format];
  const safeSize = SAFE_AREA_SIZE[format];

  const CANVAS_WIDTH = FORMATS[format].width;
  const CANVAS_HEIGHT = FORMATS[format].height;

  const PAGE_WIDTH = isPortraitOrientation ? CANVAS_WIDTH : CANVAS_HEIGHT;
  const PAGE_HEIGHT = isPortraitOrientation ? CANVAS_HEIGHT : CANVAS_WIDTH;

  const delta = isPortraitOrientation ? 0 : (CANVAS_HEIGHT - CANVAS_WIDTH) / 2;

  const getValueWithoutDelta = () => {
    switch (cutMarkId) {
      case CutMarkIdsEnum.FrontCutMarkLTT:
        return [bleedSize + safeSize, bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS];

      case CutMarkIdsEnum.FrontCutMarkLTL:
        return [bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS, bleedSize + safeSize];

      case CutMarkIdsEnum.FrontCutMarkRTT:
        return [PAGE_WIDTH - bleedSize - safeSize, bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS];

      case CutMarkIdsEnum.FrontCutMarkRTR:
        return [PAGE_WIDTH - bleedSize + CUT_MARK_BIAS, bleedSize + safeSize];

      case CutMarkIdsEnum.FrontCutMarkLBB:
        return [bleedSize + safeSize, PAGE_HEIGHT - bleedSize + CUT_MARK_BIAS];

      case CutMarkIdsEnum.FrontCutMarkLBL:
        return [bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS, PAGE_HEIGHT - bleedSize - safeSize];

      case CutMarkIdsEnum.FrontCutMarkRBB:
        return [PAGE_WIDTH - bleedSize - safeSize, PAGE_HEIGHT - bleedSize + CUT_MARK_BIAS];

      case CutMarkIdsEnum.FrontCutMarkRBR:
        return [PAGE_WIDTH - bleedSize + CUT_MARK_BIAS, PAGE_HEIGHT - bleedSize - safeSize];

      case CutMarkIdsEnum.BackCutMarkLTT:
        return [bleedSize + safeSize + PAGE_WIDTH + GAP_BETWEEN_PAGES, bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS];

      case CutMarkIdsEnum.BackCutMarkLTL:
        return [bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS + PAGE_WIDTH + GAP_BETWEEN_PAGES, bleedSize + safeSize];

      case CutMarkIdsEnum.BackCutMarkRTT:
        return [
          PAGE_WIDTH - bleedSize - safeSize + PAGE_WIDTH + GAP_BETWEEN_PAGES,
          bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS,
        ];

      case CutMarkIdsEnum.BackCutMarkRTR:
        return [PAGE_WIDTH - bleedSize + CUT_MARK_BIAS + PAGE_WIDTH + GAP_BETWEEN_PAGES, bleedSize + safeSize];

      case CutMarkIdsEnum.BackCutMarkLBB:
        return [bleedSize + safeSize + PAGE_WIDTH + GAP_BETWEEN_PAGES, PAGE_HEIGHT - bleedSize + CUT_MARK_BIAS];

      case CutMarkIdsEnum.BackCutMarkLBL:
        return [
          bleedSize - CUT_MARK_LENGTH - CUT_MARK_BIAS + PAGE_WIDTH + GAP_BETWEEN_PAGES,
          PAGE_HEIGHT - bleedSize - safeSize,
        ];

      case CutMarkIdsEnum.BackCutMarkRBB:
        return [
          PAGE_WIDTH - bleedSize - safeSize + PAGE_WIDTH + GAP_BETWEEN_PAGES,
          PAGE_HEIGHT - bleedSize + CUT_MARK_BIAS,
        ];

      case CutMarkIdsEnum.BackCutMarkRBR:
        return [
          PAGE_WIDTH - bleedSize + CUT_MARK_BIAS + PAGE_WIDTH + GAP_BETWEEN_PAGES,
          PAGE_HEIGHT - bleedSize - safeSize,
        ];

      default:
        return [0, 0];
    }
  };

  const pos = getValueWithoutDelta();

  return [pos[0] - delta, pos[1] + delta];
};

interface ICalculateOutsideRectanglePosition {
  format?: PossibleFlyerType;
  orientation?: DocumentOrientation;
  rectangleId: StaticAssetsIdsEnum;
}

export const calculateOutsideRectanglePosition = ({
  format = DocumentFormats.A6 as PossibleFlyerType,
  orientation = DocumentOrientation.Portrait,
  rectangleId,
}: ICalculateOutsideRectanglePosition) => {
  const isPortraitOrientation = orientation === DocumentOrientation.Portrait;

  const CANVAS_WIDTH = FORMATS[format].width;
  const CANVAS_HEIGHT = FORMATS[format].height;

  const delta = 0;
  const delta_rot = isPortraitOrientation ? 0 : (CANVAS_HEIGHT - CANVAS_WIDTH) / 2;

  switch (rectangleId) {
    case StaticAssetsIdsEnum.TopRectangle:
      if (isPortraitOrientation) {
        return [-INF_VAL, -INF_VAL];
      } else {
        return [-INF_VAL, -INF_VAL + delta_rot];
      }
    case StaticAssetsIdsEnum.BottomRectangle:
      if (isPortraitOrientation) {
        return [-INF_VAL, CANVAS_HEIGHT - delta];
      } else {
        return [-INF_VAL, CANVAS_HEIGHT - delta - delta_rot];
      }
    case StaticAssetsIdsEnum.LeftRectangle:
      if (isPortraitOrientation) {
        return [-INF_VAL, -INF_VAL];
      } else {
        return [-INF_VAL - delta_rot, -INF_VAL];
      }
    case StaticAssetsIdsEnum.RightRectangle:
      if (isPortraitOrientation) {
        return [2 * CANVAS_WIDTH + GAP_BETWEEN_PAGES - delta, -INF_VAL];
      } else {
        return [2 * CANVAS_HEIGHT + GAP_BETWEEN_PAGES - delta - delta_rot, -INF_VAL];
      }
    case StaticAssetsIdsEnum.MiddleRectangle:
      if (isPortraitOrientation) {
        return [CANVAS_WIDTH - delta, -INF_VAL];
      } else {
        return [CANVAS_HEIGHT - delta - delta_rot, -INF_VAL];
      }
    default:
      return [0, 0];
  }
};

interface ICalculationQRCodePosition {
  format?: PossibleFlyerType;
  orientation?: DocumentOrientation;
  originalPoint?: number[];
  size?: number[];
}

export const calculateQRCodePosition = ({
  format = DocumentFormats.A6 as PossibleFlyerType,
  orientation = DocumentOrientation.Portrait,
  originalPoint = [0, 0],
  size = [50, 50],
}: ICalculationQRCodePosition) => {
  const isPortraitOrientation = orientation === DocumentOrientation.Portrait;

  const CANVAS_WIDTH = FORMATS[format].width;
  const CANVAS_HEIGHT = FORMATS[format].height;

  const delta_rot = CANVAS_HEIGHT - CANVAS_WIDTH;

  const pos = isPortraitOrientation ? [...originalPoint] : [originalPoint[0], originalPoint[1] + size[1]];

  const originF = [CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2];
  const originS = isPortraitOrientation
    ? [CANVAS_WIDTH + GAP_BETWEEN_PAGES + CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2]
    : [CANVAS_WIDTH + GAP_BETWEEN_PAGES + CANVAS_WIDTH / 2 + delta_rot, CANVAS_HEIGHT / 2];

  const isFirstPage = calcDistance(originF, originalPoint) < calcDistance(originS, originalPoint);
  const origin = isFirstPage ? [...originF] : [...originS];

  let [newXPos, newYPos] = rotatePoint(pos, origin, isPortraitOrientation);

  if (isPortraitOrientation) {
    newYPos -= size[1];
  }

  if (isPortraitOrientation) {
    if (pos[0] > origin[0]) {
      newYPos += size[1] - size[0];
    }
    if (pos[1] > origin[1]) {
      newXPos += size[1] - size[0];
    }
  } else {
    if (pos[0] > origin[0]) {
      newYPos -= size[1] - size[0];
    }
    if (pos[1] < origin[1]) {
      newXPos += size[1] - size[0];
    }
  }

  if (!isFirstPage) {
    newXPos += isPortraitOrientation ? delta_rot : -delta_rot;
  }

  return [newXPos, newYPos];
};

export const getPageShape = (id: string, point: number[], size: number[]): TDShape => {
  return {
    id: id,
    type: TDShapeType.Rectangle,
    name: "Rectangle",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.Lowest,
    point,
    size,
    rotation: 0,
    isTemplate: true,
    isLocked: true,
    style: {
      fillColor: "#ffffff",
      strokeColor: "#AEAFC7",
      strokeWidth: 0,
      isStroked: true,
      rounded: 0,
      transparency: 100,
      fontStyle: [],
      fontSize: 12,
      isFilled: true,
      dash: DashStyle.Solid,
      scale: 1,
      isRoundingVisible: true,
      isOpacityVisible: true,
      isInnerShadowVisible: true,
      isOuterShadowVisible: true,
      outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
      outerShadowColor: "#AEAFC7",
      innerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
      innerShadowColor: "#AEAFC7",
    },
  };
};

export const getQrCodeShape = (id: string, point: number[], assetId: string): TDShape => {
  return {
    id,
    type: TDShapeType.QRCode,
    name: "qrCode",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.Highest,
    point,
    size: [50, 50 * 1.18],
    rotation: 0,
    isTemplate: false,
    isLocked: false,
    assetId,
    style: {
      fillColor: "#fff",
      strokeColor: "#EE4360",
      strokeWidth: 0,
      rounded: 11,
      transparency: 100,
      fontStyle: [],
      isStroked: true,
      scale: 1,
    },
  };
};

export const getArrowShape = (
  id: string,
  point: number[],
  endPoint: number[],
  bendPoint: number[],
  isArrow = false,
  isHighest = false
): TDShape => {
  return {
    id,
    type: TDShapeType.Arrow,
    name: "Line",
    label: "",
    labelPoint: [0.5, 0.5],
    parentId: PAGE_ID,
    childIndex: isHighest ? ChildIndexEnum.Highest : ChildIndexEnum.Middle,
    point,
    rotation: 0,
    bend: 0,
    isLocked: true,
    isTemplate: true,
    decorations: {
      start: isArrow ? Decoration.Arrow : undefined,
      end: isArrow ? Decoration.Arrow : undefined,
    },
    handles: {
      start: {
        id: "start",
        canBind: false,
        index: 0,
        point: [0, 0],
      },
      end: {
        id: "end",
        canBind: false,
        index: 1,
        point: endPoint,
      },
      bend: {
        id: "bend",
        canBind: false,
        index: 2,
        point: bendPoint,
      },
    },
    style: {
      strokeColor: "#798498",
      strokeWidth: 1,
      rounded: 0,
      isStroked: true,
      transparency: 100,
      dash: DashStyle.Solid,
      isOpacityVisible: true,
      isOuterShadowVisible: true,
      outerShadow: {
        blur: 0,
        spread: 0,
        x: 0,
        y: 0,
      },
    },
  };
};

export const getCutMarkShape = (id: string, point: number[], endPoint: number[], bendPoint: number[]): TDShape => {
  return getArrowShape(id, point, endPoint, bendPoint);
};

export const getGuideLinesShape = (id: string, point: number[], size: number[]): TDShape => {
  return {
    id,
    type: TDShapeType.Rectangle,
    name: "Rectangle",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.Middle,
    point,
    size,
    rotation: 0,
    isTemplate: true,
    isLocked: true,
    style: {
      fillColor: "#fff",
      strokeColor: "#EE4360",
      strokeWidth: 1,
      rounded: 0,
      transparency: 100,
      fontStyle: [],
      isStroked: true,
      isRoundingVisible: true,
      fontSize: 12,
      isFilled: false,
      dash: DashStyle.Solid,
      scale: 1,
    },
  };
};

export const getImageShape = (
  id: string,
  point: number[],
  size: number[],
  rotation: number,
  assetId: string
): TDShape => {
  return {
    id,
    type: TDShapeType.Image,
    name: "image",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.Highest,
    point,
    size,
    rotation,
    isGhost: true,
    isTemplate: true,
    isLocked: true,
    assetId,
    style: {
      fillColor: "#fff",
      strokeColor: "#EE4360",
      strokeWidth: 0,
      rounded: 0,
      transparency: 100,
      fontStyle: [],
      isStroked: true,
      isOpacityVisible: true,
      isOuterShadowVisible: true,
      scale: 1,
      // outerShadow: { x: 0, y: 0, spread: 0, blur: 0 },
      // outerShadowColor: "#AEAFC7",
    },
  };
};

export const getDoorHangerRectangleShape = (id: string, point: number[], size: number[]): TDShape => {
  return {
    id,
    type: TDShapeType.Rectangle,
    name: "Rectangle",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.High,
    point,
    size,
    rotation: 0,
    isTemplate: true,
    isLocked: true,
    style: {
      fillColor: "#D9D9D9",
      isFilled: true,
      isStroked: false,
    },
  };
};

export const getTextShape = (
  id: string,
  point: number[],
  size: number[],
  rotation: number,
  label: string,
  font = "Open Sans",
  fontSize = 12,
  fillColor = "#707087",
  textAlign = AlignStyle.Middle,
  verticalTextAlign = AlignStyle.Middle
): TDShape => {
  return {
    id,
    type: TDShapeType.TextArea,
    name: "Text",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.Highest,
    point,
    size,
    rotation,
    isTemplate: true,
    isLocked: true,
    style: {
      ...defaultTextStyle,
      font,
      fontSize,
      fillColor,
      textAlign,
      verticalTextAlign,
    },
    label,
    labelPoint: [0.5, 0.5],
  };
};

export const getEllipseShape = (id: string, point: number[], radius: number[], fillColor = "#e2842f"): TDShape => {
  return {
    id,
    type: TDShapeType.Ellipse,
    name: "Ellipse",
    parentId: PAGE_ID,
    childIndex: ChildIndexEnum.High,
    point,
    radius,
    rotation: 0,
    isTemplate: true,
    isLocked: true,
    style: {
      ...defaultEllipseStyle,
      fillColor,
    },
  };
};

export const isContains = (aPoint: number[], aSize: number[], bPoint: number[], bSize: number[]) => {
  return (
    aPoint[0] < bPoint[0] &&
    bPoint[0] + bSize[0] < aPoint[0] + aSize[0] &&
    aPoint[1] < bPoint[1] &&
    bPoint[1] + bSize[1] < aPoint[1] + aSize[1]
  );
};

export const isOutside = (aPoint: number[], aSize: number[], bPoint: number[], bSize: number[]) => {
  return (
    aPoint[0] + aSize[0] <= bPoint[0] ||
    bPoint[0] + bSize[0] <= aPoint[0] ||
    aPoint[1] + aSize[1] <= bPoint[1] ||
    bPoint[1] + bSize[1] <= aPoint[1]
  );
};

const func = (x: number, isUpper: boolean) => {
  const gap_1 = 25;
  const gap_2 =
    BLEED_AREA_SIZE[DocumentFormats.DOOR_HANGER as PossibleFlyerType] +
    SAFE_AREA_SIZE[DocumentFormats.DOOR_HANGER as PossibleFlyerType];
  const gap_3 =
    BLEED_AREA_SIZE[DocumentFormats.DOOR_HANGER as PossibleFlyerType] +
    SAFE_AREA_SIZE[DocumentFormats.DOOR_HANGER as PossibleFlyerType];

  const split_0 = 0;
  const split_1 = DOOR_HANGER_TRIANGLE_SIZE[0];
  const split_2 = DOOR_HANGER_CIRCLE_POS[0] - 5;
  const split_3 = split_2 + 12;
  const split_4 = DOOR_HANGER_CIRCLE_POS[0] + DOOR_HANGER_CIRCLE_SIZE[0] + gap_3;

  const radius = DOOR_HANGER_CIRCLE_SIZE[1] / 2 + gap_3;
  const cx = split_4 - radius;

  let y;

  if (x >= split_0 && x <= split_1) y = DOOR_HANGER_TRIANGLE_SIZE[1] / 2 + gap_1 - x;
  else if (split_1 < x && x <= split_2) y = gap_2;
  else if (split_2 < x && x <= split_3) y = x - split_2 + 21;
  else if (split_3 < x && x <= split_4) y = Math.sqrt(radius * radius - (x - cx) * (x - cx));
  else y = 0;

  if (!isUpper) y = -y;

  return y;
};

const isInCircle = (x: number, y: number) => {
  const radius =
    DOOR_HANGER_CIRCLE_SIZE[1] / 2 +
    BLEED_AREA_SIZE[DocumentFormats.DOOR_HANGER as PossibleFlyerType] +
    SAFE_AREA_SIZE[DocumentFormats.DOOR_HANGER as PossibleFlyerType];
  const cx = DOOR_HANGER_CIRCLE_POS[0] + DOOR_HANGER_CIRCLE_SIZE[0] - DOOR_HANGER_CIRCLE_SIZE[1] / 2;

  const dis = (x - cx) * (x - cx) + y * y;
  return dis < radius * radius;
};

export const isIntersect = (point: number[], size: number[]) => {
  const my = DOOR_HANGER_TRIANGLE_POS[1] + DOOR_HANGER_TRIANGLE_SIZE[1] / 2;

  const x = point[0];
  const y = my - point[1];
  const dx = size[0];
  const dy = size[1];

  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 2; j++) {
      if (isInCircle(x + i * dx, y - j * dy)) return true;
      if (isInCircle(FORMATS.DOOR_HANGER.width * 2 + GAP_BETWEEN_PAGES - (x + i * dx), y - j * dy)) return true;
    }
  }

  for (let i = 0; i <= 2; i++) {
    for (let j = 0; j <= 1; j++) {
      const val = func(x + dx * (i / 2), !j);
      if (val && y - dy < val && val < y) return true;
    }
  }

  for (let i = 0; i <= 2; i++) {
    for (let j = 0; j <= 1; j++) {
      const val = func(FORMATS.DOOR_HANGER.width * 2 + GAP_BETWEEN_PAGES - (x + dx * (i / 2)), !j);
      if (val && y - dy < val && val < y) return true;
    }
  }

  return false;
};

export const reduceBoundsOnePixel = (bounds: TLBounds): TLBounds => {
  return {
    ...bounds,
    minX: bounds.minX + 1,
    maxX: bounds.maxX - 1,
    minY: bounds.minY + 1,
    maxY: bounds.maxY - 1,
    width: bounds.width - 2,
    height: bounds.height - 2,
  };
};

const checkXLine = (snapLine: TLSnapLine): boolean =>
  snapLine.reduce((acc: boolean, point) => {
    if (point[1] !== snapLine[0][1]) acc = false;
    return acc;
  }, true);

export const extractSnapLines = (snapLines: TLSnapLine[], bounds: TLBoundsWithCenter[], EPS: number): TLSnapLine[] =>
  snapLines.reduce((result: TLSnapLine[], snapLine) => {
    const isXLine = checkXLine(snapLine);

    const { pos, flag } = bounds.reduce(
      ({ pos, flag }, bound) => {
        if (isXLine && Math.abs(bound.minY - snapLine[0][1]) < EPS) {
          pos = bound.minY;
          flag = true;
        }
        if (isXLine && Math.abs(bound.maxY - snapLine[0][1]) < EPS) {
          pos = bound.maxY;
          flag = true;
        }
        if (!isXLine && Math.abs(bound.minX - snapLine[0][0]) < EPS) {
          pos = bound.minX;
          flag = true;
        }
        if (!isXLine && Math.abs(bound.maxX - snapLine[0][0]) < EPS) {
          pos = bound.maxX;
          flag = true;
        }

        return { pos, flag };
      },
      { pos: 0, flag: false }
    );

    if (flag) {
      result.push(
        snapLine.map((point) => {
          if (isXLine) point[1] = pos;
          else point[0] = pos;
          return point;
        })
      );
    }

    return result;
  }, []);

export const calculateOffset = (snapLines: TLSnapLine[], currentPoint: number[], EPS: number): number[] =>
  snapLines.reduce(
    (offset: number[], snapLine) => {
      const isXLine = checkXLine(snapLine);
      if (isXLine && Math.abs(currentPoint[1] - snapLine[0][1]) < EPS) {
        offset[1] = currentPoint[1] - snapLine[0][1];
      }
      if (!isXLine && Math.abs(currentPoint[0] - snapLine[0][0]) < EPS) {
        offset[0] = currentPoint[0] - snapLine[0][0];
      }

      return offset;
    },
    [0, 0]
  );

export const extractSnapBoundIds = (): string[] =>
  [
    StaticAssetsIdsEnum.CutLineFront,
    StaticAssetsIdsEnum.CutLineBack,
    StaticAssetsIdsEnum.FrontDoorHangerTriangle,
    StaticAssetsIdsEnum.FrontDoorHangerCircle,
    StaticAssetsIdsEnum.BackDoorHangerTriangle,
    StaticAssetsIdsEnum.BackDoorHangerCircle,
    StaticAssetsIdsEnum.TopRectangle,
    StaticAssetsIdsEnum.BottomRectangle,
    StaticAssetsIdsEnum.LeftRectangle,
    StaticAssetsIdsEnum.RightRectangle,
    StaticAssetsIdsEnum.MiddleRectangle,
    StaticAssetsIdsEnum.LeftTriangleRectangle,
    StaticAssetsIdsEnum.RightTriangleRectangle,
    PageSizeIdsEnum.PageHeight,
    PageSizeIdsEnum.PageWidth,
    PageSizeIdsEnum.PageHeightText,
    PageSizeIdsEnum.PageWidthText,
    PageSideIdsEnum.PageFront,
    PageSideIdsEnum.PageBack,
  ] as string[];
