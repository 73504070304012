/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import type {
  TLAsset,
  TLBinding,
  TLBoundsCorner,
  TLBoundsEdge,
  TLBoundsEventHandler,
  TLBoundsHandleEventHandler,
  TLCanvasEventHandler,
  TLHandle,
  TLKeyboardEventHandler,
  TLPage,
  TLPageState,
  TLPinchEventHandler,
  TLPointerEventHandler,
  TLShape,
  TLShapeBlurHandler,
  TLShapeCloneHandler,
  TLSnapLine,
  TLUser,
  TLWheelEventHandler,
} from "@tldraw/core";
import { DocumentFormats, DMDocumentFormats } from "shared/constants";

/* -------------------------------------------------- */
/*                         App                        */
/* -------------------------------------------------- */

// A base class for all classes that handle events from the Renderer,
// including TDApp and all Tools.
export class TDEventHandler {
  onPinchStart?: TLPinchEventHandler;
  onPinchEnd?: TLPinchEventHandler;
  onPinch?: TLPinchEventHandler;
  onKeyDown?: TLKeyboardEventHandler;
  onKeyUp?: TLKeyboardEventHandler;
  onPointerMove?: TLPointerEventHandler;
  onPointerUp?: TLPointerEventHandler;
  onPan?: TLWheelEventHandler;
  onZoom?: TLWheelEventHandler;
  onPointerDown?: TLPointerEventHandler;
  onPointCanvas?: TLCanvasEventHandler;
  onDoubleClickCanvas?: TLCanvasEventHandler;
  onRightPointCanvas?: TLCanvasEventHandler;
  onDragCanvas?: TLCanvasEventHandler;
  onReleaseCanvas?: TLCanvasEventHandler;
  onPointShape?: TLPointerEventHandler;
  onDoubleClickShape?: TLPointerEventHandler;
  onRightPointShape?: TLPointerEventHandler;
  onDragShape?: TLPointerEventHandler;
  onHoverShape?: TLPointerEventHandler;
  onUnhoverShape?: TLPointerEventHandler;
  onReleaseShape?: TLPointerEventHandler;
  onPointBounds?: TLBoundsEventHandler;
  onDoubleClickBounds?: TLBoundsEventHandler;
  onRightPointBounds?: TLBoundsEventHandler;
  onDragBounds?: TLBoundsEventHandler;
  onHoverBounds?: TLBoundsEventHandler;
  onUnhoverBounds?: TLBoundsEventHandler;
  onReleaseBounds?: TLBoundsEventHandler;
  onPointBoundsHandle?: TLBoundsHandleEventHandler;
  onDoubleClickBoundsHandle?: TLBoundsHandleEventHandler;
  onRightPointBoundsHandle?: TLBoundsHandleEventHandler;
  onDragBoundsHandle?: TLBoundsHandleEventHandler;
  onHoverBoundsHandle?: TLBoundsHandleEventHandler;
  onUnhoverBoundsHandle?: TLBoundsHandleEventHandler;
  onReleaseBoundsHandle?: TLBoundsHandleEventHandler;
  onPointHandle?: TLPointerEventHandler;
  onDoubleClickHandle?: TLPointerEventHandler;
  onRightPointHandle?: TLPointerEventHandler;
  onDragHandle?: TLPointerEventHandler;
  onHoverHandle?: TLPointerEventHandler;
  onUnhoverHandle?: TLPointerEventHandler;
  onReleaseHandle?: TLPointerEventHandler;
  onShapeBlur?: TLShapeBlurHandler;
  onShapeClone?: TLShapeCloneHandler;
}

// The shape of the TldrawApp's React (zustand) store
export interface TDSnapshot {
  settings: {
    isDarkMode: boolean;
    isDebugMode: boolean;
    isPenMode: boolean;
    isReadonlyMode: boolean;
    isZoomSnap: boolean;
    nudgeDistanceSmall: number;
    nudgeDistanceLarge: number;
    isFocusMode: boolean;
    isSnapping: boolean;
    showRotateHandles: boolean;
    showBindingHandles: boolean;
    showCloneHandles: boolean;
    showGrid: boolean;
  };
  appState: {
    currentStyle: ShapeStyles;
    currentPageId: string;
    hoveredId?: string;
    activeTool: TDToolType;
    isToolLocked: boolean;
    isEmptyCanvas: boolean;
    isMenuOpen: boolean;
    status: string;
    snapLines: TLSnapLine[];
    isLoading: boolean;
    disableAssets: boolean;
  };
  document: TDDocument;
  room?: {
    id: string;
    userId: string;
    users: Record<string, TDUser>;
  };
}

export type TldrawPatch = Patch<TDSnapshot>;

export const PAGE_ID = "page" as const;

export enum TemplateIdsEnum {
  Front = "front",
  Back = "back",
}

export enum GuidelinesIdsEnum {
  FrontGL = "frontGL",
  BackGL = "backGL",
}

export enum CutMarkIdsEnum {
  FrontCutMarkLTT = "frontCutMarkLTT",
  FrontCutMarkLTL = "frontCutMarkLTL",
  FrontCutMarkRTT = "frontCutMarkRTT",
  FrontCutMarkRTR = "frontCutMarkRTR",
  FrontCutMarkLBB = "frontCutMarkLBB",
  FrontCutMarkLBL = "frontCutMarkLBL",
  FrontCutMarkRBB = "frontCutMarkRBB",
  FrontCutMarkRBR = "frontCutMarkRBR",
  BackCutMarkLTT = "backCutMarkLTT",
  BackCutMarkLTL = "backCutMarkLTL",
  BackCutMarkRTT = "backCutMarkRTT",
  BackCutMarkRTR = "backCutMarkRTR",
  BackCutMarkLBB = "backCutMarkLBB",
  BackCutMarkLBL = "backCutMarkLBL",
  BackCutMarkRBB = "backCutMarkRBB",
  BackCutMarkRBR = "backCutMarkRBR",
}

export enum StaticAssetsIdsEnum {
  QrCode = "qrCode",
  QrCodeForTearaway = "qrCodeForTearaway",
  DiecutLineFront = "diecutLineFront",
  DiecutLineBack = "diecutLineBack",
  CutLineFront = "cutLineFront",
  CutLineBack = "cutLineBack",
  FrontDoorHangerTriangle = "frontDoorHangerTriangle",
  FrontDoorHangerCircle = "frontDoorHangerCircle",
  BackDoorHangerTriangle = "backDoorHangerTriangle",
  BackDoorHangerCircle = "backDoorHangerCircle",
  TopRectangle = "topRectangle",
  BottomRectangle = "bottomRectangle",
  LeftRectangle = "leftRectangle",
  RightRectangle = "rightRectangle",
  MiddleRectangle = "middleRectangle",
  LeftTriangleRectangle = "leftTriangleRectangle",
  RightTriangleRectangle = "rightTriangleRectangle",
  TearawayCutLineFront = "TearawayCutLineFront",
  TearawayCutLineBack = "TearawayCutLineBack",
  TearawayFlyerBuilderCutlineFront = "tearawayFlyerBuilderCutlineFront",
  TearawayFlyerBuilderCutlineBack = "tearawayFlyerBuilderCutlineBack",
  TearawaySafetyLineFront = "tearawaySafetyLineFront",
  TearawaySafetyLineBack = "tearawaySafetyLineBack",
  A6SafetyLineFront = "a6SafetyLineFront",
  A6SafetyLineBack = "a6SafetyLineBack",
}

export enum PageSizeIdsEnum {
  PageHeight = "pageHeight",
  PageWidth = "pageWidth",
  PageHeightText = "pageHeightText",
  PageWidthText = "pageWidthText",
}

export enum PageSideIdsEnum {
  PageFront = "pageFront",
  PageBack = "pageBack",
}

export enum DoorHangerAssetsIdsEnum {
  QrCode = "qrCode",
  DoorHangerTriangleFrontSrc = "doorHangerTriangleFrontSrc",
  DoorHangerCircleFrontSrc = "doorHangerCircleFrontSrc",
  DoorHangerTriangleBackSrc = "doorHangerTriangleBackSrc",
  DoorHangerCircleBackSrc = "doorHangerCircleBackSrc",
  DiecutLineFrontSrc = "diecutLineFrontSrc",
  DiecutLineBackSrc = "diecutLineBackSrc",
  CutLineFrontSrc = "cutLineFrontSrc",
  CutLineBackSrc = "cutLineBackSrc",
  SafetyLineFrontSrc = "safetyLineFrontSrc",
  SafetyLineBackSrc = "safetyLineBackSrc",
}

export enum TearawayAssetsIdsEnum {
  TearawaySafetyLineSrc = "tearawaySafetyLineSrc",
  TearawayCutLineSrc = "tearawayCutLineSrc",
  TearawayFlyerBuilderCutLineSrc = "tearawayFlyerBuilderCutLineSrc",
}

export enum TemplateAssetsIdsEnum {
  TemplateBackgroundSrc = "templateBackgroundSrc",
  TemplateLogoSrc = "TemplateLogoSrc",
}

export enum A6AssetsIdsEnum {
  A6SafetyLineSrc = "a6SafetyLineSrc",
}

export enum TemplateShapesIdsEnum {
  TemplateFrontBackground = "templateFrontBackground",
  TemplateBackBackground = "templateBackBackground",
  TemplateFrontWhiteRectangle = "templateFrontWhiteRectangle",
  TemplateBackWhiteRectangle = "templateBackWhiteRectangle",
  TemplateLogo = "templateLogo",
  TemplateText_1 = "tmplateText_1",
  TemplateText_1_1 = "templateText_1_1",
  TemplateText_1_2 = "templateText_1_2",
  TemplateText_1_2_For_Tearyway = "templateText_1_2_For_Tearyway",
  TemplateText_2 = "templateText_2",
  TemplateText_2_1 = "templateText_2_1",
  TemplateText_2_1_1 = "templateText_2_1_1",
  TemplateText_2_2 = "templateText_2_2",
  TemplateText_2_2_1 = "templateText_2_2_1",
  TemplateText_2_3 = "templateText_2_3",
  TemplateText_2_3_1 = "templateText_2_3_1",
  TemplateBulletPoint_1 = "templateBulletPoint_1",
  TemplateBulletPoint_2 = "templateBulletPoint_2",
  TemplateBulletPoint_3 = "TemplateBulletPoint_3",
  TemplateFrontCircle = "templateFrontCircle",
  TemplateBackCircle = "templateBackCircle",
  TemplateTextInFrontCircle = "templateTextInFrontCircle",
  TemplateTextInBackCircle = "templateTextInBackCircle",
}

export enum ChildIndexEnum {
  Lowest = 0,
  Low = 99999,
  Middle = 100000,
  High = 100001,
  Highest = 100002,
  Max = 999999,
}

export type TldrawCommand = Command<TDSnapshot>;

// The shape of the files stored in JSON
export interface TDFile {
  name: string;
  fileHandle: FileSystemHandle | null;
  document: TDDocument;
  assets: Record<string, unknown>;
}

// The shape of the Tldraw document

export type PossibleFlyerType = keyof typeof DocumentFormats | keyof typeof DMDocumentFormats;

export interface DocumentSettings {
  format: PossibleFlyerType;
  orientation: DocumentOrientation;
  isShowGuidelines: boolean;
}
export interface TDDocument extends DocumentSettings {
  id: string;
  name: string;
  version: number;
  pages: Record<string, TDPage>;
  pageStates: Record<string, TLPageState>;
  sides: 1 | 2;
  assets: TDAssets;
}

// The shape of a single page in the Tldraw document
export type TDPage = TLPage<TDShape, TDBinding>;

// A partial of a TDPage, used for commands / patches
export type PagePartial = {
  shapes: Patch<TDPage["shapes"]>;
  bindings: Patch<TDPage["bindings"]>;
};

// The meta information passed to TDShapeUtil components
export interface TDMeta {
  isDarkMode: boolean;
}

// The type of info given to shapes when transforming
export interface TransformInfo<T extends TLShape> {
  type: TLBoundsEdge | TLBoundsCorner;
  initialShape: T;
  scaleX: number;
  scaleY: number;
  transformOrigin: number[];
}

export interface TemplateConfig {
  name: string;
  size?: string;
  measuring?: string;
  width: number;
  height: number;
  orientation?: DocumentOrientation;
  format?: PossibleFlyerType;
  printLines?: string;
  template: string;
  printingRulesMm: string;
  printingRulesInches: string;
  example: string;
  preview: string;
  placeholder: string;
  frontSide: string;
  backSide: string;
  widthInMm: number;
  heightInMm: number;
  displayWidth: number;
  displayHeight: number;
  previewPadding: number;
  verticalArrowHeight: number;
  horizontalArrowWidth: number;
  gapForArrow?: number;
  horizontalArrow: string;
  verticalArrow: string;
}

export enum DocumentOrientation {
  Portrait = "Portrait",
  Landscape = "Landscape",
}

// The status of a TDUser
export enum TDUserStatus {
  Idle = "idle",
  Connecting = "connecting",
  Connected = "connected",
  Disconnected = "disconnected",
}

// A TDUser, for multiplayer rooms
export interface TDUser extends TLUser<TDShape> {
  activeShapes: TDShape[];
  status: TDUserStatus;
}

export type Theme = "dark" | "light";

export enum SessionType {
  Transform = "transform",
  Translate = "translate",
  TransformSingle = "transformSingle",
  Brush = "brush",
  Arrow = "arrow",
  Draw = "draw",
  Erase = "erase",
  Rotate = "rotate",
  Handle = "handle",
  Grid = "grid",
}

export enum TDStatus {
  Idle = "idle",
  PointingHandle = "pointingHandle",
  PointingBounds = "pointingBounds",
  PointingBoundsHandle = "pointingBoundsHandle",
  TranslatingLabel = "translatingLabel",
  TranslatingHandle = "translatingHandle",
  Translating = "translating",
  Transforming = "transforming",
  Rotating = "rotating",
  Pinching = "pinching",
  Brushing = "brushing",
  Creating = "creating",
  EditingText = "editing-text",
}

export type TDToolType =
  | "select"
  | "erase"
  // | TDShapeType.Text
  | TDShapeType.TextArea
  | TDShapeType.Draw
  | TDShapeType.Ellipse
  | TDShapeType.Rectangle
  | TDShapeType.Triangle
  | TDShapeType.Line
  | TDShapeType.Arrow
  | TDShapeType.Code
  | TDShapeType.QRCode;

export type Easing =
  | "linear"
  | "easeInQuad"
  | "easeOutQuad"
  | "easeInOutQuad"
  | "easeInCubic"
  | "easeOutCubic"
  | "easeInOutCubic"
  | "easeInQuart"
  | "easeOutQuart"
  | "easeInOutQuart"
  | "easeInQuint"
  | "easeOutQuint"
  | "easeInOutQuint"
  | "easeInSine"
  | "easeOutSine"
  | "easeInOutSine"
  | "easeInExpo"
  | "easeOutExpo"
  | "easeInOutExpo";

export enum MoveType {
  Backward = "backward",
  Forward = "forward",
  XBackward = "x_backward",
  XForward = "x_forward",
  ToFront = "toFront",
  ToBack = "toBack",
  ToPosition = "toPosition",
}

export enum AlignType {
  Top = "top",
  CenterVertical = "centerVertical",
  Bottom = "bottom",
  Left = "left",
  CenterHorizontal = "centerHorizontal",
  Right = "right",
}

export enum StretchType {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum DistributeType {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum FlipType {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

/* -------------------------------------------------- */
/*                       Shapes                       */
/* -------------------------------------------------- */

export enum TDShapeType {
  Sticky = "sticky",
  Ellipse = "ellipse",
  Rectangle = "rectangle",
  Triangle = "triangle",
  Draw = "draw",
  Arrow = "arrow",
  Line = "line",
  Text = "text",
  TextArea = "textarea",
  Group = "group",
  Image = "image",
  Video = "video",
  Code = "code",
  QRCode = "qrCode",
}

export enum Decoration {
  Arrow = "arrow",
}

export interface TDBaseShape extends TLShape {
  style: ShapeStyles;
  type: TDShapeType;
  label?: string;
  handles?: Record<string, TldrawHandle>;
  isTemplate?: boolean;
}

export interface DrawShape extends TDBaseShape {
  type: TDShapeType.Draw;
  points: number[][];
  isComplete: boolean;
}

// The extended handle (used for arrows)
export interface TldrawHandle extends TLHandle {
  canBind?: boolean;
  bindingId?: string;
}

export interface RectangleShape extends TDBaseShape {
  type: TDShapeType.Rectangle;
  size: number[];
  label?: string;
  labelPoint?: number[];
}

export interface TextAreaShape extends TDBaseShape {
  type: TDShapeType.TextArea;
  size: number[];
  label?: string;
  labelPoint?: number[];
}

export interface CodeShape extends TDBaseShape {
  type: TDShapeType.Code;
  size: number[];
  label?: string;
  labelPoint?: number[];
}

export interface EllipseShape extends TDBaseShape {
  type: TDShapeType.Ellipse;
  radius: number[];
  label?: string;
  labelPoint?: number[];
}

export interface TriangleShape extends TDBaseShape {
  type: TDShapeType.Triangle;
  size: number[];
  label?: string;
  labelPoint?: number[];
}

// The shape created with the arrow tool
export interface ArrowShape extends TDBaseShape {
  type: TDShapeType.Arrow;
  bend: number;
  handles: {
    start: TldrawHandle;
    bend: TldrawHandle;
    end: TldrawHandle;
  };
  decorations?: {
    start?: Decoration;
    end?: Decoration;
    middle?: Decoration;
  };
  label?: string;
  labelPoint?: number[];
}

export interface ArrowBinding extends TLBinding {
  handleId: keyof ArrowShape["handles"];
  distance: number;
  point: number[];
}

export type TDBinding = ArrowBinding;

export interface ImageShape extends TDBaseShape {
  type: TDShapeType.Image;
  size: number[];
  assetId: string;
}

export interface QRCodeShape extends TDBaseShape {
  type: TDShapeType.QRCode;
  size: number[];
  assetId: string;
}

export interface VideoShape extends TDBaseShape {
  type: TDShapeType.Video;
  size: number[];
  assetId: string;
  isPlaying: boolean;
  currentTime: number;
}

// The shape created by the text tool
export interface TextShape extends TDBaseShape {
  type: TDShapeType.Text;
  text: string;
}

// The shape created by the sticky tool
export interface StickyShape extends TDBaseShape {
  type: TDShapeType.Sticky;
  size: number[];
  text: string;
}

// The shape created when multiple shapes are grouped
export interface GroupShape extends TDBaseShape {
  type: TDShapeType.Group;
  size: number[];
  children: string[];
}

// A union of all shapes
export type TDShape =
  | RectangleShape
  | TextAreaShape
  | EllipseShape
  | TriangleShape
  | DrawShape
  | ArrowShape
  // | TextShape
  | GroupShape
  | CodeShape
  | QRCodeShape
  | ImageShape;

/* ------------------ Shape Styles ------------------ */

export enum ColorStyle {
  White = "white",
  LightGray = "lightGray",
  Gray = "gray",
  Black = "black",
  Green = "green",
  Cyan = "cyan",
  Blue = "blue",
  Indigo = "indigo",
  Violet = "violet",
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  LightBlue = "lightblue",
}

export enum SizeStyle {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum DashStyle {
  Draw = "draw",
  Solid = "solid",
  Dashed = "dashed",
  Dotted = "dotted",
}

export enum FontSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extraLarge",
}

export enum AlignStyle {
  Start = "start",
  Middle = "middle",
  End = "end",
  Justify = "justify",
}

export enum FontStyle {
  Bold = "bold",
  Italic = "italic",
  Underscore = "underscore",
  Strikethrough = "strikethrough",
}

export interface Shadow {
  x: number;
  y: number;
  blur: number;
  spread: number;
}

export interface ShapeStyles {
  fillColor?: string;
  isFilled?: boolean;

  strokeColor?: string;
  strokeWidth?: number;
  isStroked?: boolean;

  rounded?: number;
  isRoundingVisible?: boolean;

  transparency?: number;
  isOpacityVisible?: boolean;

  // size: SizeStyle;
  dash?: DashStyle;
  scale?: number;

  font?: string;
  fontSize?: number;
  fontStyle?: FontStyle[];
  textAlign?: AlignStyle;
  verticalTextAlign?: AlignStyle;

  outerShadow?: Shadow;
  outerShadowColor?: string;
  isOuterShadowVisible?: boolean;

  innerShadow?: Shadow;
  innerShadowColor?: string;
  isInnerShadowVisible?: boolean;
}

export const KeysOfStyleProperties: Array<keyof ShapeStyles> = [
  "fillColor",
  "strokeColor",
  "strokeWidth",
  "rounded",
  "transparency",
  "dash",
  "font",
  "fontSize",
  "fontStyle",
  "textAlign",
  "isFilled",
  "isStroked",
  "verticalTextAlign",
  "isOpacityVisible",
  "isRoundingVisible",
  "innerShadow",
  "outerShadow",
  "isInnerShadowVisible",
  "isOuterShadowVisible",
];

export enum TDAssetType {
  Image = "image",
  Video = "video",
}

export interface TDImageAsset extends TLAsset {
  type: TDAssetType.Image;
  src: string;
  size: number[];
}

export interface TDVideoAsset extends TLAsset {
  type: TDAssetType.Video;
  src: string;
  size: number[];
}

export type TDAsset = TDImageAsset | TDVideoAsset;

export type TDAssets = Record<string, TDAsset>;

/* -------------------------------------------------- */
/*                    Export                          */
/* -------------------------------------------------- */

export enum TDExportTypes {
  PNG = "png",
  JPG = "jpeg",
  WEBP = "webp",
  PDF = "pdf",
  SVG = "svg",
  JSON = "json",
}

export interface TDExport {
  currentPageId: string;
  name: string;
  shapes: TDShape[];
  assets: TDAssets;
  type: TDExportTypes;
  size: number[];
  serialized?: string;
}

export interface IDeltaMap {
  [id: string]: {
    prev: number[];
    next: number[];
  };
}
/* -------------------------------------------------- */
/*                    Type Helpers                    */
/* -------------------------------------------------- */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ParametersExceptFirst<F> = F extends (arg0: any, ...rest: infer R) => any ? R : never;

export type ExceptFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;

export type ExceptFirstTwo<T extends unknown[]> = T extends [any, any, ...infer U] ? U : never;

export type PropsOfType<U> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof TDShape]: TDShape[K] extends any ? (TDShape[K] extends U ? K : never) : never;
}[keyof TDShape];

export type Difference<A, B, C = A> = A extends B ? never : C;

export type Intersection<A, B, C = A> = A extends B ? C : never;

export type FilteredKeys<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];

export type RequiredKeys<T> = {
  [K in keyof T]-?: Difference<Record<string, unknown>, Pick<T, K>, K>;
}[keyof T];

export type MembersWithRequiredKey<T, U> = {
  [P in keyof T]: Intersection<U, RequiredKeys<T[P]>, T[P]>;
}[keyof T];

export type MappedByType<U extends string, T extends { type: U }> = {
  [P in T["type"]]: T extends any ? (P extends T["type"] ? T : never) : never;
};

export type ShapesWithProp<U> = MembersWithRequiredKey<MappedByType<TDShapeType, TDShape>, U>;

export type Patch<T> = Partial<{ [P in keyof T]: Patch<T[P]> }>;

export interface Command<T extends { [key: string]: any }> {
  id?: string;
  before: Patch<T>;
  after: Patch<T>;
}

export interface FileWithHandle extends File {
  handle?: FileSystemHandle;
}

export interface FileWithDirectoryHandle extends File {
  directoryHandle?: FileSystemHandle;
}

// The following typings implement the relevant parts of the File System Access
// API. This can be removed once the specification reaches the Candidate phase
// and is implemented as part of microsoft/TSJS-lib-generator.

export interface FileSystemHandlePermissionDescriptor {
  mode?: "read" | "readwrite";
}

export interface FileSystemHandle {
  readonly kind: "file" | "directory";
  readonly name: string;

  isSameEntry: (other: FileSystemHandle) => Promise<boolean>;

  queryPermission: (descriptor?: FileSystemHandlePermissionDescriptor) => Promise<PermissionState>;
  requestPermission: (descriptor?: FileSystemHandlePermissionDescriptor) => Promise<PermissionState>;
}

export interface GtagOptions {
  event: string;
  client_id: string;
  user_id: number | string;
  campaign_id?: number | string;
  flyer_format?: string;
  flyer_weight?: number | string;
  design_type?: string;
  flyer_submitted_type?: string;
  nr_of_elements_created?: number | string;
}
