import { createContext, useEffect, useState } from "react";
import posthog from "posthog-js";
import CONFIG from "config/config";

export const EDDMContext = createContext();

export const EDDMContextProvider = ({ children }) => {
  const [isEddmFeatureEnabled, setIsEddmFeatureEnabled] = useState(null);
  posthog.init(CONFIG.POSTHOG_KEY, {
    api_host: CONFIG.POSTHOG_HOST,
  });

  // eslint-disable-next-line no-console
  console.log("isEddmFeatureEnabled", isEddmFeatureEnabled);

  useEffect(() => {
    if (isEddmFeatureEnabled !== undefined && isEddmFeatureEnabled !== null) {
      return;
    }
    const posthogResponse = posthog.isFeatureEnabled("is_eddm_feature_enabled");
    if (posthogResponse === undefined && isEddmFeatureEnabled === null) {
      setIsEddmFeatureEnabled(undefined);
    } else if (posthogResponse === undefined && isEddmFeatureEnabled === undefined) {
      setIsEddmFeatureEnabled(null);
    } else if (posthogResponse !== undefined) {
      setIsEddmFeatureEnabled(posthogResponse);
    }
  }, [isEddmFeatureEnabled, posthog]);

  if (isEddmFeatureEnabled === undefined || isEddmFeatureEnabled === null) {
    return null;
  }
  return <EDDMContext.Provider value={{ isEddmEnabled: isEddmFeatureEnabled }}>{children}</EDDMContext.Provider>;
};
