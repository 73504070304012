import { makeStyles } from "@material-ui/core";
import IconButton from "pages/flyer/builder/components/Inspector/components/IconButton";
import { SECTIONS } from "pages/flyer/builder/constants";
import React from "react";
import {
  ImageIcon,
  // VariablesIcon,
  LayersIcon,
  QRIcon,
  SettingsIcon,
  ShapesIcon,
  TextIcon,
} from "../Toolbar/icons/newIcons";

interface IToolBar {
  // handleOpenPrintRuleModal?: () => void;
  selectedSection: null | string;
  setSelectedSection: (section: null | string) => void;
}

const Toolbar: React.FC<IToolBar> = ({ selectedSection, setSelectedSection }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.items}>
        <IconButton
          isActive={selectedSection === SECTIONS.SETTINGS}
          onClick={() => setSelectedSection(SECTIONS.SETTINGS)}
        >
          <SettingsIcon cypress_id={"settingsIcon"} />
          <span className={classes.toolLabel}>Settings</span>
        </IconButton>

        <IconButton isActive={selectedSection === SECTIONS.IMAGES} onClick={() => setSelectedSection(SECTIONS.IMAGES)}>
          <ImageIcon cypress_id={"imageIcon"} />
          <span className={classes.toolLabel}>Images</span>
        </IconButton>

        <IconButton isActive={selectedSection === SECTIONS.TEXT} onClick={() => setSelectedSection(SECTIONS.TEXT)}>
          <TextIcon cypress_id={"textIcon"} />
          <span className={classes.toolLabel}>Text</span>
        </IconButton>

        <IconButton isActive={selectedSection === SECTIONS.SHAPES} onClick={() => setSelectedSection(SECTIONS.SHAPES)}>
          <ShapesIcon cypress_id={"shapesIcon"} />
          <span className={classes.toolLabel}>Shapes</span>
        </IconButton>

        <IconButton
          isActive={selectedSection === SECTIONS.QR_CODE}
          onClick={() => setSelectedSection(SECTIONS.QR_CODE)}
        >
          <QRIcon cypress_id={"QRCodeIcon"} />
          <span className={classes.toolLabel}>QR code</span>
        </IconButton>

        {/*
          <IconButton isActive={activeTool === TDShapeType.Arrow} onClick={selectArrowTool} cypressId={"addArrowIcon"}>
            <VariablesIcon />
            <span className={classes.toolLabel}>Variables</span>
          </IconButton>
        */}

        <IconButton isActive={selectedSection === SECTIONS.LAYERS} onClick={() => setSelectedSection(SECTIONS.LAYERS)}>
          <LayersIcon cypress_id={"layersIcon"} />
          <span className={classes.toolLabel}>Layers</span>
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    background: "rgba(26, 37, 71, 1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "calc(100vh - 80px)",
    padding: 8,
  },
  items: {
    marginTop: "10px",
  },
  toolLabel: {
    fontFamily: "Proxima Nova, sans-serif",
    fontWeight: 600,
    lineHeight: "15px",
    fontSize: "12px",
    marginTop: "5px",
  },
});

export default Toolbar;
