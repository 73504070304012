const style = {
  container: {
    display: "flex",
    justifyContent: "center",
  },
  modalWrapper: {
    width: "100vw",
    height: "100vh",
    position: "absolute" as const,
    top: 0,
    left: 0,
    zIndex: 4,
  },
  modal: {
    width: 410,
    height: 140,
    backgroundColor: "#fff",
    zIndex: 1,
    position: "absolute" as const,
    top: 80,
    right: "calc(50vw - 600px)",
    display: "flex",
    flexDirection: "column" as const,
    borderRadius: 4,
    boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
  },
  closeButton: {
    position: "absolute" as const,
    top: 20,
    right: 20,
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
    cursor: "pointer",
  },
  content: {
    flex: 1,
    marginLeft: 20,
    marginRight: 20,
    display: "flex",
    alignItems: "center",
  },
  img: {
    display: "flex",
    width: 48,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
    width: 322,
    lineHeight: "24px",
    fontFamily: "Proxima Nova",
    color: "#011533",
  },
};

export default style;
