import { useContext, useEffect, useMemo, useRef, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { debounce } from "lodash";
import { Box, makeStyles, Chip } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { CustomTooltipContent } from "components/tooltip-content";
import { LightTooltip } from "components/light-tooltip";
import Header from "components/header";
import BounceLoader from "components/loaders/bounce-loader";
import { IfElse } from "components/logic";
import { ProgressBarContext } from "components/progress-bar";
import { formatCampaignName, validateCampaignName } from "utils";
// import { SnackBarContext } from "components/snack-bar";
// import { DialogModalContext } from "components/dialog-modal";
import { Saving } from "components/Saving";
import { AudienceMapEDDM } from "components/audience-map/components/AudienceMapEDDM";
import CONFIG from "config/config";
import { DM_ROUTING } from "config/routing";
import { getSelectedRoutesFromDistributionLocations } from "components/audience-map/components/AudienceMapEDDM/utils";
import CustomSelect from "components/custom-select";
import useExitPrompt from "Hooks/useExitPrompt";
import { NextButton } from "../../../components/next-button";
import { CAMPAIGN_INTERNAL_STATUSES, EDDM_NAVIGATION, radiusToZoom } from "../../constants";
import {
  createQuote,
  updateCampaignExtraData,
  updateSelfServeCampaignData,
  checkIsCampaignNameExist,
  updateCampaign as updateCampaignDB,
} from "../../../graphQL";
import BlockingOverlay from "../../../components/BlockingOverlay";
import { styles } from "./styles.js";
import { useStore } from "../../../store";
// import { insertCampaignLogs } from "../../../Logs/campaign/gql";
import { CAMPAIGN_LOG_ENUM_TYPES, CAMPAIGN_ENUM_TYPES } from "../../../shared/constants";
import ModalSelectChannel from "../../../pages/campaign/channel/Modal";

const libraries = ["geometry", "places", "drawing"];
const useStyles = makeStyles(() => styles);
const radiusOptions = [1, 2, 5, 10].map((v) => ({
  value: v,
  label: `${v} miles`,
}));

export const Segments = () => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY,
    libraries: libraries,
    language: "en",
  });

  const history = useHistory();
  const [, setShowExitPrompt] = useExitPrompt(false);
  const [radius, setRadius] = useState(1);

  const {
    costsCalculationData,
    country,
    campaign: {
      channel,
      campaignName,
      // flyerType,
      // flyerWeight,
      // campaignDuration,
      internalStatus,
      isSubmitted,
      id: campaignId,
      lastActiveStep,
    },
    errors: { campaignNameDuplicates },
    user: { accessDenied = true, id: userId },
    client: { id: clientId, name: clientName },
    map: { center, loading, loadingPercentage, visiblePostCodes = [], shouldShowDefaultPin },
    distributionLocations,
    setDistributionLocations,
    setFocusedLocation,
    updateCampaign,
    updateMap,
    updateErrors,
  } = useStore();
  const fromHome = useMemo(() => lastActiveStep === "home", [lastActiveStep]);

  const { totalCost, tax } = costsCalculationData?.detailedCost || {};

  // useContext
  // const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);
  // const runDialogModal = useContext(DialogModalContext);

  // useState
  const [selectedRoutes, setSelectedRoutes] = useState(
    getSelectedRoutesFromDistributionLocations(distributionLocations)
  );
  const [shouldAutoSave, setShouldAutoSave] = useState(false);

  const [error, setError] = useState(true);
  // const [gTagEvents, setgTagEvents] = useState({ ss_campaign_aud_area: false, ss_campaign_aud_city: false });
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [validatingCampaignName, setValidatingCampaignName] = useState(false);
  const [nameModalOpen, setNameModalOpen] = useState(fromHome);
  const [tempCampaignName, setTempCampaignName] = useState("");

  // useRef
  const clickSaveButtonRef = useRef(false);

  // useMemo
  const isNextActive = useMemo(() => {
    return costsCalculationData.flyersAmount && costsCalculationData.flyersAmount > 1000;
  }, [costsCalculationData.flyersAmount, isSubmitted]);

  const TooltipContent = useMemo(() => {
    if (!isNextActive) {
      return (
        <CustomTooltipContent
          title="Not enough addresses!"
          content="To proceed, please select at least 1000 addresses."
        />
      );
    }

    return "";
  }, [isNextActive]);

  // useEffect

  // useEffect(() => {
  //   if (costsCalculationError) {
  //     runDialogModal({
  //       disabledClose: true,
  //       title: "Internal Error",
  //       contentText: (
  //         <Box className={classes.internalErrorModal}>
  //           <div>Something went wrong</div>
  //           <div>Please, contact our support</div>
  //         </Box>
  //       ),
  //       ctaLabel: "Contact support",
  //       customCtaStyles: { width: "208px" },
  //       handleCTAClick: () => {
  //         window.open(
  //           `mailto:support@oppizi.com?subject=Internal problem - ${country.name}&body=Please,%20describe%20your%20problem`
  //         );
  //       },
  //     });
  //   }
  // }, [costsCalculationError]);

  useEffect(() => {
    if (campaignNameDuplicates) {
      setError("Duplicate campaign name. Please choose another.");
      return;
    }
  }, [campaignNameDuplicates]);

  useEffect(() => {
    if (nameModalOpen) {
      if (!tempCampaignName) {
        setError(true);
        return;
      }

      if (validateCampaignName(tempCampaignName) !== null) {
        setError(true);
      } else {
        setError(null);
      }

      const timerId = setTimeout(() => {
        setError(validateCampaignName(tempCampaignName));
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    } else {
      setError(null);
    }
  }, [tempCampaignName]);

  useEffect(() => {
    if (distributionLocations && distributionLocations.length) {
      const focusedLocation = distributionLocations.find((location) => location.isFocused);
      if (focusedLocation) {
        setRadius(focusedLocation.radius);
        updateMap({ center: { lat: focusedLocation.lat, lng: focusedLocation.lng, zoom: radiusToZoom[radius] } });
      }
    }
  }, []);

  const saveDataToDB = debounce(() => {
    saveCampaign(true);
    setShouldAutoSave(false);
  }, 1000);

  useEffect(() => {
    if (distributionLocations && shouldAutoSave) {
      saveDataToDB(distributionLocations);
    }
    return () => {
      saveDataToDB.cancel();
    };
  }, [distributionLocations]);

  // useCallback
  const saveCampaign = async (isAutoSave) => {
    setIsSaving(true);
    if (!isSubmitted && distributionLocations) {
      await updateCampaignExtraData({
        lastActiveStep: "segments",
        campaignId,
        totalCosts: totalCost,
        flyersCount: costsCalculationData?.flyersAmount || 0,
        taxes: tax,
      });
      await updateSelfServeCampaignData({
        campaignId: campaignId,
        distributionLocations: distributionLocations,
      });
      updateCampaign({ lastActiveStep: "segments" });
      await setQuoteNumber(campaignId);
      if (!isAutoSave) {
        nextBtnClicked(campaignId);
      }
    }
    setIsSaving(false);
  };

  const setQuoteNumber = async (campaignId) => {
    const quote = await createQuote(campaignId);
    updateCampaign({ quote });
  };

  const nextBtnClicked = (id) => {
    return !internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
      ? history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, { campaignId: id, clientId }))
      : history.push(generatePath(DM_ROUTING.DM_CAMPAIGN_DETAILS, { campaignId: id, clientId }));
  };

  const headerActions = {
    HOME: {
      action: () => {
        console.log("redirect home");
      },
    },
    NEXT: {
      isActive: true,
      action: async () => {
        if (isSubmitted) {
          runProgressBar(60);
          nextBtnClicked(campaignId);
          runProgressBar(80);
          runProgressBar(-1);
        } else {
          clickSaveButtonRef.current = false;
          await saveCampaign();
        }
      },
      hintPlacement: "bottom",
      hint: "",
    },
  };

  const handleAutocompleteChanged = (newMarker) => {
    if (newMarker) {
      const lat = newMarker?.geometry?.location?.lat();
      const lng = newMarker?.geometry?.location?.lng();
      const locationExists = !!distributionLocations.find(
        (location) =>
          location.lat === newMarker?.geometry?.location?.lat() && location.lng === newMarker?.geometry?.location?.lng()
      );
      setShouldAutoSave(true);
      if (!locationExists) {
        setDistributionLocations([
          ...distributionLocations.map((l) => ({ ...l, isFocused: false })),
          {
            name: newMarker.formattedAddress,
            lat,
            lng,
            radius: radius,
            isFocused: true,
            selectedRoutes: [],
          },
        ]);
      } else {
        setDistributionLocations([
          ...distributionLocations.map((l) => ({
            ...l,
            isFocused: l.lat === newMarker?.geometry?.location?.lat() && l.lng === newMarker?.geometry?.location?.lng(),
          })),
        ]);
      }
      updateMap({ center: { lat, lng } });
    }
  };

  useEffect(() => {
    if (distributionLocations && distributionLocations.length) {
      setDistributionLocations(
        distributionLocations.map((location) => {
          if (location.isFocused) {
            return { ...location, radius: radius };
          } else {
            return location;
          }
        })
      );
    }
  }, [radius]);

  const getFilteredPostCodes = (key) => [...visiblePostCodes.filter((postCode) => postCode?.name?.includes(key))];

  const persistCampaignName = async () => {
    await updateCampaignDB({ campaignId, name: tempCampaignName });
    await updateCampaignExtraData({ lastActiveStep: "segments", campaignId });
    updateCampaign({ lastActiveStep: "segments", campaignName: tempCampaignName });
    setNameModalOpen(false);
  };

  const handleNameSave = async () => {
    setValidatingCampaignName(true);
    if (tempCampaignName) {
      const { campaignIds } = await checkIsCampaignNameExist(clientId, tempCampaignName.trim());
      if (campaignIds.length && (!campaignId || !campaignIds.includes(campaignId))) {
        setValidatingCampaignName(false);
        return updateErrors({ campaignNameDuplicates: true });
      }
      updateErrors({ campaignNameDuplicates: false });
      await persistCampaignName();
      setValidatingCampaignName(false);
    }
  };

  const onChangeCampaignName = async (name) => {
    // updateCampaign({ campaignName: formatCampaignName(name) });
    setTempCampaignName(formatCampaignName(name));
    updateErrors({ campaignNameDuplicates: false });
  };

  return (
    <Fragment>
      {isBlocked ? <BlockingOverlay /> : null}
      <Box className={classes.scrollWrapper}>
        <Helmet>
          <title>Segments</title>
        </Helmet>
        <Header
          tabs={EDDM_NAVIGATION}
          activeTabIndex={1}
          headerActions={headerActions}
          hideBackButton={isSubmitted || campaignId}
          hasError={!isNextActive || isEdited}
          isDM={channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL}
        />

        <Box className={classes.container}>
          <ModalSelectChannel
            isOpen={nameModalOpen}
            onClose={() => setNameModalOpen(false)}
            channel={CAMPAIGN_ENUM_TYPES.DIRECTMAIL}
            clientName={clientName}
            onChangeCampaignName={onChangeCampaignName}
            campaignStateName={tempCampaignName}
            error={error}
            onSave={handleNameSave}
            loading={validatingCampaignName}
          />
          <IfElse condition={isLoaded}>
            <Box>
              <Box className={classes.segmentsHeaderWrapper}>
                <Box className={classes.searchBarWrapper}>
                  <PlaceAutocomplete
                    countryCode={country?.code}
                    disabled={isSubmitted || loading}
                    placeholder={"Search for a specific location or address..."}
                    postCodeSearch={true}
                    onPlaceChanged={handleAutocompleteChanged}
                    getFilteredPostCodes={getFilteredPostCodes}
                  />
                  <Box className={classes.radiusWrapper}>
                    <span>Radius:</span>
                    <CustomSelect
                      options={radiusOptions}
                      selectedValue={radius}
                      onChange={(val) => {
                        setShouldAutoSave(true);
                        setRadius(val);
                      }}
                      className={classes.radiusSelect}
                      disabled={isSubmitted || loading}
                    />
                  </Box>
                  <Box className={classes.radiusWrapper}>
                    <Saving saving={isSaving} />
                  </Box>
                </Box>
                <Box className={classes.nextBtnWrapper} style={loading ? { pointerEvents: "none" } : {}}>
                  {!isNextActive ? (
                    <LightTooltip title={TooltipContent} placement="top">
                      <span>
                        <NextButton isNextActive={isNextActive} />
                      </span>
                    </LightTooltip>
                  ) : (
                    <NextButton
                      loading={!clickSaveButtonRef.current && isLoading}
                      isNextActive={isNextActive}
                      onClick={headerActions.NEXT.action}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {null}
          </IfElse>

          <Box className={classes.mapContainer}>
            <IfElse condition={isLoaded}>
              <Box className={classes.mapWrapper}>
                <AudienceMapEDDM
                  isSubmitted={isSubmitted}
                  radius={radius}
                  setRadius={setRadius}
                  selectedRoutes={selectedRoutes}
                  setSelectedRoutes={setSelectedRoutes}
                  shouldAutoSave={shouldAutoSave}
                  setShouldAutoSave={setShouldAutoSave}
                />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <BounceLoader />
              </Box>
            </IfElse>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
