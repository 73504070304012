import { PossibleFlyerType, TemplateConfig } from "pages/flyer/builder/drawer/types";
import fonts from "pages/flyer/builder/fonts";
import FrontSideA6 from "static/media/PrintRuleFrontSideA6.svg";
import BackSideA6 from "static/media/PrintRuleBackSideA6.svg";
import FrontSideDoorHanger from "static/media/PrintRuleFrontSideDoorHanger.jpg";
import BackSideDoorHanger from "static/media/PrintRuleBackSideDoorHanger.jpg";
import { DocumentFormats, DMDocumentFormats, DMDocumentNames, DocumentNames } from "shared/constants";
import DoorHangerFlyerEPS from "../../../static/files/DoorHangerFlyer.eps";

import A5FlyerPrintingRulesMm from "../../../static/files/A5_printing_rules_mm.pdf";
import A5FlyerPrintingRulesInches from "../../../static/files/A5_printing_rules_inches.pdf";
import PreviewA5Placeholder from "./../upload/assets/PreviewA5.png";
import PreviewA5 from "./../upload/assets/PreviewA5Transparent.png";
import A5HorizontalArrow from "./../upload/assets/A5HorizontalArrow.png";
import A5VerticalArrow from "./../upload/assets/A5VerticalArrow.png";

import A6Example from "./../upload/assets/ExampleA6.png";
import A6_Temlate from "../../../static/files/A6.eps";
import A6FlyerPrintingRulesMm from "../../../static/files/A6_printing_rules_mm.pdf";
import A6FlyerPrintingRulesInches from "../../../static/files/A6_printing_rules_inches.pdf";
import PreviewA6Placeholder from "./../upload/assets/PreviewA6.png";
import PreviewA6 from "./../upload/assets/PreviewA6Transparent.png";
import A6HorizontalArrow from "./../upload/assets/A6HorizontalArrow.png";
import A6VerticalArrow from "./../upload/assets/A6VerticalArrow.png";

import ExampleDoorHanger from "./../upload/assets/ExampleDoorHanger.png";
import DoorHangerPrintingRulesMm from "../../../static/files/DH_printing_rules_mm.pdf";
import DoorHangerPrintingRulesInches from "../../../static/files/DH_printing_rules_inches.pdf";
import PreviewDoorHangerPlaceholder from "./../upload/assets/PreviewDoorHanger.png";
import PreviewDoorHanger from "./../upload/assets/PreviewDoorHangerTransparent.png";
import DoorHangerHorizontalArrow from "./../upload/assets/DoorHangerHorizontalArrow.png";
import DoorHangerVerticalArrow from "./../upload/assets/DoorHangerVerticalArrow.png";

import TearawayPrintingRulesMm from "../../../static/files/TR_Printing_rules_mm.pdf";
import TearawayPrintingRulesInches from "../../../static/files/TR_Printing_rules_inches.pdf";
import PreviewTearawayPlaceholder from "./../upload/assets/PreviewTearaway.png";
import PreviewTearaway from "./../upload/assets/PreviewTearawayTransparent.png";
import TearawayHorizontalArrow from "./../upload/assets/TearawayHorizontalArrow.png";
import TearawayVerticalArrow from "./../upload/assets/TearawayVerticalArrow.png";

import DLPrintingRulesMm from "../../../static/files/DL_printing_rules_mm.pdf";
import DLPrintingRulesInches from "../../../static/files/DL_printing_rules_inches.pdf";
import PreviewDLPlaceholder from "./../upload/assets/PreviewDL.png";
import PreviewDL from "./../upload/assets/PreviewDLTransparent.png";
import DLHorizontalArrow from "./../upload/assets/DLHorizontalArrow.png";
import DLVerticalArrow from "./../upload/assets/DLVerticalArrow.png";

import SmallFlyerPrintingRulesInches from "../../../static/files/Small_printing_rules_inches.pdf";
import PreviewSmallPlaceholder from "./../upload/assets/PreviewSmall.png";
import PreviewSmall from "./../upload/assets/PreviewSmallTransparent.png";
import SmallHorizontalArrow from "./../upload/assets/SmallHorizontalArrow.png";
import SmallVerticalArrow from "./../upload/assets/SmallVerticalArrow.png";

import BestsellerFlyerPrintingRulesInches from "../../../static/files/Bestseller_printing_rules_inches.pdf";
import PreviewBestSellerPlaceholder from "./../upload/assets/PreviewBestSeller.png";
import PreviewBestSeller from "./../upload/assets/PreviewBestSellerTransparent.png";
import BestSellerHorizontalArrow from "./../upload/assets/BestSellerHorizontalArrow.png";
import BestSellerVerticalArrow from "./../upload/assets/BestSellerVerticalArrow.png";

import LargeFlyerPrintingRulesInches from "../../../static/files/Large_printing_rules_inches.pdf";
import PreviewLargePlaceholder from "./../upload/assets/PreviewLarge.png";
import PreviewLarge from "./../upload/assets/PreviewLargeTransparent.png";
import LargeHorizontalArrow from "./../upload/assets/LargeHorizontalArrow.png";
import LargeVerticalArrow from "./../upload/assets/LargeVerticalArrow.png";

import JumboFlyerPrintingRulesInches from "../../../static/files/Jumbo_printing_rules_inches.pdf";
import PreviewJumboPlaceholder from "./../upload/assets/PreviewJumbo.png";
import PreviewJumbo from "./../upload/assets/PreviewJumboTransparent.png";
import JumboHorizontalArrow from "./../upload/assets/JumboHorizontalArrow.png";
import JumboVerticalArrow from "./../upload/assets/JumboVerticalArrow.png";

import OversizedFlyerPrintingRulesInches from "../../../static/files/Oversized_printing_rules_inches.pdf";
import PreviewOversizedPlaceholder from "./../upload/assets/PreviewOversized.png";
import PreviewOversized from "./../upload/assets/PreviewOversizedTransparent.png";
import OversizedHorizontalArrow from "./../upload/assets/OversizedHorizontalArrow.png";
import OversizedVerticalArrow from "./../upload/assets/OversizedVerticalArrow.png";

export const FORMATS: Record<PossibleFlyerType, TemplateConfig> = {
  // businessCard: {
  //   name: "Business Card",
  //   mm: "50 × 285mm",
  //   width: 160,
  //   height: 240,
  //   getBG: (type) => getBG(type, 160, 240, businessCardIMG),
  //   xSnappingLines: [],
  //   ySnappingLines: [],
  // },
  A5: {
    template: DoorHangerFlyerEPS,
    printingRulesMm: A5FlyerPrintingRulesMm,
    printingRulesInches: A5FlyerPrintingRulesInches,
    name: DocumentNames.A5,
    format: DocumentFormats.A5 as PossibleFlyerType,
    width: 344,
    height: 590,
    example: ExampleDoorHanger,
    preview: PreviewA5,
    placeholder: PreviewA5Placeholder,
    frontSide: FrontSideDoorHanger,
    backSide: BackSideDoorHanger,
    size: "148 x 210",
    measuring: "mm",
    widthInMm: 148,
    heightInMm: 210,
    displayWidth: 390,
    displayHeight: 546,
    gapForArrow: 17,
    previewPadding: 0,
    horizontalArrow: A5HorizontalArrow,
    verticalArrow: A5VerticalArrow,
    verticalArrowHeight: 528,
    horizontalArrowWidth: 373,
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNjkuMyA0NTMuNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY5LjMgNDUzLjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQzLjgsMjk3LjZIMjUuNWMtNi4zLDAtMTEuMy01LjEtMTEuMy0xMS4zVjI1LjVjMC02LjMsNS4xLTExLjMsMTEuMy0xMS4zaDIxOC4zYzYuMywwLDExLjMsNS4xLDExLjMsMTEuMwoJCXYyNjAuOEMyNTUuMSwyOTIuNiwyNTAsMjk3LjYsMjQzLjgsMjk3LjZ6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQzLjgsNDM5LjRIMjUuNWMtNi4zLDAtMTEuMy01LjEtMTEuMy0xMS4zVjMwOWMwLTYuMyw1LjEtMTEuMywxMS4zLTExLjNoMjE4LjNjNi4zLDAsMTEuMyw1LjEsMTEuMywxMS4zCgkJVjQyOEMyNTUuMSw0MzQuMywyNTAsNDM5LjQsMjQzLjgsNDM5LjR6Ii8+CjwvZz4KPC9zdmc+Cg==",
  },
  A6: {
    template: A6_Temlate,
    printingRulesMm: A6FlyerPrintingRulesMm,
    printingRulesInches: A6FlyerPrintingRulesInches,
    name: DocumentNames.A6,
    format: DocumentFormats.A6 as PossibleFlyerType,
    frontSide: FrontSideA6,
    backSide: BackSideA6,
    example: A6Example,
    preview: PreviewA6,
    placeholder: PreviewA6Placeholder,
    size: "105 × 148",
    measuring: "mm",
    widthInMm: 105,
    heightInMm: 148,
    displayWidth: 415,
    displayHeight: 573,
    previewPadding: 0,
    gapForArrow: 22,
    horizontalArrow: A6HorizontalArrow,
    verticalArrow: A6VerticalArrow,
    verticalArrowHeight: 550,
    horizontalArrowWidth: 393,
    width: 419.5, // 111 mm (include bleed area)
    height: 582, // 154 mm (include bleed area)
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
    // getBG: (type) => getBG(type, 436, 550, a6IMG),
    // xSnappingLines: [],
    // ySnappingLines: [],
  },
  DOOR_HANGER: {
    template: DoorHangerFlyerEPS,
    printingRulesMm: DoorHangerPrintingRulesMm,
    printingRulesInches: DoorHangerPrintingRulesInches,
    name: DocumentNames.DOOR_HANGER,
    format: DocumentFormats.DOOR_HANGER as PossibleFlyerType,
    width: 359,
    height: 839, // (mm) = 3.74 x 8.74 in (bleed area)
    example: ExampleDoorHanger,
    preview: PreviewDoorHanger,
    placeholder: PreviewDoorHangerPlaceholder,
    frontSide: FrontSideDoorHanger,
    backSide: BackSideDoorHanger,
    size: "3.74 x 8.74",
    measuring: "in",
    widthInMm: 95,
    heightInMm: 222,
    displayWidth: 240,
    displayHeight: 560,
    previewPadding: 0,
    gapForArrow: 14,
    horizontalArrow: DoorHangerHorizontalArrow,
    verticalArrow: DoorHangerVerticalArrow,
    verticalArrowHeight: 547,
    horizontalArrowWidth: 227,
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
  },
  TEARAWAY: {
    template: DoorHangerFlyerEPS,
    printingRulesMm: TearawayPrintingRulesMm,
    printingRulesInches: TearawayPrintingRulesInches,
    name: DocumentNames.TEARAWAY,
    format: DocumentFormats.TEARAWAY as PossibleFlyerType,
    width: 344,
    height: 590,
    example: ExampleDoorHanger,
    preview: PreviewTearaway,
    placeholder: PreviewTearawayPlaceholder,
    frontSide: FrontSideDoorHanger,
    backSide: BackSideDoorHanger,
    size: "85 x 150",
    measuring: "mm",
    widthInMm: 85,
    heightInMm: 150,
    displayWidth: 327,
    displayHeight: 561,
    previewPadding: 0,
    gapForArrow: 20,
    horizontalArrow: TearawayHorizontalArrow,
    verticalArrow: TearawayVerticalArrow,
    verticalArrowHeight: 541,
    horizontalArrowWidth: 307,
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNjkuMyA0NTMuNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY5LjMgNDUzLjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQzLjgsMjk3LjZIMjUuNWMtNi4zLDAtMTEuMy01LjEtMTEuMy0xMS4zVjI1LjVjMC02LjMsNS4xLTExLjMsMTEuMy0xMS4zaDIxOC4zYzYuMywwLDExLjMsNS4xLDExLjMsMTEuMwoJCXYyNjAuOEMyNTUuMSwyOTIuNiwyNTAsMjk3LjYsMjQzLjgsMjk3LjZ6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQzLjgsNDM5LjRIMjUuNWMtNi4zLDAtMTEuMy01LjEtMTEuMy0xMS4zVjMwOWMwLTYuMyw1LjEtMTEuMywxMS4zLTExLjNoMjE4LjNjNi4zLDAsMTEuMyw1LjEsMTEuMywxMS4zCgkJVjQyOEMyNTUuMSw0MzQuMywyNTAsNDM5LjQsMjQzLjgsNDM5LjR6Ii8+CjwvZz4KPC9zdmc+Cg==",
  },
  DL: {
    template: DoorHangerFlyerEPS,
    printingRulesMm: DLPrintingRulesMm,
    printingRulesInches: DLPrintingRulesInches,
    name: DocumentNames.DL,
    format: DocumentFormats.DL as PossibleFlyerType,
    width: 344,
    height: 590,
    example: ExampleDoorHanger,
    preview: PreviewDL,
    placeholder: PreviewDLPlaceholder,
    frontSide: FrontSideDoorHanger,
    backSide: BackSideDoorHanger,
    size: "99 x 210",
    measuring: "mm",
    widthInMm: 99,
    heightInMm: 210,
    displayWidth: 279,
    displayHeight: 574,
    previewPadding: 0,
    gapForArrow: 15,
    horizontalArrow: DLHorizontalArrow,
    verticalArrow: DLVerticalArrow,
    verticalArrowHeight: 558,
    horizontalArrowWidth: 264,
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNjkuMyA0NTMuNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY5LjMgNDUzLjU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQzLjgsMjk3LjZIMjUuNWMtNi4zLDAtMTEuMy01LjEtMTEuMy0xMS4zVjI1LjVjMC02LjMsNS4xLTExLjMsMTEuMy0xMS4zaDIxOC4zYzYuMywwLDExLjMsNS4xLDExLjMsMTEuMwoJCXYyNjAuOEMyNTUuMSwyOTIuNiwyNTAsMjk3LjYsMjQzLjgsMjk3LjZ6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQzLjgsNDM5LjRIMjUuNWMtNi4zLDAtMTEuMy01LjEtMTEuMy0xMS4zVjMwOWMwLTYuMyw1LjEtMTEuMywxMS4zLTExLjNoMjE4LjNjNi4zLDAsMTEuMyw1LjEsMTEuMywxMS4zCgkJVjQyOEMyNTUuMSw0MzQuMywyNTAsNDM5LjQsMjQzLjgsNDM5LjR6Ii8+CjwvZz4KPC9zdmc+Cg==",
  },
  SMALL: {
    template: A6_Temlate,
    printingRulesMm: A6FlyerPrintingRulesMm,
    printingRulesInches: SmallFlyerPrintingRulesInches,
    name: DMDocumentNames.SMALL,
    format: DMDocumentFormats.SMALL as PossibleFlyerType,
    frontSide: FrontSideA6,
    backSide: BackSideA6,
    example: A6Example,
    preview: PreviewSmall,
    placeholder: PreviewSmallPlaceholder,
    size: "107.95 × 279.4",
    measuring: "mm",
    heightInMm: 107.95, // 4.25 inch
    widthInMm: 279.4, // 11 inch
    displayHeight: 240, // 4.25 inch analog representation in screen
    displayWidth: 621.17, // 11 inch analog representation in screen
    previewPadding: 0,
    gapForArrow: 14,
    horizontalArrow: SmallHorizontalArrow,
    verticalArrow: SmallVerticalArrow,
    verticalArrowHeight: 230,
    horizontalArrowWidth: 609,
    width: 419.5, // 111 mm (include bleed area)
    height: 582, // 154 mm (include bleed area)
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
    // getBG: (type) => getBG(type, 436, 550, a6IMG),
    // xSnappingLines: [],
    // ySnappingLines: [],
  },
  BEST_SELLER: {
    template: A6_Temlate,
    printingRulesMm: A6FlyerPrintingRulesMm,
    printingRulesInches: BestsellerFlyerPrintingRulesInches,
    name: DMDocumentNames.BEST_SELLER,
    format: DMDocumentFormats.BEST_SELLER as PossibleFlyerType,
    frontSide: FrontSideA6,
    backSide: BackSideA6,
    example: A6Example,
    preview: PreviewBestSeller,
    placeholder: PreviewBestSellerPlaceholder,
    size: "105 × 148",
    measuring: "mm",
    heightInMm: 158.75, // 6.25 inch
    widthInMm: 228.6, // 9 inch
    displayWidth: 508.23, // 9 inch analog representation on screen
    displayHeight: 352.93, // 6.25 inch analog representation on screen
    previewPadding: 0,
    gapForArrow: 14,
    horizontalArrow: BestSellerHorizontalArrow,
    verticalArrow: BestSellerVerticalArrow,
    verticalArrowHeight: 340,
    horizontalArrowWidth: 496,
    width: 419.5, // 111 mm (include bleed area)
    height: 582, // 154 mm (include bleed area)
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
    // getBG: (type) => getBG(type, 436, 550, a6IMG),
    // xSnappingLines: [],
    // ySnappingLines: [],
  },
  LARGE: {
    template: A6_Temlate,
    printingRulesMm: A6FlyerPrintingRulesMm,
    printingRulesInches: LargeFlyerPrintingRulesInches,
    name: DMDocumentNames.LARGE,
    format: DMDocumentFormats.LARGE as PossibleFlyerType,
    frontSide: FrontSideA6,
    backSide: BackSideA6,
    example: A6Example,
    preview: PreviewLarge,
    placeholder: PreviewLargePlaceholder,
    size: "105 × 148",
    measuring: "mm",
    heightInMm: 158.75, // 6.25 inch
    widthInMm: 279.4, // 11 inch
    displayWidth: 621.17, // 11 inch analog representation in screen
    displayHeight: 352.93, // 6.25 inch analog representation on screen
    previewPadding: 0,
    gapForArrow: 14,
    horizontalArrow: LargeHorizontalArrow,
    verticalArrow: LargeVerticalArrow,
    verticalArrowHeight: 340,
    horizontalArrowWidth: 609,
    width: 419.5, // 111 mm (include bleed area)
    height: 582, // 154 mm (include bleed area)
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
    // getBG: (type) => getBG(type, 436, 550, a6IMG),
    // xSnappingLines: [],
    // ySnappingLines: [],
  },
  JUMBO: {
    template: A6_Temlate,
    printingRulesMm: A6FlyerPrintingRulesMm,
    printingRulesInches: JumboFlyerPrintingRulesInches,
    name: DMDocumentNames.JUMBO,
    format: DMDocumentFormats.JUMBO as PossibleFlyerType,
    frontSide: FrontSideA6,
    backSide: BackSideA6,
    example: A6Example,
    preview: PreviewJumbo,
    placeholder: PreviewJumboPlaceholder,
    size: "105 × 148",
    measuring: "mm",
    heightInMm: 215.9, // 8.5 inch
    widthInMm: 279.4, // 11 inch
    displayWidth: 621.17, // 11 inch analog representation in screen
    displayHeight: 479.99, // 8.5 inch analog representation in screen
    previewPadding: 0,
    gapForArrow: 14,
    horizontalArrow: JumboHorizontalArrow,
    verticalArrow: JumboVerticalArrow,
    verticalArrowHeight: 465,
    horizontalArrowWidth: 609,
    width: 419.5, // 111 mm (include bleed area)
    height: 582, // 154 mm (include bleed area)
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
    // getBG: (type) => getBG(type, 436, 550, a6IMG),
    // xSnappingLines: [],
    // ySnappingLines: [],
  },
  OVERSIZED: {
    template: A6_Temlate,
    printingRulesMm: A6FlyerPrintingRulesMm,
    printingRulesInches: OversizedFlyerPrintingRulesInches,
    name: DMDocumentNames.OVERSIZED,
    format: DMDocumentFormats.OVERSIZED as PossibleFlyerType,
    frontSide: FrontSideA6,
    backSide: BackSideA6,
    example: A6Example,
    preview: PreviewOversized,
    placeholder: PreviewOversizedPlaceholder,
    size: "105 × 148",
    measuring: "mm",
    heightInMm: 304.8, // 12 inch
    widthInMm: 381, // 15 inch
    displayWidth: 750, // 15 inch analog representation on screen
    displayHeight: 600, // 12 inch analog representation on screen
    previewPadding: 0,
    gapForArrow: 14,
    horizontalArrow: OversizedHorizontalArrow,
    verticalArrow: OversizedVerticalArrow,
    verticalArrowHeight: 586,
    horizontalArrowWidth: 736,
    width: 419.5, // 111 mm (include bleed area)
    height: 582, // 154 mm (include bleed area)
    printLines:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMTcuNSA0NTkuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzE3LjUgNDU5LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzU0QzkwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojMDEwMjAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT4KPHJlY3QgeD0iMTQuMiIgeT0iMTQuMiIgY2xhc3M9InN0MCIgd2lkdGg9IjI4OS4xIiBoZWlnaHQ9IjQzMC45Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSI5LjkiIHkxPSIxNC4yIiB4Mj0iMCIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iNDQ5LjMiIHgyPSIzMDMuMyIgeTI9IjQ1OS4yIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjQ0NSIgeDI9IjMxNy41IiB5Mj0iNDQ1Ii8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIzMDcuNiIgeTE9IjE0LjIiIHgyPSIzMTcuNSIgeTI9IjE0LjIiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjMwMy4zIiB5MT0iOS45IiB4Mj0iMzAzLjMiIHkyPSIwIi8+CjxsaW5lIGNsYXNzPSJzdDEiIHgxPSIxNC4yIiB5MT0iOS45IiB4Mj0iMTQuMiIgeTI9IjAiLz4KPGxpbmUgY2xhc3M9InN0MSIgeDE9IjE0LjIiIHkxPSI0NDkuMyIgeDI9IjE0LjIiIHkyPSI0NTkuMiIvPgo8bGluZSBjbGFzcz0ic3QxIiB4MT0iOS45IiB5MT0iNDQ1IiB4Mj0iMCIgeTI9IjQ0NSIvPgo8L3N2Zz4K",
    // getBG: (type) => getBG(type, 436, 550, a6IMG),
    // xSnappingLines: [],
    // ySnappingLines: [],
  },
};

export const FONT_FAMILIES = {
  Alegreya: {
    name: "Alegreya",
    fontFamily: "'Alegreya', serif",
    fontWeights: {
      400: "normal",
      // 500: "medium",
      700: "bold",
    },
    fontStyles: {
      regular: "Regular",
      italic: "Italic",
    },
  },
  "IBM Plex Sans": {
    name: "IBM Plex Sans",
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontWeights: {
      // 300: "light",
      400: "normal",
      // 500: "medium",
      // 600: "semibold",
      700: "bold",
    },
    fontStyles: {
      regular: "Regular",
      italic: "Italic",
    },
  },
  "IBM Plex Serif": {
    name: "IBM Plex Serif",
    fontFamily: "'IBM Plex Serif', serif",
    fontWeights: {
      // 300: "light",
      400: "normal",
      // 500: "medium",
      // 600: "semibold",
      700: "bold",
    },
    fontStyles: {
      regular: "Regular",
      italic: "Italic",
    },
  },
  "Nunito Sans": {
    name: "Nunito Sans",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeights: {
      // 300: "light",
      400: "normal",
      // 600: "semibold",
      700: "bold",
    },
    fontStyles: {
      regular: "Regular",
      italic: "Italic",
    },
  },
  Roboto: {
    name: "Roboto",
    fontFamily: "'Roboto', sans-serif",
    fontWeights: {
      // 300: "light",
      400: "normal",
      // 500: "medium",
      700: "bold",
    },
    fontStyles: {
      regular: "Regular",
      italic: "Italic",
    },
  },
};

export const staticFonts = fonts;

export const GAP_BETWEEN_PAGES = 50;
export const SIZE_RESIZE_HANDLES = 10;
export const SIZE_RESIZE_HANDLES_CENTER = 5;
export const SELECTION_BOUNDS_HANDLES_MIN_SIZE = 1;
export const CUT_MARK_LENGTH = 20;

// const getBG = (type, width, height, bgUrl) => (
//   <>
//     <If condition={type === "guide"}>
//       <div style={{ width, height }}>
//         <img
//           style={{ pointerEvents: "none", userSelect: "none" }}
//           src={bgUrl}
//           alt="format bg"
//         />
//       </div>
//     </If>
//     <If condition={type === "white"}>
//       <div
//         style={{
//           width,
//           height,
//           backgroundColor: "white",
//           pointerEvents: "none",
//         }}
//       ></div>
//     </If>
//   </>
// );

export const CUT_MARK_BIAS = 0;

export const DOOR_HANGER_TRIANGLE_SIZE = [48, 58];
export const DOOR_HANGER_TRIANGLE_POS = [0, 104.5];

export const DOOR_HANGER_CIRCLE_SIZE = [118, 94];
export const DOOR_HANGER_CIRCLE_POS = [110, 86.5];
export const INF_VAL = 5000;

export const INTERVAL_SIZE = 38;

export const BLEED_AREA_SIZE: Record<PossibleFlyerType, number> = {
  A5: 11,
  A6: 11,
  DOOR_HANGER: 8.5,
  TEARAWAY: 11,
  DL: 11,
  SMALL: 11,
  BEST_SELLER: 11,
  LARGE: 11,
  JUMBO: 11,
  OVERSIZED: 11,
};

export const SAFE_AREA_SIZE: Record<PossibleFlyerType, number> = {
  A5: 11,
  A6: 11,
  DOOR_HANGER: 9,
  TEARAWAY: 10,
  DL: 11,
  SMALL: 11,
  BEST_SELLER: 11,
  LARGE: 11,
  JUMBO: 11,
  OVERSIZED: 11,
};

export const SECTIONS = {
  SETTINGS: "SETTINGS",
  IMAGES: "IMAGES",
  TEXT: "TEXT",
  SHAPES: "SHAPES",
  QR_CODE: "QR_CODE",
  LAYERS: "LAYERS",
};

export const WHITE_BG_SIZE = [280, 460];
