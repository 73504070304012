import ROUTES from "config/routing";
import { authorization } from "module/auth";
import { Fragment, memo, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import LogRocket from "logrocket";
import CONFIG from "config/config";
import moment from "moment";
import { CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import {
  getCampaignCost,
  getCampaign,
  getSelfServeCampaignData,
  getClient,
  getCountryData,
  getCityTimezone,
} from "../graphQL";
import { getCountOfMission, getInternalError } from "./utils";
import { calculateSubtotalAndPrintingCosts, formatPrintingCosts, getCorrectSettingsObject } from "../shared/campaign";
import BounceLoader from "../components/loaders/bounce-loader";
import { CAMPAIGN_ENUM_TYPES } from "../shared/constants";
import { useStore } from "../store";
import { getCitiesList, getLogRocketSessionURL, gtagWrapper } from "../utils";
import { NonGoogleAlertModal } from "../components/NonGoogleAlertModal";
import { DialogModalContext } from "../components/dialog-modal";
import Chat from "../TalkJS/container";

const CampaignDataHOC = (props) => {
  const { initData, updateUser, updateMap } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const logRocketSession = getLogRocketSessionURL({ short: true });
  const [logRocketSent, setLogRocketSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isArchivedCity, setIsArchivedCity] = useState(false);
  const [costsError, setCostsError] = useState(false);
  const [country, setCountry] = useState(null);
  const [client, setClient] = useState(null);

  const runDialogModal = useContext(DialogModalContext);

  const history = useHistory();
  const { campaignId, clientId: clientIdParam } = useParams();
  const user = authorization.getUser();

  const getInitialData = useCallback(async () => {
    setIsLoading(true);

    if (user.clientId != clientIdParam) {
      updateUser({ accessDenied: true });
      return history.push(ROUTES.ACCESS_DENIED);
    }

    updateUser({ accessDenied: false });

    if (!campaignId || isNaN(Number(campaignId))) {
      const selectedClientId = user.clientId ? user.clientId : clientIdParam;

      if (!selectedClientId) return history.push(ROUTES.ACCESS_DENIED);

      const clientData = await getClient({ clientId: selectedClientId });

      setClient(clientData[0]);
      const countryId = clientData[0].countryId;
      const country = await getCountryData(countryId);

      setCountry(country);

      const formattedPrintingCosts = formatPrintingCosts(country.printingCosts);

      const costsCalculationError = getInternalError({
        countrySettings: country.countryDefaultSettings,
        countryCode: country.code,
        printingCost: formattedPrintingCosts,
      });

      const costsCalculationData = {
        printingCosts: formattedPrintingCosts,
      };

      const STATE = {
        campaign: {},
        city: null,
        client: clientData[0],
        country: { ...country, baPerLocation: country.countryDefaultSettings.baPerLocation, citiesList: [] },
        costsCalculationData,
        errors: { costsCalculationError },
        user: {
          ...user,
          accessDenied: false,
        },

        missions: [],
      };
      console.log({ STATE });
      initData(STATE);
    } else {
      try {
        const campaignData = await getCampaign(campaignId, user.clientId);

        setClient(campaignData.client);

        const isSubmitted =
          campaignData.internalStatus &&
          campaignData.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT &&
          campaignData.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.REJECTED;

        const { selectedCities } = await getSelfServeCampaignData(campaignId);
        const countryId = campaignData.country.id;
        const country = await getCountryData(countryId);

        setCountry(country);

        const city = selectedCities.find((city) => city.id);

        const formattedPrintingCosts = formatPrintingCosts(country.printingCosts);

        let costsCalculationData = {
          printingCosts: formattedPrintingCosts,
        };

        const costsCalculationError = getInternalError({
          countrySettings: country.countryDefaultSettings,
          countryCode: country.code,
          printingCost: formattedPrintingCosts,
        });

        const isD2D = campaignData.channel === CAMPAIGN_ENUM_TYPES.LETTERBOX;
        let timezone = "";

        if (city) {
          const detailedCost = await getCampaignCost({ id: campaignId, cityId: city.id });
          const missionCostWithoutPrinting = detailedCost.missionCostWithoutPrinting;

          const citySetting = country.citiesSettings.find((item) => item.id === city.id);
          //ARCHIVED CITY
          if (!citySetting) {
            return setIsArchivedCity(true);
          }

          const settings = getCorrectSettingsObject({
            countrySettings: country.countryDefaultSettings,
            citySetting,
            isD2D,
          });

          const missionsCount = getCountOfMission(city.addresses);

          const flyerType = campaignData.flyerType;
          const flyerWeight = campaignData.flyerWeight;

          if (!costsCalculationData.printingCosts[`${flyerType}#${flyerWeight}`] && !isSubmitted) {
            return setCostsError(true);
          }

          if (!isSubmitted) {
            const { subtotal } = calculateSubtotalAndPrintingCosts({
              missionCostWithoutPrinting,
              missionsCount,
              printingCosts: costsCalculationData.printingCosts,
              flyerType,
              flyerWeight,
              flyerPack: settings.flyerPack,
              outfitTotal: detailedCost.outfit.total,
            });

            const costPerFlyer = !isNaN(subtotal / detailedCost.printing.quantity)
              ? subtotal / detailedCost.printing.quantity
              : 0;
            costsCalculationData = {
              ...costsCalculationData,
              settings,
              costPerFlyer: Math.trunc(Number(costPerFlyer) * 1000) / 1000,
              detailedCost,
            };
          } else {
            costsCalculationData = {
              ...costsCalculationData,
              settings,
              costPerFlyer: Math.trunc(Number(detailedCost.printing.rate) * 1000) / 1000,
              detailedCost,
            };
          }

          if (!city.addresses.length) {
            updateMap({ center: { lng: city.lng, lat: city.lat } });
          } else {
            city.addresses = city.addresses.map((address) => {
              if (address.startDate && typeof address.startDate === "string") {
                return {
                  ...address,
                  startDate: address.startDate
                    .split("#")
                    .map((date) => date.split("GMT")[0])
                    .join("#"),
                };
              }
              return address;
            });
          }

          const cityTimezone = await getCityTimezone(city.id);
          if (cityTimezone) {
            timezone = cityTimezone;
          }
        }

        let convertedStartDate;
        if (campaignData.startDate) {
          // The start_date is saved in UTC but with the actual date in the selected city, NOT the UTC representation with offset.
          // So it is not saved with the offset, but exactly the date that the campaign will launch in the selected city.
          // Since the date pickers and all the calculations related with dates in the front end are using User's tz
          // here we force the date to be in User's timezone in order to calculate all date-related logic as if the user were
          // located in the selected city.
          convertedStartDate = new Date(
            moment(moment(Number(campaignData.startDate)).utc().format().split("T")[0], "YYYY-MM-DD", true).format()
          );
        }

        const campaign = {
          id: campaignId,
          amountOff: campaignData.amountOff,
          campaignComments: campaignData.campaignComments,
          campaignDuration: campaignData.campaignDuration,
          campaignFlyerInfo: campaignData.campaignFlyerInfo,
          campaignName: campaignData.campaignName,
          channel: campaignData.channel,
          stripeCouponCode: campaignData.stripeCouponCode,
          flyerType: campaignData.flyerType,
          flyerWeight: campaignData.flyerWeight,
          internalStatus: campaignData.internalStatus,
          isSubmitted: isSubmitted,
          isPaymentSkipped: campaignData.isPaymentSkipped,
          lastActiveStep: campaignData.lastActiveStep,
          percentOff: campaignData.percentOff,
          qrCodeLink: campaignData.qrCodeLink,
          quote: campaignData.quote,
          startDate: convertedStartDate,
          submittedBy: campaignData.submittedBy,
          updatedAt: campaignData.updatedAt,
          isD2D: campaignData.channel === CAMPAIGN_ENUM_TYPES.LETTERBOX,
          isH2H: campaignData.channel === CAMPAIGN_ENUM_TYPES.HANDTOHAND,
          isDM: campaignData.channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL,
          //TODO ???? deleeeeeete everything
          missionsCount: campaignData.missionsCount,
          taxes: campaignData.taxes,
          totalCosts: campaignData.totalCosts,
          isTrackableCoupon: campaignData.isTrackableCoupon,
          couponType: campaignData.couponType,
          staticCouponCode: campaignData.staticCouponCode,
          dynamicCouponCode: campaignData.dynamicCouponCode,
        };

        const citiesList = await getCitiesList({ country, channel: campaign.channel });

        const STATE = {
          campaign,
          city: city ? { ...city, distributionZones: city.distributionZones[campaign.isD2D ? "d2d" : "h2h"] } : null,
          client: campaignData.client,
          timezone,
          country: {
            ...country,
            baPerLocation:
              campaignData.internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
                ? country.countryDefaultSettings.baPerLocation
                : campaignData.baPerLocation,
            citiesList,
          },
          costsCalculationData,
          errors: {
            costsCalculationError,
            cityMissionsLimitError: { title: "", message: "" },
            qrCodeUrlError: null,
            launchDateError: null,
            campaignNameError: null,
          },
          user: {
            ...user,
            accessDenied: false,
          },
        };
        console.log({ STATE });
        initData(STATE);
      } catch (error) {
        if (error.message && (error.message === "Access denied" || error.message === "Campaign does not exist.")) {
          updateUser({ accessDenied: true });
          history.push(ROUTES.ACCESS_DENIED);
        }
      }
    }
    setIsLoading(false);
  }, [campaignId]);

  useEffect(() => {
    if (CONFIG.ENV === "production" && campaignId !== "channel" && user?.clientId) {
      LogRocket.identify(user.clientId, {
        name: campaignId,
      });
    }

    if (campaignId !== "channel" && logRocketSession && !logRocketSent) {
      const props = {
        event: "ss_logrocket",
        logrocket_session: logRocketSession,
        campaign_id: campaignId,
      };
      gtagWrapper(props);
      setLogRocketSent(true);
    }
  }, [campaignId, logRocketSession, user.clientId]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (!openModal && !userAgent.match(/chrome|chromium|crios/i)) {
      setOpenModal(true);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      getInitialData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isArchivedCity || costsError) {
      runDialogModal({
        disabledClose: true,
        title: "System Error",
        contentText: (
          <Box styles={{ padding: "10px 40px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div />
            <div style={{ textAlign: "center" }}>
              {costsError
                ? "We apologize for the inconvenience, but the selected 'Flyer Type' or 'Flyer Weight' is no longer supported. Please reach out to our support team for further assistance."
                : "We apologize for the inconvenience, but this city is no longer supported. Please reach out to our support team for further assistance."}
            </div>
          </Box>
        ),
        ctaLabel: "Contact support",
        customCtaStyles: {
          width: "208px",
        },
        handleCTAClick: () => {
          costsError
            ? window.open(
                `mailto:support@oppizi.com?subject=Internal problem with campaign costs&body=Please,%20describe%20your%20problem`
              )
            : window.open(
                `mailto:support@oppizi.com?subject=Internal problem - ${country.name}&body=Please,%20describe%20your%20problem`
              );
        },
      });
    }
  }, [isArchivedCity, costsError]);

  return isLoading ? (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100vh">
      <BounceLoader />
    </Box>
  ) : (
    <Fragment>
      <NonGoogleAlertModal open={openModal} closeModal={() => setOpenModal(false)} />
      {props.children}
      <Chat user={user} countryId={country.id} client={client} />
    </Fragment>
  );
};

export default memo(CampaignDataHOC);
