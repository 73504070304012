import { normalizeGraphQLConnection } from "../../shared/graphql/utils";
import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getCities = async ({ status, countryId }) => {
  const query = `
      query ($status: Int, $countryId: ID){
        cities (status: $status, countryId: $countryId) {
          edges {
            node {
              id
              name
              lunchCheckInTime
              afternoonCheckInTime
              payHourly
              recruitmentOnHold
              recruitmentCalendarId
              onboardingLocation
              onboardingUrl
              timezone
              statusV2
              cbdRadius
              pickupInformationURL
              morningCheckInTimeBefore
              morningCheckInTimeAfter
              lunchCheckInTimeBefore
              lunchCheckInTimeAfter
              afternoonCheckInTimeBefore
              afternoonCheckInTimeAfter
              checkinRadius
              newcomersMissionsLimitV2
              surgeInterval
              teamLeaderEnabled
              location {
                latitude
                longitude
              }
              country {
                id
                name
                code
              }
            }
          }
        }
      }`;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    status,
    countryId,
  });

  if (request.status === 200) {
    const {
      data: { cities },
    } = await request.json();

    return normalizeGraphQLConnection(cities);
  }
};

export const getCityTimezone = async (cityId) => {
  const query = `
    query (
      $cityId: ID!, 
    ) {
      city(id: $cityId){
        timezone
      }
    }
  `;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    cityId,
  });

  if (request.status === 200) {
    const { data } = await request.json();
    return data.city.timezone;
  }
};
