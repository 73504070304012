import React from "react";
import { Box } from "@material-ui/core";

export const Error = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      style={{ fontFamily: "monospace" }}
    >
      Error
    </Box>
  );
};
