// NOTICE: This file was copied from the oppizi-shared repository, do
// not modify it here.

export const STATUS_INACTIVE = 0
export const STATUS_PAID = 1
export const STATUS_DONE = 2
export const STATUS_FINNISHED = 2
export const STATUS_APPROVED = 2
export const STATUS_PUBLISHED = 3

// User statuses
export const STATUS_PENDING = 0
export const STATUS_ACTIVE = 1
export const STATUS_BLOCKED = 2
export const STATUS_DELETED = 4
export const STATUS_PRE_ACCESS = 5.5
export const STATUS_WAITING_FOR_REVIEW = 6

// User statuses deprecated
export const STATUS_TERMINATING = 5

// Campaign
export const STATUS_CAMPAIGN_DRAFT = 0
export const STATUS_CAMPAIGN_ACTIVE = 10
export const STATUS_CAMPAIGN_COMPLETED = 15
export const STATUS_CAMPAIGN_ARCHIVE = 20

export const STATUS_CAMPAIGN_ENUM_DRAFT = 'DRAFT'
export const STATUS_CAMPAIGN_ENUM_ACTIVE = 'ACTIVE'
export const STATUS_CAMPAIGN_ENUM_COMPLETED = 'COMPLETED'
export const STATUS_CAMPAIGN_ENUM_ARCHIVE = 'ARCHIVED'

export const STATUS_CAMPAIGN_ENUM_TO_TYPES_MAP = {
  [STATUS_CAMPAIGN_ENUM_DRAFT]: STATUS_CAMPAIGN_DRAFT,
  [STATUS_CAMPAIGN_ENUM_ACTIVE]: STATUS_CAMPAIGN_ACTIVE,
  [STATUS_CAMPAIGN_ENUM_COMPLETED]: STATUS_CAMPAIGN_COMPLETED,
  [STATUS_CAMPAIGN_ENUM_ARCHIVE]: STATUS_CAMPAIGN_ARCHIVE,
}
export const STATUS_CAMPAIGN_TYPES_TO_ENUM_MAP = {
  [STATUS_CAMPAIGN_DRAFT]: STATUS_CAMPAIGN_ENUM_DRAFT,
  [STATUS_CAMPAIGN_ACTIVE]: STATUS_CAMPAIGN_ENUM_ACTIVE,
  [STATUS_CAMPAIGN_COMPLETED]: STATUS_CAMPAIGN_ENUM_COMPLETED,
  [STATUS_CAMPAIGN_ARCHIVE]: STATUS_CAMPAIGN_ENUM_ARCHIVE,
}

// Location
export const STATUS_DISABLED = 0
export const STATUS_ENABLED = 1
export const LOCATION_RESERVED_HOURS = 3
export const STATUS_LOCATION_ARCHIVED = 20

// missions
export const STATUS_DRAFT = 0
export const STATUS_AVAILABLE = 10
export const STATUS_BOOKED = 20
export const STATUS_CHECKED_IN = 30
export const STATUS_UNDER_REVIEW = 35
export const STATUS_COMPLETED = 40
export const STATUS_CANCELLED = 50
export const STATUS_PAID_MISSION = 60

// Client
export const STATUS_CLIENT_ACTIVE = 1
export const STATUS_CLIENT_ARCHIVED = 4

// paymentSystemTypes
export const PAYMENT_SYSTEM_TYPES_ENUM = {
  PER_MISSION: 'PER_MISSION',
  PER_HOUR: 'PER_HOUR',
}

// Should match the enum in the graphQL Schema
export const BRAND_AMBASSADOR_STATUS_ENUM = {
  PENDING: 'PENDING',
  PRE_ACCESS: 'PRE_ACCESS',
  WAITING_FOR_REVIEW: 'WAITING_FOR_REVIEW',
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  DELETED: 'DELETED',
}

export const BRAND_AMBASSADORS_STATUS_ENUM_MAP = {
  [BRAND_AMBASSADOR_STATUS_ENUM.PENDING]: STATUS_PENDING,
  [BRAND_AMBASSADOR_STATUS_ENUM.PRE_ACCESS]: STATUS_PRE_ACCESS,
  [BRAND_AMBASSADOR_STATUS_ENUM.ACTIVE]: STATUS_ACTIVE,
  [BRAND_AMBASSADOR_STATUS_ENUM.BLOCKED]: STATUS_BLOCKED,
  [BRAND_AMBASSADOR_STATUS_ENUM.DELETED]: STATUS_DELETED,
  [BRAND_AMBASSADOR_STATUS_ENUM.WAITING_FOR_REVIEW]: STATUS_WAITING_FOR_REVIEW,
}

// Should match the enum in the graphQL Schema
export const MISSION_STATUS_ENUM = {
  DRAFT: 'DRAFT',
  AVAILABLE: 'AVAILABLE',
  BOOKED: 'BOOKED',
  CANCELLED: 'CANCELLED',
  CHECKED_IN: 'CHECKED_IN',
  UNDER_REVIEW: 'UNDER_REVIEW',
  COMPLETED: 'COMPLETED',
  PAID: 'PAID',
}

export const MISSION_STATUS_ENUM_MAP = {
  [MISSION_STATUS_ENUM.DRAFT]: STATUS_DRAFT,
  [MISSION_STATUS_ENUM.AVAILABLE]: STATUS_AVAILABLE,
  [MISSION_STATUS_ENUM.BOOKED]: STATUS_BOOKED,
  [MISSION_STATUS_ENUM.CANCELLED]: STATUS_CANCELLED,
  [MISSION_STATUS_ENUM.CHECKED_IN]: STATUS_CHECKED_IN,
  [MISSION_STATUS_ENUM.UNDER_REVIEW]: STATUS_UNDER_REVIEW,
  [MISSION_STATUS_ENUM.COMPLETED]: STATUS_COMPLETED,
  [MISSION_STATUS_ENUM.PAID]: STATUS_PAID_MISSION,
}

export const PICKUP_TIME_BEFORE_MS = 15 * 60 * 1000 //  15 minutes before the mission
export const BAD_WEATHER_SURGE_BONUS = 'BAD_WEATHER_SURGE'

export const NEWCOMERS_MISSIONS_LIMIT = 3

// User roles
export const ROLE_ADMIN = 'ADMIN'
export const ROLE_CITY_MANAGER = 'CITY_MANAGER'
export const ROLE_CLIENT_ADMIN = 'CLIENT_ADMIN'
export const ROLE_CLIENT = 'ROLE_CLIENT'
export const ROLE_DISTRIBUTOR = 'DISTRIBUTOR'
export const ROLE_SPY = 'ROLE_SPY'
export const RECRUITER = 'RECRUITER'

// Permit Cost
export const PERMIT_COST_PENDING = 0
export const PERMIT_COST_PAID = 1

// Inventory movements
export const INVENTORY_MOVEMENT_INIT = 0
export const INVENTORY_MOVEMENT_IN = 1
export const INVENTORY_MOVEMENT_OUT = 2
export const INVENTORY_MOVEMENT_DISTRIBUTED = 3

export const INVENTORY_FLYER_ITEM_TYPE = 3

// Global configuration
export const SURGE_RATIO = 1.2
export const SURGE_BEFORE = 15
export const SURGE_AFTER = 15
export const MAX_STARS_RATING = 5
export const MAX_PAY_RATIO = 2.2

// report
export const STATUS_PROCESSED = 10
export const STATUS_ERROR = 20

// Others
export const DEFAULT_RATIO_MORNING = 0.3333
export const DEFAULT_RATIO_LUNCH = 0.3333
export const DEFAULT_RATIO_AFTERNOON = 0.3333
export const DEFAULT_MISSION_DURATION = 3

export const TIME_MORNING = 'MORNING'
export const TIME_LUNCH = 'LUNCH'
export const TIME_AFTERNOON = 'AFTERNOON'

export const DEFAULT_TIME_MORNING = '07:30:00' // 7.30am
export const DEFAULT_TIME_LUNCH = '11:30:00' // 11.30am
export const DEFAULT_TIME_AFTERNOON = '16:30:00' // 4.30pm

export const AMBASSADOR_PER_MISSION = 2
export const HOURS_PER_MISSION = 3

export const DEFAULT_PROFILE_PIC =
  'https://oppizi-uploads.s3-ap-southeast-2.amazonaws.com/default-profile-pic.jpg'
export const SAMPLE_CLIENT_PITCH =
  'https://oppizi-uploads.s3-ap-southeast-2.amazonaws.com/sample/sample-pitch.pdf'
export const IMAGE_URL_REGEX = /^https:\/\/.*\.(?:png|jpeg|jpg)$/i
export const DOCUMENT_URL_REGEX = /^https:\/\/.*\.(?:png|jpeg|jpg|pdf)$/i

// flagging rules
export const FLAG_MISSION_DURATION_TOO_SHORT = 1
export const FLAG_MISSION_FLYERS_NOT_DISTRIBUTED = 1 << 1
export const FLAG_MISSION_STEPS_WALKED = 1 << 2
export const FLAG_MISSION_LEAVE_LOCATION = 1 << 3
export const FLAG_MISSION_SHORT_TIME_AND_FLYERS_NOT_DISTRIBUTED = 1 << 4
export const FLAG_MISSION_BA_LEAVING_MISSION_AREA = 1 << 5
export const FLAG_MISSION_NO_ACTIVITY = 1 << 6

// extra payments types
export const EXTRA_PAYMENT_REFERRAL = 'referralReward'
export const EXTRA_PAYMENT_BAD_WEATHER = 'badWeatherSurge'
export const EXTRA_PAYMENT_CONVERSION = 'conversionReward'

// Global search constants
export const GLOBAL_SEARCH = {
  MISSION: 'MISSION',
  CAMPAIGN: 'CAMPAIGN',
  LOCATION: 'LOCATION',
  USER: 'USER',
  CLIENT: 'CLIENT',
  INVENTORY_CODE: 'INVENTORY_CODE',
  CITY: 'CITY',
  PARTICIPANT: 'PARTICIPANT',
}

export const UNVERIFIED_USER_ERROR = 'EMAIL_NOT_VERIFIED'
export const TOO_FAR_FROM_LOCATION_ERROR = 'TOO_FAR_FROM_LOCATION_ERROR'
export const MISSION_EXPIRED_ERROR = 'MISSION_EXPIRED_ERROR'
export const MISSION_NOT_YET_ACTIVE_ERROR = 'MISSION_NOT_YET_ACTIVE_ERROR'
export const MISSION_LB_EXPIRED_ERROR = 'MISSION_LB_EXPIRED_ERROR'
export const MISSION_LB_NOT_YET_ACTIVE_ERROR = 'MISSION_LB_NOT_YET_ACTIVE_ERROR'

// Used for the pay per hour feature, will split the payout via this amount
export const MISSION_DURATION = 3

// UNBOOK_REASONS
export const UNBOOK_REASONS = {
  BAD_WEATHER: 'BAD_WEATHER',
  CLIENT_REQUEST: 'CLIENT_REQUEST',
  BA_REQUEST: 'BA_REQUEST',
  CHEATING_SUSPICIOUS_BEHAVIOUR: 'CHEATING_SUSPICIOUS_BEHAVIOUR',
}

export const READABLE_REASONS = {
  [UNBOOK_REASONS.BAD_WEATHER]: 'Bad Weather',
  [UNBOOK_REASONS.CLIENT_REQUEST]: 'Client Request',
  [UNBOOK_REASONS.BA_REQUEST]: 'Brand Ambassador Request',
  [UNBOOK_REASONS.CHEATING_SUSPICIOUS_BEHAVIOUR]:
    'Cheating / Suspicious Behaviour',
}

// WEBSOCKETS
export const WEBSOCKETS_DOMAINS = {
  'dev': 'localhost:3001',
  'development': 'localhost:3001',
  'phoebe': 'sjg8mjdq7c.execute-api.us-east-1.amazonaws.com',
  'Phoebe': 'sjg8mjdq7c.execute-api.us-east-1.amazonaws.com',
  'titan': '5vv67tboy5.execute-api.us-east-1.amazonaws.com',
  'Titan': '5vv67tboy5.execute-api.us-east-1.amazonaws.com',
  'prod': 'f6p30h9gvb.execute-api.us-east-1.amazonaws.com',
  'oppizi': 'f6p30h9gvb.execute-api.us-east-1.amazonaws.com',
  'promofy': 'g7ln94iejh.execute-api.us-east-1.amazonaws.com',
  'Promofy': 'g7ln94iejh.execute-api.us-east-1.amazonaws.com',
}
export const WEBSOCKETS_URLS = {
  'dev': `ws://${WEBSOCKETS_DOMAINS['dev']}`,
  'development': `ws://${WEBSOCKETS_DOMAINS['dev']}`,
  'phoebe': `wss://${WEBSOCKETS_DOMAINS['phoebe']}/phoebe`,
  'Phoebe': `wss://${WEBSOCKETS_DOMAINS['phoebe']}/phoebe`,
  'titan': `wss://${WEBSOCKETS_DOMAINS['titan']}/titan`,
  'Titan': `wss://${WEBSOCKETS_DOMAINS['titan']}/titan`,
  'prod': `wss://${WEBSOCKETS_DOMAINS['prod']}/prod`,
  'oppizi': `wss://${WEBSOCKETS_DOMAINS['prod']}/prod`,
  'promofy': `wss://${WEBSOCKETS_DOMAINS['promofy']}/promofy`,
  'Promofy': `wss://${WEBSOCKETS_DOMAINS['promofy']}/promofy`,
}

// CHAT
export const CHAT_MAX_TEXT_LENGTH = 1023
export const CHAT_STAFF_ACTIVE_DURATION_MINUTES = 120
export const CHAT_MESSAGE_TYPES = {
  TEXT: 'TEXT',
  LOCATION: 'LOCATION',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  FILE: 'FILE',
}

export const NOTIFICATION_TYPES = {
  CHAT_MESSAGE_RECEIVED: 'CHAT_MESSAGE_RECEIVED',
}

// Pitch questions
export const PITCH_QUESTION_TYPES = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  CHECK_BOX: 'CHECK_BOX',
  DROPDOWN: 'DROPDOWN',
}

export const CHAT_STRING_TYPES = {
  TEXT: 'TEXT',
  URL: 'URL',
}

export const INVOICE_TYPE_GENERAL = 'general'

// Campaign Types

export const CAMPAIGN_TYPES = {
  HANDTOHAND: 1,
  LETTERBOX: 2,
  DIRECTMAIL: 3,
  UNCERTAIN: 4,
}

export const CAMPAIGN_ENUM_TYPES = {
  HANDTOHAND: 'HANDTOHAND',
  LETTERBOX: 'LETTERBOX',
  DIRECTMAIL: 'DIRECTMAIL',
  UNCERTAIN: 'UNCERTAIN',
}

export const USER_CAMPAIGN_ENUM_TYPES = {
  HANDTOHAND: 'Hand to hand',
  LETTERBOX: 'Door to door',
  DIRECTMAIL: 'Direct Mail',
  UNCERTAIN: 'Uncertain',
}

export const USER_CAMPAIGN_ABBREVIATION_ENUM_TYPES = {
  HANDTOHAND: 'H2H',
  LETTERBOX: 'D2D',
  DIRECTMAIL: 'DM',
  UNCERTAIN: 'UNCERTAIN',
}

export const CAMPAIGN_ENUM_TO_TYPES_MAP = {
  [CAMPAIGN_ENUM_TYPES.HANDTOHAND]: CAMPAIGN_TYPES.HANDTOHAND,
  [CAMPAIGN_ENUM_TYPES.LETTERBOX]: CAMPAIGN_TYPES.LETTERBOX,
  [CAMPAIGN_ENUM_TYPES.DIRECTMAIL]: CAMPAIGN_TYPES.DIRECTMAIL,
  [CAMPAIGN_ENUM_TYPES.UNCERTAIN]: CAMPAIGN_TYPES.UNCERTAIN,
}

export const CAMPAIGN_TYPES_TO_ENUM_MAP = {
  [CAMPAIGN_TYPES.HANDTOHAND]: CAMPAIGN_ENUM_TYPES.HANDTOHAND,
  [CAMPAIGN_TYPES.LETTERBOX]: CAMPAIGN_ENUM_TYPES.LETTERBOX,
  [CAMPAIGN_TYPES.DIRECTMAIL]: CAMPAIGN_ENUM_TYPES.DIRECTMAIL,
  [CAMPAIGN_TYPES.UNCERTAIN]: CAMPAIGN_ENUM_TYPES.UNCERTAIN,
}

// Mission Types
export const MISSION_TYPES = {
  HANDTOHAND: 1,
  LETTERBOX: 2,
}

export const MISSION_ENUM_TYPES = {
  HANDTOHAND: 'HANDTOHAND',
  LETTERBOX: 'LETTERBOX',
}

export const MISSION_ENUM_TO_TYPES_MAP = {
  [MISSION_ENUM_TYPES.HANDTOHAND]: MISSION_TYPES.HANDTOHAND,
  [MISSION_ENUM_TYPES.LETTERBOX]: MISSION_TYPES.LETTERBOX,
}

export const MISSION_TYPES_TO_ENUM_MAP = {
  [MISSION_TYPES.HANDTOHAND]: MISSION_ENUM_TYPES.HANDTOHAND,
  [MISSION_TYPES.LETTERBOX]: MISSION_ENUM_TYPES.LETTERBOX,
}

// City
export const CITY_STATUS_ENUM_ACTIVE = 'ACTIVE'
export const CITY_STATUS_ENUM_ARCHIVED = 'ARCHIVED'
export const CITY_STATUS_ACTIVE = 1
export const CITY_STATUS_ARCHIVED = 0
export const CITY_STATUSES_TO_ENUM_MAP = {
  [CITY_STATUS_ACTIVE]: CITY_STATUS_ENUM_ACTIVE,
  [CITY_STATUS_ARCHIVED]: CITY_STATUS_ENUM_ARCHIVED,
}

export const CLIENT_STATUS_ENUM = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
}

export const CLIENT_ENUM_TO_TYPES_MAP = {
  [CLIENT_STATUS_ENUM.ACTIVE]: STATUS_ACTIVE,
  [CLIENT_STATUS_ENUM.DELETED]: STATUS_DELETED,
}

export const CLIENT_STATUS_INT_TO_ENUM = {
  [STATUS_ACTIVE]: CLIENT_STATUS_ENUM.ACTIVE,
  [STATUS_DELETED]: CLIENT_STATUS_ENUM.DELETED,
}

export const LOCATION_STATUS_ENUM = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  ARCHIVED: 'ARCHIVED',
}
export const LOCATION_STATUS = {
  DISABLED: STATUS_DISABLED,
  ENABLED: STATUS_ENABLED,
  ARCHIVED: STATUS_LOCATION_ARCHIVED,
}
export const LOCATION_ENUM_TO_TYPES_MAP = {
  [LOCATION_STATUS_ENUM.DISABLED]: LOCATION_STATUS.DISABLED,
  [LOCATION_STATUS_ENUM.ENABLED]: LOCATION_STATUS.ENABLED,
  [LOCATION_STATUS_ENUM.ARCHIVED]: LOCATION_STATUS.ARCHIVED,
}

export const LOCATION_STATUS_TYPE_TO_ENUM = {
  [LOCATION_STATUS.DISABLED]: LOCATION_STATUS_ENUM.DISABLED,
  [LOCATION_STATUS.ENABLED]: LOCATION_STATUS_ENUM.ENABLED,
  [LOCATION_STATUS.ARCHIVED]: LOCATION_STATUS_ENUM.ARCHIVED,
}

export const LOCATION_SORT_COLUMN_ENUM = {
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
  LABEL_ASC: 'LABEL_ASC',
  LABEL_DESC: 'LABEL_DESC',
  TYPE_ASC: 'TYPE_ASC',
  TYPE_DESC: 'TYPE_DESC',
  IS_MORNING_ASC: 'IS_MORNING_ASC',
  IS_MORNING_DESC: 'IS_MORNING_DESC',
  IS_LUNCH_ASC: 'IS_LUNCH_ASC',
  IS_LUNCH_DESC: 'IS_LUNCH_DESC',
  IS_AFTERNOON_ASC: 'IS_AFTERNOON_ASC',
  IS_AFTERNOON_DESC: 'IS_AFTERNOON_DESC',
  IS_SHELTERED_ASC: 'IS_SHELTERED_ASC',
  IS_SHELTERED_DESC: 'IS_SHELTERED_DESC',
  IS_EVENT_ASC: 'IS_EVENT_ASC',
  IS_EVENT_DESC: 'IS_EVENT_DESC',
  IS_REQUIRE_PERMIT_ASC: 'IS_REQUIRE_PERMIT_ASC',
  IS_REQUIRE_PERMIT_DESC: 'IS_REQUIRE_PERMIT_DESC',
}

export const ONBOARDING_TYPES = {
  ONLINE: 10,
  OFFLINE: 20,
}

export const ONBOARDING_TYPES_ENUM = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
}

export const ONBOARDING_TYPES_ENUM_TO_INT_MAP = {
  [ONBOARDING_TYPES_ENUM.ONLINE]: ONBOARDING_TYPES.ONLINE,
  [ONBOARDING_TYPES_ENUM.OFFLINE]: ONBOARDING_TYPES.OFFLINE,
}

export const ONBOARDING_TYPES_INT_TO_ENUM_MAP = {
  [ONBOARDING_TYPES.ONLINE]: ONBOARDING_TYPES_ENUM.ONLINE,
  [ONBOARDING_TYPES.OFFLINE]: ONBOARDING_TYPES_ENUM.OFFLINE,
}

export const RECRUITMENT_CHANNEL_OPTIONS = [
  'Email',
  'Text',
  'Brand Ambassador distributing',
  'LinkedIn',
  'Facebook',
  'Twitter',
  'Slack',
  'Friends/Family',
  'Instagram',
  'Gumtree',
  'Craiglist',
]

export const TAG_TYPE_ENUM = {
  CLIENT: 'CLIENT',
}

export const APP_DOWNLOAD_LINK = {
  ANDROID: {
    PROD: 'https://play.google.com/store/apps/details?id=com.oppizi',
    TITAN: 'https://play.google.com/store/apps/details?id=com.oppizi.staging',
    PHOEBE: 'https://play.google.com/store/apps/details?id=com.oppizi.uat',
    PROMOFY: 'https://play.google.com/store/apps/details?id=com.promofy.app',
  },
  IOS: {
    PROD: 'https://apps.apple.com/us/app/oppizi/id1342941948',
    TITAN: ' https://apps.apple.com/us/app/oppizi-titan/id1445613003',
    PHOEBE: 'https://apps.apple.com/us/app/oppizi-phoebe/id1430074345',
    PROMOFY: 'https://apps.apple.com/us/app/promofy/id1483581564',
  },
}

export const CAMPAIGN_DELIVERABILITY_STATUS = {
  DIGITAL_PROOFS: 0,
  PRINTING: 10,
  OUT_FOR_DELIVERY: 20,
  RECEIVED_IN_LOCAL_OFFICE: 30,
  OUT_FOR_POSTAGE: 40,
  DISTRIBUTED: 50,
}

export const CAMPAIGN_DELIVERABILITY_STATUS_ENUM = {
  DIGITAL_PROOFS: 'DIGITAL_PROOFS',
  PRINTING: 'PRINTING',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  RECEIVED_IN_LOCAL_OFFICE: 'RECEIVED_IN_LOCAL_OFFICE',
  OUT_FOR_POSTAGE: 'OUT_FOR_POSTAGE',
  DISTRIBUTED: 'DISTRIBUTED',
}

export const CAMPAIGN_DELIVERABILITY_STATUS_ENUM_TO_INT_MAP = {
  [CAMPAIGN_DELIVERABILITY_STATUS_ENUM.DIGITAL_PROOFS]:
    CAMPAIGN_DELIVERABILITY_STATUS.DIGITAL_PROOFS,
  [CAMPAIGN_DELIVERABILITY_STATUS_ENUM.PRINTING]:
    CAMPAIGN_DELIVERABILITY_STATUS.PRINTING,
  [CAMPAIGN_DELIVERABILITY_STATUS_ENUM.OUT_FOR_DELIVERY]:
    CAMPAIGN_DELIVERABILITY_STATUS.OUT_FOR_DELIVERY,
  [CAMPAIGN_DELIVERABILITY_STATUS_ENUM.RECEIVED_IN_LOCAL_OFFICE]:
    CAMPAIGN_DELIVERABILITY_STATUS.RECEIVED_IN_LOCAL_OFFICE,
  [CAMPAIGN_DELIVERABILITY_STATUS_ENUM.OUT_FOR_POSTAGE]:
    CAMPAIGN_DELIVERABILITY_STATUS.OUT_FOR_POSTAGE,
  [CAMPAIGN_DELIVERABILITY_STATUS_ENUM.DISTRIBUTED]:
    CAMPAIGN_DELIVERABILITY_STATUS.DISTRIBUTED,
}

export const CAMPAIGN_DELIVERABILITY_STATUS_INT_TO_ENUM_MAP = {
  [CAMPAIGN_DELIVERABILITY_STATUS.DIGITAL_PROOFS]:
    CAMPAIGN_DELIVERABILITY_STATUS_ENUM.DIGITAL_PROOFS,
  [CAMPAIGN_DELIVERABILITY_STATUS.PRINTING]:
    CAMPAIGN_DELIVERABILITY_STATUS_ENUM.PRINTING,
  [CAMPAIGN_DELIVERABILITY_STATUS.OUT_FOR_DELIVERY]:
    CAMPAIGN_DELIVERABILITY_STATUS_ENUM.OUT_FOR_DELIVERY,
  [CAMPAIGN_DELIVERABILITY_STATUS.RECEIVED_IN_LOCAL_OFFICE]:
    CAMPAIGN_DELIVERABILITY_STATUS_ENUM.RECEIVED_IN_LOCAL_OFFICE,
  [CAMPAIGN_DELIVERABILITY_STATUS.OUT_FOR_POSTAGE]:
    CAMPAIGN_DELIVERABILITY_STATUS_ENUM.OUT_FOR_POSTAGE,
  [CAMPAIGN_DELIVERABILITY_STATUS.DISTRIBUTED]:
    CAMPAIGN_DELIVERABILITY_STATUS_ENUM.DISTRIBUTED,
}

export const DocumentFormats = {
  A5: 'A5',
  A6: 'A6',
  DOOR_HANGER: 'DOOR_HANGER',
  TEARAWAY: 'TEARAWAY',
  DL: 'DL',
}

export const DocumentNames = {
  A5: 'A5',
  A6: 'A6',
  DOOR_HANGER: 'Door hanger',
  TEARAWAY: 'Tearaway',
  DL: 'DL',
}

export const DMDocumentFormats = {
  SMALL: 'SMALL',
  BEST_SELLER: 'BEST_SELLER',
  LARGE: 'LARGE',
  JUMBO: 'JUMBO',
  OVERSIZED: 'OVERSIZED',
}

export const DMDocumentNames = {
  SMALL: 'Small',
  BEST_SELLER: 'Best seller',
  LARGE: 'Large',
  JUMBO: 'Jumbo',
  OVERSIZED: 'Oversized',
}

export const DMDocumentLabels = {
  SMALL: 'Small: 4.25” x 11”',
  BEST_SELLER: 'Best seller: 6.25” x 9”',
  LARGE: 'Large: 6.25” x 11”',
  JUMBO: 'Jumbo: 8.5” x 11”',
  OVERSIZED: 'Oversized: 12” x 15”',
}

export const PrintersLabels = {
  name: 'Printers',
  availableStates: 'States',
  region: 'Region',
  actions: 'Actions',
}

export const StatesRegions = {
  southeast: 'South East',
  midwest: 'Mid West',
  west: 'West',
  northeast: 'North East',
}

export const CAMPAIGN_LOG_ENUM_TYPES = {
  ARCHIVE_CAMPAIGN: 'ARCHIVE_CAMPAIGN',
  APPROVE_CAMPAIGN: 'APPROVE_CAMPAIGN',
  DECLINE_CAMPAIGN: 'DECLINE_CAMPAIGN',
  GENERATE_CODES: 'GENERATE_CODES',
  DELETE_CODES: 'DELETE_CODES',
  APPROVE_CODES: 'APPROVE_CODES',
  UPLOAD_DIGITAL_PROOF: 'UPLOAD_DIGITAL_PROOF',
  DELETE_DIGITAL_PROOF: 'DELETE_DIGITAL_PROOF',
  UPLOAD_STATEMENT_OF_WORK: 'UPLOAD_STATEMENT_OF_WORK',
  DELETE_STATEMENT_OF_WORK: 'DELETE_STATEMENT_OF_WORK',
  ACTIVATE_CAMPAIGN: 'ACTIVATE_CAMPAIGN',
  UPLOAD_PDF_DESIGN: 'UPLOAD_PDF_DESIGN',
  DELETE_PDF_DESIGN: 'DELETE_PDF_DESIGN',
  SET_FLYER_ORIENTATION: 'SET_FLYER_ORIENTATION',
  SET_FLYER_DESIGN_URL: 'SET_FLYER_DESIGN_URL',
  SET_CAMPAIGN_NAME: 'SET_CAMPAIGN_NAME',
  SET_COUPON_CODE: 'SET_COUPON_CODE',
  SET_NAME: 'SET_NAME',
  SET_STATUS: 'SET_STATUS',
  SET_START_DATE: 'SET_START_DATE',
  SET_COST_PER_FLYER: 'SET_COST_PER_FLYER',
  SET_OFFER_EXPIRATION_DATE: 'SET_OFFER_EXPIRATION_DATE',
  SET_REDEMPTION_TIME: 'SET_REDEMPTION_TIME',
  SET_DURATION: 'SET_DURATION',
  SET_EXPIRATION: 'SET_EXPIRATION',
  SKIP_PAYMENT: 'SKIP_PAYMENT',
  SET_BONUS_ALLOWANCE: 'SET_BONUS_ALLOWANCE',
  SET_BONUS_PER_CONVERSION: 'SET_BONUS_PER_CONVERSION',
  SET_CONVERSION_API: 'SET_CONVERSION_API',
  SET_SCAN_DATA: 'SET_SCAN_DATA',
  ADD_METRIC: 'ADD_METRIC',
  DELETE_METRIC: 'DELETE_METRIC',
  SET_RECEIVE_REPORT: 'SET_RECEIVE_REPORT',
  SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
  SET_FLYER_TYPE: 'SET_FLYER_TYPE',
  SET_FLYER_WEIGHT: 'SET_FLYER_WEIGHT',
  ADD_AREA: 'ADD_AREA',
  ADD_LOCATION: 'ADD_LOCATION',
  DELETE_AREA: 'DELETE_AREA',
  DELETE_LOCATION: 'DELETE_LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',
  SET_FLYER_URL: 'SET_FLYER_URL',
  SET_LAUNCH_DATE: 'SET_LAUNCH_DATE',
  SET_FLYER_HAS_URL_CHECKBOX: 'SET_FLYER_HAS_URL_CHECKBOX',
  SET_COMMENTS: 'SET_COMMENTS',
  CAMPAIGN_SUBMITTED: 'CAMPAIGN_SUBMITTED',
}

