export const styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  mapWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    padding: 0,
    position: "relative",

    "& .gm-style .gm-style-iw-c": {
      backgroundColor: "white !important",
      color: "#011533 !important",
      fontSize: "14px !important",
      lineHeight: "20px !important",
      padding: "0px !important",

      "& .gm-style-iw-d": {
        overflow: "hidden !important",
      },

      "& > button": {
        display: "none !important",
      },
    },

    "& .gm-style-iw-tc": {
      display: "none",
    },
  },

  selectedCitiesList: {
    display: "flex",
    justifyContent: "end",
    margin: "26px 0 24px",
  },
  selectedCity: {
    marginRight: "8px",
    padding: "4px 12px",
    // border: "1px solid #BDBDBD",
    borderRadius: "8px",
    background: "#C2C8D1",
    color: "#000000",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
  },
  mr_10: {
    marginRight: "10px",
  },
  showOperatingZoneWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-14px",
  },
  font_size_12: {
    fontSize: 12,
  },
  font_size_14: {
    fontSize: 14,
  },
  locationNameLabel: {
    padding: "3px 10px",
  },
  cursor: {
    cursor: "pointer",
  },
  clusters: {
    backgroundColor: "#011533",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "25px",
    width: "25px",
    borderRadius: "50%",
    "& img": {
      display: "none",
    },
  },
};
