import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Grid, Paper, Box, Typography, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import DetailsForm from "components/details-form";
import Header from "components/header";
import nextIcon from "static/media/next.svg";
import { ProgressBarContext } from "components/progress-bar";
import { SnackBarContext } from "components/snack-bar";
import { LightTooltip } from "components/light-tooltip";
import { CustomTooltipContent } from "components/tooltip-content";
import ROUTES, { DM_ROUTING } from "config/routing";
import { authorization } from "module/auth";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { gtagWrapper, checkIsNeedUpdateLastActiveStepState } from "utils";
import { EDDM_NAVIGATION, NAVIGATION, CAMPAIGN_INTERNAL_STATUSES } from "../../constants";
import { getCampaignDynamicCode, updateCampaign as updateCampaignDB, updateCampaignExtraData } from "../../../graphQL";
import BlockingOverlay from "../../../components/BlockingOverlay";
import style from "./style.js";
import { useStore } from "../../../store";
import { insertCampaignLogs } from "../../../Logs/campaign/gql";
import { CAMPAIGN_LOG_ENUM_TYPES } from "../../../shared/constants";
import { replaceNullValue } from "../../../Logs/campaign/utils";
import IntegrationsService from "../../../module/IntegrationsService/client";

const Details = ({ classes }) => {
  const history = useHistory();

  const {
    user: { id: userId },
    client: { id: clientId },
    campaign: {
      id: campaignId,
      campaignName,
      isSubmitted,
      startDate,
      channel,
      campaignComments = "",
      qrCodeLink,
      lastActiveStep,
      isTrackableCoupon,
      couponType,
      staticCouponCode,
      dynamicCouponCode,
      isDM,
    },
    errors: { qrCodeUrlError, launchDateError, noCouponCode },
    updateCampaign,
  } = useStore();

  const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);
  const isAdmin = authorization.isAdmin();

  const { flyerInfo, isLoading: isLoadingFlyerInfo } = useGetFlyerInfo();
  const [isBlocked, setIsBlocked] = useState(false);
  const [campaignNameDisabled, setCampaignNameDisabled] = useState(true);

  const [qrCodeNotRequired, setQrCodeNotRequired] = useState(!!startDate && !qrCodeLink);
  const [{ oldCampaignName, oldQrCodeLink, oldCampaignComments, oldStartDate, oldQrCodeNotRequired }] = useState({
    oldCampaignName: campaignName,
    oldQrCodeLink: qrCodeLink,
    oldCampaignComments: campaignComments,
    oldStartDate: startDate,
    qrCodeNotRequired,
    oldQrCodeNotRequired: !!startDate && !qrCodeLink,
  });

  const isFlyerUploaded = useMemo(
    () => (flyerInfo && !isLoadingFlyerInfo ? flyerInfo.isFlyerUploaded : null),
    [flyerInfo, isLoadingFlyerInfo]
  );

  const submitDisabled = useMemo(
    () =>
      qrCodeUrlError ||
      !startDate ||
      !campaignNameDisabled ||
      launchDateError ||
      (isTrackableCoupon && noCouponCode && !isAdmin),
    [
      qrCodeUrlError,
      startDate,
      isFlyerUploaded,
      campaignNameDisabled,
      launchDateError,
      noCouponCode,
      isTrackableCoupon,
      isAdmin,
    ]
  );

  const TooltipContent = useMemo(() => {
    if (!startDate) {
      return (
        <CustomTooltipContent title="Launch date missing!" content="Please, select a launch date for your campaign." />
      );
    }

    if (qrCodeUrlError) {
      return <CustomTooltipContent title="URL missing!" content="Please, provide a valid URL address." />;
    }

    return "";
  }, [startDate, qrCodeUrlError]);

  const getCouponState = () => {
    return isTrackableCoupon
      ? couponType === "static"
        ? { staticCouponCode, dynamicCouponCode: null }
        : { staticCouponCode: "", dynamicCouponCode }
      : { staticCouponCode: "", dynamicCouponCode: null, couponType: null };
  };

  const saveCampaign = useCallback(async () => {
    runProgressBar(60);
    let succeed;

    let convertedDate = null;
    if (startDate && !isNaN(+startDate)) {
      // Saving the date in Postgres in UTC WITHOUT OFFSET.
      // Just to persist the actual DATE in the selected city.
      convertedDate = `${moment(startDate).format("YYYY-MM-DD")}T00:00:00.000Z`;
    }

    try {
      if (!campaignId) {
        throw Error("There is no campaign id");
      } else {
        await updateCampaignDB({
          campaignId,
          name: campaignName.trim(),
          comments: campaignComments,
          startDate: convertedDate,
        });
        runProgressBar(80);

        const couponState = getCouponState();

        if (couponState.dynamicCouponCode) {
          const { couponId, integrationId } = JSON.parse(dynamicCouponCode);

          const oldDynamicCouponCode = await getCampaignDynamicCode(campaignId, clientId);

          if (oldDynamicCouponCode) {
            const { couponId: oldCouponId, integrationId: oldIntegrationId } = JSON.parse(oldDynamicCouponCode);
            await IntegrationsService.updateCoupons({
              integrationId: oldIntegrationId,
              clientId,
              payload: { couponId: oldCouponId, used: false },
            });
          }
          await IntegrationsService.updateCoupons({
            integrationId,
            clientId,
            payload: {
              couponId,
              used: true,
            },
          });
        }

        await updateCampaignExtraData({
          ...{
            campaignId: campaignId,
            lastActiveStep: "details",
            internalStatus: CAMPAIGN_INTERNAL_STATUSES.DRAFT,
            qrCodeLink: qrCodeLink,
            startDate: convertedDate,
          },
          ...couponState,
        });
        updateCampaign(couponState);

        const logs = [];

        if (campaignName.trim() !== oldCampaignName) {
          logs.push({
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_NAME,
            additionalInfo: `Details page [CB] CAMPAIGN EDIT from ${replaceNullValue(
              oldCampaignName
            )} to ${replaceNullValue(campaignName.trim())}`,
          });
        }

        if (campaignComments !== oldCampaignComments) {
          logs.push({
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_COMMENTS,
            additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldCampaignComments)} to ${replaceNullValue(
              campaignComments
            )}`,
          });
        }

        if (qrCodeLink !== oldQrCodeLink) {
          logs.push({
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_URL,
            additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldQrCodeLink || null)} to ${replaceNullValue(
              qrCodeLink || null
            )}`,
          });
        }

        if (new Date(startDate).getTime() !== new Date(oldStartDate).getTime()) {
          logs.push({
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_LAUNCH_DATE,
            additionalInfo: `[CB] CAMPAIGN EDIT from ${
              oldStartDate ? moment(oldStartDate).format("YYYY-MM-DD") : " - "
            } to ${startDate ? moment(startDate).format("YYYY-MM-DD") : " - "}`,
          });
        }
        if (oldQrCodeNotRequired !== qrCodeNotRequired) {
          logs.push({
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.SET_FLYER_HAS_URL_CHECKBOX,
            additionalInfo: `[CB] CAMPAIGN EDIT from ${replaceNullValue(oldQrCodeNotRequired)} to ${replaceNullValue(
              qrCodeNotRequired
            )}`,
          });
        }

        if (logs.length) {
          await insertCampaignLogs(logs);
        }

        updateCampaign({
          campaignName: campaignName.trim(),
          startDate: startDate,
          qrCodeLink,
          internalStatus: CAMPAIGN_INTERNAL_STATUSES.DRAFT,
        });
      }

      runSnackBar({
        type: "success",
        msg: `Saved.`,
        vertical: "bottom",
        horizontal: "right",
      });
      succeed = true;
      runProgressBar(0);
    } catch (error) {
      runSnackBar({
        type: "error",
        msg: `Server error failed to save campaign. ${error.message}`,
        vertical: "bottom",
        horizontal: "right",
      });
      succeed = false;
      runProgressBar(0);
    }
    return succeed;
  }, [
    campaignName,
    campaignComments,
    startDate,
    runProgressBar,
    runSnackBar,
    qrCodeLink,
    qrCodeNotRequired,
    isTrackableCoupon,
    couponType,
    staticCouponCode,
    dynamicCouponCode,
  ]);

  const handleSave = useCallback(async () => {
    if (isLoadingFlyerInfo) {
      return;
    }

    setIsBlocked(true);

    gtagWrapper({
      event: "ss_details_completed",
      client_id: clientId?.toString(),
      user_id: userId,
      campaign_id: campaignId,
    });
    if (isSubmitted) {
      if (isDM) {
        return history.push(generatePath(DM_ROUTING.DM_SUBMIT, { campaignId, clientId }));
      } else {
        return history.push(generatePath(ROUTES.SUBMIT, { campaignId, clientId }));
      }
    }
    const result = await saveCampaign();
    if (result) {
      const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
        stateLastActiveStep: lastActiveStep,
        newLastActiveStep: "submit",
        isDM: isDM,
      });
      if (isNeedUpdateLastActiveStep) {
        await updateCampaignExtraData({
          campaignId,
          lastActiveStep: "details",
        });
        updateCampaign({ lastActiveStep: "details" });
      }
      if (isDM) {
        return history.push(generatePath(DM_ROUTING.DM_SUBMIT, { campaignId, clientId }));
      } else {
        return history.push(generatePath(ROUTES.SUBMIT, { campaignId, clientId }));
      }
    }

    setIsBlocked(false);
  }, [saveCampaign, isLoadingFlyerInfo]);

  const headerActions = {
    BACK: {
      action: async () => {
        if (isLoadingFlyerInfo) {
          return;
        }

        if (isDM) {
          return history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, { campaignId, clientId }));
        } else {
          return history.push(
            generatePath(isFlyerUploaded ? ROUTES.UPLOAD_FLYER : ROUTES.FLYER_BUILDER, {
              campaignId,
              clientId,
            })
          );
        }
      },
    },
  };

  useEffect(() => {
    if (!campaignId && !channel) {
      history.push(generatePath(ROUTES.ERROR));
    }
  }, []);

  return (
    <Grid container justify="center">
      {isBlocked ? <BlockingOverlay /> : null}
      <Grid xs={12} item>
        <Helmet>
          <title>Campaign Details</title>
        </Helmet>
        <Header
          tabs={isDM ? EDDM_NAVIGATION : NAVIGATION}
          activeTabIndex={3}
          headerActions={headerActions}
          hasError={submitDisabled}
          isDM={isDM}
        />
        <Paper className={classes.paper} elevation={0} square>
          <Container className={classes.container}>
            <Grid
              className={classes.scrollWrap}
              xs={10}
              sm={6}
              md={5}
              lg={4}
              spacing={3}
              container
              item
              justify="center"
            >
              <Typography variant="h4" className={classes.title}>
                Campaign Details
              </Typography>
              <DetailsForm
                isFlyerUploaded={isFlyerUploaded}
                qrCodeNotRequired={qrCodeNotRequired}
                setQrCodeNotRequired={setQrCodeNotRequired}
                campaignNameDisabled={campaignNameDisabled}
                setCampaignNameDisabled={setCampaignNameDisabled}
              />
              <Box className={classes.nextBtnWrapper}>
                <LightTooltip title={TooltipContent} placement="top">
                  <Typography
                    className={cn(classes.navigationBtn, {
                      [classes.navigationActive]: !submitDisabled,
                      [classes.navigationDisabled]: submitDisabled,
                    })}
                    onClick={submitDisabled ? () => {} : handleSave}
                    cypress_id={"next-btn"}
                  >
                    {false && <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />} {"Next"}
                    <img src={nextIcon} style={{ position: "absolute", right: "28px" }} />
                  </Typography>
                </LightTooltip>
              </Box>
            </Grid>
          </Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Details);
