import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "html > body": {
        position: "static !important",
      },

      "svg:not(:root)": {
        overflow: "visible",
      },

      "*::-webkit-scrollbar": { width: "8px", height: "8px", zIndex: 999 },

      "*::-webkit-scrollbar-track": {
        WebkitBorderRadius: "10px",
        borderRadius: "10px",
      },

      "*::-webkit-scrollbar-thumb": {
        background: "rgba(196, 196, 196, 0.5)",
        borderRadius: "6px",
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
