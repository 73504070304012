import { memo, useCallback, useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import CONFIG from "config/config";
import Image from "material-ui-image";

import { authorization } from "module/auth";

import Form, { Input, Button } from "components/form";
import { ProgressBarContext } from "components/progress-bar";
import { If } from "components/logic";

import logoImg from "static/media/logo.png";

import { authUser } from "../../../../graphQL";

import style from "./style.js";
import baseStyle from "../baseStyle.js";
import { ROLE_CLIENT, ROLE_CLIENT_ADMIN } from "../../../../shared/constants";

const Login = ({ classes }) => {
  const runProgressBar = useContext(ProgressBarContext);
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = useCallback(async () => {
    try {
      runProgressBar(60);
      const response = await authUser({
        email: email,
        password: password,
      });

      if (response.status === 200) {
        runProgressBar(80);
        const { data } = await response.json();
        if (
          [ROLE_CLIENT, ROLE_CLIENT_ADMIN].includes(data.authUser.user.role) &&
          typeof data.authUser.user.isSelfServeEnabled === "boolean" &&
          !data.authUser.user.isSelfServeEnabled
        ) {
          throw Error("Access denied");
        }

        authorization.login(data.authUser.user, data.authUser.token);
        window.location.replace(
          data.authUser.user.clientId ? `client/${data.authUser.user.clientId}/campaign/channel` : "/access-denied"
        );
        //TODO
        // Search last campaign created
        // redirect user to segments of last campaign
        // if there are no campaigns for the client then redirect to home.
        // window.location.replace(
        //   `${CONFIG.PLATFORM_BASE_URL}/dashboard/client-campaigns/${data.authUser.user.clientId}/campaign`
        // );
      }
      runProgressBar(0);
    } catch ({ message }) {
      if (message) {
        setError(message);
      }
      runProgressBar(0);
    }
  }, [email, password]);

  const handleChange = (value, key) => {
    switch (key) {
      case "email":
        return setEmail(value);
      case "password":
        return setPassword(value);

      default:
        break;
    }
  };

  return (
    <Fade in={true}>
      <Box className={classes.root}>
        <Box className={classes.logoWrap}>
          <Image src={logoImg} disableSpinner color="transparent" />
        </Box>
        <Typography className={classes.modalTitle}>Log in to Oppizi</Typography>
        <Form onSubmit={handleSubmit}>
          <Box mb="25px" className={classes.inputGroup}>
            <Input
              placeholder="Email address"
              type="email"
              name="email"
              value={email}
              onChange={(value) => handleChange(value, "email")}
            />
            <Input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(value) => handleChange(value, "password")}
            />
          </Box>
          <If condition={error}>
            <Box mb="15px" display="flex" justifyContent="center" alignItems="baseline">
              <Typography className={classes.error}>{error}</Typography>
            </Box>
          </If>
          <Button type="submit" variant="contained" color="secondary">
            Login
          </Button>
          <Box mt="150px"></Box>
        </Form>
      </Box>
    </Fade>
  );
};

export default memo(withStyles({ ...baseStyle, ...style })(Login));
