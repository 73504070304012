import CONFIG from "config/config";
import { callNoAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const authUser = (input) => {
  const mutation = `
    mutation($input: AuthUserInput) {
      authUser(input: $input){
        user {
          id
          clientId
          clientName
          firstName
          lastName
          isSelfServeEnabled
          role
          email
        }
        token
      }
    }`;

  return callNoAuthorizedGraphQLEndpoint(mutation, { input }, CONFIG.GRAPHQL_API_BASE_URL);
};
