import React, { useEffect, useState, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import nextIcon from "static/media/next.svg";
import CircularProgress from "@material-ui/core/CircularProgress";

import { styles } from "./style";
import {
  CAMPAIGN_ENUM_TYPES,
  DMDocumentLabels,
  USER_CAMPAIGN_ABBREVIATION_ENUM_TYPES,
  USER_CAMPAIGN_ENUM_TYPES,
} from "../../../../shared/constants";
import Input from "../../../../components/form/input";
import SelectMenu from "../../../flyer/builder/components/Inspector/components/SelectMenu";
import warningIcon from "./icons/warning-icon.svg";

const FLYER_FORMATS = Object.keys(DMDocumentLabels);

const ModalSelectChannel = ({
  classes,
  isOpen: isOpenProps,
  onClose,
  channel,
  clientName,
  onChangeCampaignName,
  campaignStateName,
  error,
  onSave,
  loading,
  flyerFormat,
  setFlyerFormat,
}) => {
  // const [isShowWarning, setIsShowWarning] = useState(true);

  const disabledButton = !campaignStateName || error || loading;

  const isDM = channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !disabledButton) {
      onSave();
    }
  };

  const isOpen = isOpenProps && channel;

  // useEffect(() => {
  //   if (!isOpen) {
  //     setIsShowWarning(true);
  //   }
  // }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="responsive-dialog-title" className={classes.dialog}>
      {isOpen ? (
        <Fragment>
          <Box display={"flex"} justifyContent={"end"} style={{ height: "60px" }}>
            <Button color="inherit" size="small" onClick={onClose}>
              <CloseIcon fontSize="default" />
            </Button>
          </Box>
          {isDM ? (
            <Box className={classes.contentWrapDM}>
              <DialogContent>
                <DialogTitle id="responsive-dialog-title" className={classes.title}>
                  Campaign settings
                </DialogTitle>
                <div className={cn(classes.content, classes.mt20)}>
                  <div>
                    <label className={classes.inputLabel}>
                      Give a name to your campaign (you can always change it later)
                    </label>
                    <Input
                      classesMap={classes.classNameInputDM}
                      name="campaignName"
                      value={campaignStateName}
                      variant="outlined"
                      placeholder={`${clientName} - ${USER_CAMPAIGN_ABBREVIATION_ENUM_TYPES[channel]} - Campaign name`}
                      onChange={onChangeCampaignName}
                      onKeyDown={handleKeyDown}
                      hidePlaceholderOnFocus
                      cypressID="campaignName-input"
                    />
                    <Box className={classes.error}>{error && typeof error === "string" ? error : null}</Box>
                  </div>
                  {/* {isShowWarning ? (
                    <div className={classes.inputWrap}>
                      <div className={classes.warningWrapper}>
                        <div className={classes.warningIcon}>
                          <img src={warningIcon} alt="icon" />
                        </div>
                        <div>
                          We need your print options upfront so we can accurately price your campaign. You can change
                          these settings later in the Settings of the campaign.
                        </div>
                        <div onClick={() => setIsShowWarning(false)} className={classes.closeIcon}>
                          <CloseIcon fontSize="default" />
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* <div className={classes.inputWrap}>
                    <label className={classes.inputLabel}>Choose the format of your flyers</label>
                    <SelectMenu
                      items={FLYER_FORMATS.map((item) => {
                        return {
                          label: DMDocumentLabels[item],
                          helperText: "",
                          action: () => {
                            setFlyerFormat(item);
                          },
                          isChecked: flyerFormat === item,
                        };
                      })}
                      displayValue={DMDocumentLabels[flyerFormat]}
                      isDisabled={false}
                    />
                  </div> */}
                  {/* <div className={classes.inputWrap}>
                    <label className={classes.inputLabel}>Choose sides to print</label>
                    <SelectMenu
                      items={["2 sides"].map((item) => {
                        return {
                          label: item,
                          helperText: "",
                          action: () => {
                            //TODO
                          },
                          isChecked: true,
                        };
                      })}
                      displayValue={"2 sides"}
                      isDisabled={true}
                    />
                  </div> */}
                  <Box className={classes.nextBtnWrapper} cypress_id="modalSelectChannel-nextBtn">
                    <Typography
                      className={cn(classes.navigationBtn, {
                        [classes.navigationActiveEDDM]: !disabledButton,
                        [classes.navigationDisabledEDDM]: disabledButton,
                      })}
                      onClick={!disabledButton ? onSave : () => {}}
                      tabIndex="0"
                      onKeyDown={handleKeyDown}
                    >
                      {loading && <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />}Save
                    </Typography>
                  </Box>
                </div>
              </DialogContent>
            </Box>
          ) : (
            <Box className={classes.contentWrap}>
              <DialogContent>
                <DialogTitle id="responsive-dialog-title" className={classes.title}>
                  {USER_CAMPAIGN_ENUM_TYPES[channel]}, great choice!
                </DialogTitle>
                <div className={classes.content}>
                  <div className={classes.text}>Now let’s name your campaign:</div>
                  <div>
                    <Input
                      classesMap={classes.classNameInput}
                      name="campaignName"
                      value={campaignStateName}
                      variant="outlined"
                      placeholder={`${clientName} - ${USER_CAMPAIGN_ABBREVIATION_ENUM_TYPES[channel]} - Campaign name`}
                      onChange={onChangeCampaignName}
                      onKeyDown={handleKeyDown}
                      hidePlaceholderOnFocus
                      cypressID="campaignName-input"
                    />
                    <Box className={classes.error}>{error && typeof error === "string" ? error : null}</Box>
                  </div>
                  <Box className={classes.nextBtnWrapper} cypress_id="modalSelectChannel-nextBtn">
                    <Typography
                      className={cn(classes.navigationBtn, {
                        [classes.navigationActive]: !disabledButton,
                        [classes.navigationDisabled]: disabledButton,
                      })}
                      onClick={!disabledButton ? onSave : () => {}}
                      tabIndex="0"
                      onKeyDown={handleKeyDown}
                    >
                      {loading && <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />}Next
                      <img src={nextIcon} style={{ position: "absolute", right: "28px" }} />
                    </Typography>
                  </Box>
                </div>
              </DialogContent>
            </Box>
          )}
        </Fragment>
      ) : null}
    </Dialog>
  );
};

export default withStyles(styles)(ModalSelectChannel);
