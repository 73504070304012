// @ts-nocheck
import { useCallback, useEffect, useRef, useState, memo } from "react";
import ReactDatePicker, { CalendarContainerProps, ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { StyledDatePickerContainer, StyledDatePicker } from "./DatePicker.styled";
import { Container, CustomInput, Header } from "./components";
import { CLASSNAME_NEW_SELECTED_DATES, CLASSNAME_OLD_SELECTED_DATES, TIMELIST, addDays } from "./utils";
import { useControlDate } from "./hooks";

export interface IDatePickerProps {
  oldDates?: Date[];
  onConfirm?: (selectedDates: Date[], selectedTime: number) => void;
  timezone?: string;
  defaultCampaignLimits: number;
}

interface HighlightDates {
  [className: string]: Date[];
}

export const DatePicker = memo(({ oldDates, onConfirm, timezone, defaultCampaignLimits }: IDatePickerProps) => {
  const today = new Date();

  const [isOpen, setIsOpen] = useState(false);

  const calendarRef = useRef<ReactDatePicker | null>(null);

  const {
    updatedOldDates,
    newSelectedDates,
    selectedTime,
    setOldSelectedDates,
    setNewSelectedDates,
    setSelectedTime,
    onChangeSelectedDate,
  } = useControlDate(oldDates || []);

  useEffect(() => {
    if (!isOpen && oldDates) {
      setOldSelectedDates(oldDates);
      setNewSelectedDates([]);
      setSelectedTime(TIMELIST[0]);
    }
  }, [isOpen, oldDates]);

  const handleClickConfirm = useCallback(() => {
    onConfirm && onConfirm(newSelectedDates, selectedTime);
    setIsOpen(false);
  }, [newSelectedDates, selectedTime, onConfirm]);

  const handleCalendarClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSelectDate = useCallback((date: Date) => {
    onChangeSelectedDate(date);
  }, []);

  const handleCustomInputClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const calendarContainer = (props: CalendarContainerProps) =>
    Container({
      ...props,
      selectedTime,
      isDisabled: !newSelectedDates.length,
      onCalendarClose: handleCalendarClose,
      onClickConfirm: handleClickConfirm,
      onChangeSelectedTime: setSelectedTime,
      defaultCampaignLimits,
    });

  const customHeader = (props: ReactDatePickerCustomHeaderProps) => <Header {...props} />;

  const highlightWithRanges: HighlightDates[] = [
    {
      [CLASSNAME_OLD_SELECTED_DATES]: updatedOldDates,
    },
    {
      [CLASSNAME_NEW_SELECTED_DATES]: newSelectedDates,
    },
  ];

  return (
    <>
      <CustomInput onClick={handleCustomInputClick} />
      {isOpen && (
        <StyledDatePickerContainer>
          <StyledDatePicker
            ref={calendarRef}
            renderCustomHeader={customHeader}
            calendarContainer={calendarContainer}
            highlightDates={highlightWithRanges}
            minDate={addDays(today, defaultCampaignLimits, timezone)}
            maxDate={addDays(today, 360, timezone)}
            monthsShown={2}
            calendarStartDay={1}
            shouldCloseOnSelect={false}
            inline
            onSelect={handleSelectDate}
            onChange={() => {}}
          />
        </StyledDatePickerContainer>
      )}
    </>
  );
});

export default DatePicker;
