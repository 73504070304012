export const styles = {
  dialog: {
    "& .MuiDialogContent-root, .MuiDialogTitle-root, .MuiDialogActions-root": {
      padding: 0,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "12px",

    "& > h2": {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "26px",
    },
  },
  titleIcon: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "12px",
    width: "32px",
    height: "32px",
  },
  body: {
    textAlign: "center",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    margin: 0,
  },
  content: {
    padding: "0 24px 40px",
  },
  cta: {
    background: "#EE4360",
    border: "1px solid #EE4360",
    borderRadius: "16px",
    padding: "14px 0",
    width: "150px",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "initial" as const,
    "&:hover": {
      background: "#FFFFFF",
      border: "1px solid #EE4360",
      color: "#EE4360",
    },
  },
  secondary: {
    borderRadius: "16px",
    border: "1px solid black",
    padding: "14px 0",
    width: "150px",
    fontWeight: "bold" as const,
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "initial" as const,
  },
};
