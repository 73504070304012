export const MeetingLinkMap = {
  US: "https://email.oppizi.com/meetings/arthur51?embed=true",
  CA: "https://email.oppizi.com/meetings/arthur51?embed=true",
  AU: "https://meetings.hubspot.com/alice-p1?embed=true",
  NZ: "https://meetings.hubspot.com/alice-p1?embed=true",
  BE: "https://meetings.hubspot.com/thomas-de-kerchove?embed=true",
  NL: "https://meetings.hubspot.com/thomas-de-kerchove?embed=true",
  FR: "https://meetings.hubspot.com/vincent-bonnet?embed=true",
  UK: "https://info.oppizi.com/meetings/nicolas202?embed=true",
  DE: "https://meetings.hubspot.com/sebastian-mark?embed=true",
};

export const DefaultMeetingLink = MeetingLinkMap["US"];
