import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloLink, concat } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import CampaignDataHOC from "campaign-data-hoc";
import { DialogModal } from "components/dialog-modal";
import ProgressBar from "components/progress-bar";
import SnackBar from "components/snack-bar";
import { PostHogProviderWrapper } from "components/post-hog-provider";
import ROUTING from "config/routing";
import Auth from "pages/auth";
import AccessDenied from "pages/campaign/access-denied";
import Audience from "pages/campaign/audience";
import Channel from "pages/campaign/channel";
import Details from "pages/campaign/details";
import Quote from "pages/campaign/quote";
import Builder from "pages/flyer/builder";
import SelectFlyerTypePage from "pages/flyer/flyerTypePage";
import UploadDesignPage from "pages/flyer/upload";
import ReactDOM from "react-dom";
import CONFIG from "config/config";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import GlobalStyles from "./global-styles";
import reportWebVitals from "./reportWebVitals";
import "./style.css";

import theme from "./theme";
import Login from "./pages/auth/components/login";

const httpLink = new HttpLink({ uri: CONFIG.FLYER_API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("apiKey");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});
export const LegacyApp = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <DialogModal>
          <SnackBar>
            <ProgressBar>
              <Auth>
                <Router>
                  <Switch>
                    <Route path={ROUTING.LOGIN} component={Login} />
                    <Route path={ROUTING.CAMPAIGN}>
                      <CampaignDataHOC>
                        <Switch>
                          <Route path={ROUTING.CAMPAIGN_CHANNEL} component={Channel} />
                          <Route path={ROUTING.CAMPAIGN_DETAILS} component={Details} />
                          <Route path={[ROUTING.CAMPAIGN_AUDIENCE]} component={Audience} />
                          <Route path={[ROUTING.SUBMIT, ROUTING.SUCCESS]} component={Quote} />
                          <Redirect to={ROUTING.CAMPAIGN_CHANNEL} />
                        </Switch>
                      </CampaignDataHOC>
                    </Route>
                    <Route path={ROUTING.FLYER}>
                      <CampaignDataHOC>
                        <Switch>
                          <Route path={ROUTING.FLYER_BUILDER} component={Builder} />
                          <Route path={ROUTING.UPLOAD_FLYER} component={UploadDesignPage} />
                          <Route path={ROUTING.FLYER} component={SelectFlyerTypePage} />
                        </Switch>
                      </CampaignDataHOC>
                    </Route>
                    <Route path={ROUTING.ACCESS_DENIED} component={AccessDenied} />
                    <Redirect to={ROUTING.CAMPAIGN} />
                  </Switch>
                </Router>
              </Auth>
            </ProgressBar>
          </SnackBar>
        </DialogModal>
      </ThemeProvider>
    </ApolloProvider>
  );
};
