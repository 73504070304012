/* eslint-disable react/no-unknown-property */
import React, { memo, PropsWithChildren } from "react";
import { withStyles } from "@material-ui/core";
import style from "pages/flyer/flyerTypePage/components/LinkToFile/style";
import { ReactComponent as DownloadIcon } from "static/media/download.svg";

type IProps = PropsWithChildren<{
  classes: any;
  href: string;
  linkText: string;
  icon?: boolean;
  cypressId: string;
}>;

const LinkToFile: React.FC<IProps> = ({ classes, href, linkText, cypressId, icon = false, children = undefined }) => {
  return (
    <span className={classes.inlineBlock}>
      <a className={classes.link} href={href} rel="noreferrer" target="_blank" cypress_id={cypressId}>
        {icon ? <DownloadIcon></DownloadIcon> : null}
        {linkText}
        {children}
      </a>
    </span>
  );
};

export default memo(withStyles(style)(LinkToFile));
