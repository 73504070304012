const SvgAttention = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9974 2.29199C14.2541 2.29199 17.7057 5.74283 17.7057 10.0003C17.7057 14.257 14.2541 17.7087 9.9974 17.7087C5.7399 17.7087 2.28906 14.257 2.28906 10.0003C2.28906 5.74283 5.7399 2.29199 9.9974 2.29199Z"
      stroke="#FC0202"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99479 6.83691V10.5194"
      stroke="#FC0202"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.99063 13.1637H9.99896" stroke="#FC0202" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgAttention;
