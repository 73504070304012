import { useContext } from "react";
import { EDDMContextProvider, EDDMContext } from "components/post-hog-provider";
import { LegacyApp } from "LegacyApp";
import { NewApp } from "NewApp";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./style.css";

const App = () => {
  const { isEddmEnabled } = useContext(EDDMContext);

  return isEddmEnabled ? <NewApp /> : <LegacyApp />;
};

ReactDOM.render(
  <EDDMContextProvider>
    <App />
  </EDDMContextProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
