import React, { createContext, useState, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";

import { styles } from "./style";

// @ts-ignore
const useStyles = makeStyles(() => styles);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DialogModalContext = createContext((state: DialogModalState) => {});

interface DialogModalState {
  open?: boolean;
  title?: string;
  contentText?: any;
  actions?: any;
  fullScreen?: boolean;
  disabledClose?: boolean;
  ctaLabel?: any;
  secondaryButtonLabel?: any;
  hasOptions?: boolean;
  handleCTAClick?: () => void;
  handleSecondaryButtonClick?: () => void;
  customCtaStyles?: Record<any, any>;
  customSecondaryStyles?: Record<any, any>;
  titleIcon?: any;
}

interface DialogModalProps {
  children: any;
}

export const DialogModal: React.FC<DialogModalProps> = ({ children }) => {
  const classes = useStyles();
  const [state, setState] = useState<DialogModalState>({ open: false });

  const runDialog = useCallback((state) => setState({ ...state, open: true }), []);

  const {
    title,
    contentText,
    actions,
    fullScreen,
    disabledClose = false,
    hasOptions,
    ctaLabel,
    secondaryButtonLabel,
    handleCTAClick,
    handleSecondaryButtonClick,
    customCtaStyles = {},
    customSecondaryStyles = {},
    titleIcon,
  } = state;

  const onClose = () => {
    if (!disabledClose) {
      setState({ open: false });
    }
  };

  const handleCTA = () => {
    if (handleCTAClick) {
      handleCTAClick();
    }
    onClose();
  };

  const handleCancel = () => {
    if (handleSecondaryButtonClick) {
      handleSecondaryButtonClick();
    }
    onClose();
  };

  const renderButtons = useCallback(() => {
    return Object.keys(state).length ? (
      <DialogActions style={{ justifyContent: "space-evenly", marginTop: "32px" }}>
        {actions ? actions : null}
        {hasOptions ? (
          <Button className={classes.secondary} style={customSecondaryStyles} onClick={() => handleCancel()}>
            {secondaryButtonLabel ? secondaryButtonLabel : "Cancel"}
          </Button>
        ) : null}
        <Button className={classes.cta} style={customCtaStyles} onClick={() => handleCTA()}>
          {ctaLabel ? ctaLabel : "Confirm"}
        </Button>
      </DialogActions>
    ) : null;
  }, [state.open]);

  return (
    <div>
      {state.open ? (
        <Dialog
          fullScreen={fullScreen}
          open={state.open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
          className={classes.dialog}
        >
          <Box display={"flex"} justifyContent={"end"} style={{ height: "60px" }}>
            {!disabledClose ? (
              <Button color="inherit" size="small" onClick={onClose}>
                <CloseIcon fontSize="default" />
              </Button>
            ) : null}
          </Box>
          <Box className={classes.content}>
            {contentText && (
              <DialogContent>
                {titleIcon ? <DialogTitle className={classes.title}>{titleIcon}</DialogTitle> : null}
                {title ? (
                  <DialogTitle id="responsive-dialog-title" className={classes.title}>
                    {title}
                  </DialogTitle>
                ) : null}
                <DialogContentText className={classes.body}>{contentText} </DialogContentText>
              </DialogContent>
            )}
            {renderButtons()}
          </Box>
        </Dialog>
      ) : null}
      <DialogModalContext.Provider value={runDialog}>{children}</DialogModalContext.Provider>
    </div>
  );
};
