const style = (theme) => {
  return {
    headerWrapper: {
      background: "#F9F9FF",
      boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
      height: "80px",
      width: "fit-content",
      minWidth: "100%",
      position: "relative",
      zIndex: 3,
    },

    eddmHeaderWrapper: {
      background: "#FFFFFF",
      boxShadow: "none",
    },

    container: {
      width: "1200px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "auto",
    },

    flyerContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
    },

    nameAndBadgeWrapper: {
      display: "flex",
      alignItems: "center",

      "& > p": {
        color: "#011533",
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "26px",
      },
    },

    homeAndBackWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "56px",
    },

    firstHeaderItem: {
      flex: 1,
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "15%",
      },
    },

    middleHeaderItem: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "35%",
      },
    },

    lastHeaderItem: {
      flex: 1,
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "50%",
      },
    },

    gridWrapper: {
      height: "100%",
      alignItems: "center",
    },

    backBtn: {
      cursor: "pointer",
      display: "flex",
      transition: ".3s all",
      width: "fit-content",

      "& > img": {
        marginRight: "18px",
      },
      "& > p": {
        color: "#011533",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "20px",
      },
    },

    homeBtn: {
      marginRight: "40px",
    },

    tabsWrapper: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },

    tabWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "6px",
    },

    tab: {
      cursor: "default",
      color: "rgba(0, 0, 0, 0.26)",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
    },

    activeTab: {
      color: "#000000",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "20px",
    },

    link: {
      cursor: "pointer",
    },

    separator: {
      background: "black",
      height: "2px",
      width: "30px",
      margin: "0 12px",
    },

    disabled: {
      background: "rgba(0, 0, 0, 0.26)",
    },

    navigationBtnsWrapper: {
      display: "flex",
      justifyContent: "end",
      marginRight: "56px",

      "& > img, & > div": {
        cursor: "pointer",
        marginLeft: "18px",
      },

      "& > div": {
        display: "flex",
        alignItems: "center",
        "& > span": {
          marginLeft: "10px",
          fontWeight: "bold",
          fontSize: "16px",
        },
      },
    },

    navigationBtn: {
      cursor: "pointer",
      border: "1px solid #273754",
      borderRadius: "16px",
      padding: "14px 52px",
      color: "#00112B",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      transition: ".3s all",
      margin: "0 6px",
      alignItems: "center",
      display: "flex",
      position: "relative",

      "&:hover": {
        transform: "scale(1.02)",
      },
    },

    navigationActive: {
      background: "#EE4360",
      border: "none",
      paddingRight: "65px",
      color: "#FFFFFF",
    },

    navigationDisabled: {
      background: "#C2C8D1",
      border: "none",
      paddingRight: "60px",
      color: "#FFFFFF",
    },

    marginRight20: {
      marginRight: "20px",
    },
    item: {
      padding: "10px",
      borderRadius: "8px",
      display: "flex",
      color: "rgb(46, 56, 96);",
    },

    itemActive: {},
    itemCanBeActive: {
      cursor: "pointer",
      "&:hover": {
        background: "#E2E2FC",
      },
    },
  };
};

export default style;
