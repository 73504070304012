/* eslint-disable react/no-unknown-property */
import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uploadFile as uploadFileToS3 } from "react-s3";

import CONFIG from "config/config";
import DragAndDropArea from "pages/flyer/flyerTypePage/components/DragAndDropArea";
import { UploadFile } from "shared/graphql/mutations/uploadFile";
import style from "pages/flyer/flyerTypePage/components/UploadPopUp/style";
import { ReactComponent as CloseIcon } from "static/media/close.svg";
import { insertCampaignLogs } from "../../../../../Logs/campaign/gql";
import { CAMPAIGN_LOG_ENUM_TYPES } from "../../../../../shared/constants";

interface IProps {
  classes: any;
  isOpen: boolean;
  onClose: () => void;
  setFiles: (file: any) => void;
  setIsFileSizeExceedErrorModalOpen: (val: boolean) => void;
  setIsUploading: (val: boolean) => void;
  setFileUploadProgress: (val: number) => void;
  setIsFileSizeExceedLimit: (val: boolean) => void;
  getFlyerInfo: () => Promise<void>;
}

const UploadPopUp: React.FC<IProps> = ({
  classes,
  isOpen,
  onClose,
  setFiles,
  setIsFileSizeExceedErrorModalOpen,
  setIsUploading,
  setFileUploadProgress,
  setIsFileSizeExceedLimit,
  getFlyerInfo,
}) => {
  const [uploadFile] = useMutation(UploadFile);
  const [tempFiles, setTempFiles] = useState<Array<any>>([]);

  const { campaignId } = useParams();

  const [loading, setLoading] = useState(false);

  if (!isOpen) {
    return null;
  }

  const onCancel = () => {
    if (!loading) {
      setFiles([]);
      onClose();
    }
  };

  const uploadHandler = async () => {
    const file = tempFiles[0];
    if (file.size > 25 * 1048576) {
      setIsFileSizeExceedLimit(true);
      setIsFileSizeExceedErrorModalOpen(true);
      setIsUploading(true);
      setFileUploadProgress(60);
      setTempFiles([]);
    } else {
      setIsFileSizeExceedLimit(false);
      setLoading(true);
      setIsUploading(true);
      setFileUploadProgress(0);

      try {
        const { key } = await uploadFileToS3(file, {
          bucketName: CONFIG.PDF_FLYER_BUCKET,
          dirName: "campaign-builder/" + `${Date.now().toString()}`,
          region: CONFIG.PDF_FLYER_BUCKET_REGION,
          accessKeyId: CONFIG.AWS.KEY,
          secretAccessKey: CONFIG.AWS.SECRET,
        });
        setFileUploadProgress(60);

        await uploadFile({
          variables: {
            file: key,
            fileName: file.name,
            fileType: file.type,
            campaignId: `${campaignId}`,
          },
        });
        await insertCampaignLogs([
          {
            campaignId,
            type: CAMPAIGN_LOG_ENUM_TYPES.UPLOAD_PDF_DESIGN,
            additionalInfo: `[CB] CAMPAIGN EDIT File name: ${file.name}`,
          },
        ]);
        await getFlyerInfo();
        setFiles([file]);
        setTempFiles([]);
      } catch (error) {
        console.log({ error }, "uploadFile error");
        return;
      }

      setFileUploadProgress(100);
      setLoading(false);
      setIsUploading(false);
    }
    onClose();
  };

  return (
    <div className={classes.modalWrapper}>
      <div
        className={classes.backdropFilter}
        onClick={(e) => {
          if (!loading) {
            e.stopPropagation();
            onCancel();
          }
        }}
      />
      <div className={classes.modal}>
        <div className={classes.closeButton} onClick={onCancel} cypress_id="closeBtn">
          <CloseIcon />
        </div>
        <p className={classes.header} cypress_id="modalHeader">
          Upload flyer design
        </p>
        <p className={classes.info} cypress_id="modalInfo">
          Upload a PDF file with the flyer design. Please, make sure that the design follows the selected flyer type for
          this campaign.
        </p>
        <p className={classes.info} cypress_id="modalInfo">
          The design must include clear crop lines that indicate exactly where the flyer edges will be cut. For
          additional information, see our printing rules guide.
        </p>
        <div className={classes.dragAndDropWrapper} cypress_id="dragAndDropArea">
          <DragAndDropArea files={tempFiles} setFiles={setTempFiles} />
        </div>
        <div className={classes.container}>
          <div className={classes.buttons}>
            <Button className={classes.button} variant="outlined" onClick={onCancel} cypress_id="cancelBtn">
              Cancel
            </Button>
            <Button
              disabled={!tempFiles.length || loading}
              className={classes.button}
              variant="outlined"
              onClick={uploadHandler}
              cypress_id="confirmBtn"
            >
              {loading && <CircularProgress size={20} color="secondary" style={{ marginRight: 10 }} />} Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(style)(UploadPopUp));
