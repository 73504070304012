import { withStyles } from "@material-ui/core";
import React, { memo } from "react";

import { ReactComponent as CloseIcon } from "static/media/close.svg";
import { ReactComponent as ErroIcon } from "static/media/error-icon.svg";
import style from "./style";
interface IProps {
  classes: any;
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
}

const FileSizeExceedErrorModal: React.FC<IProps> = ({ classes, isOpen, onClose, fileName }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className={classes.closeButton} onClick={onClose}>
          <CloseIcon></CloseIcon>
        </div>
        <div className={classes.content}>
          <div className={classes.img}>
            <ErroIcon></ErroIcon>
          </div>
          <div className={classes.text}>
            <p className={classes.text1}>The file {fileName} exceeds allowed limit.</p>
            {/* tmp solution  6mb*/}
            <p className={classes.text2}>You can upload a file with size up to 25Mb.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(style)(FileSizeExceedErrorModal));
