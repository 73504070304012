import { Fragment, forwardRef } from "react";
import { Box } from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../../../../static/media/calendar-icon";

const styles = {
  wrapper: {
    display: "flex",
  },
  text: {
    paddingLeft: "6px",
    cursor: "pointer",
    width: "100%",
  },
  icon: {
    marginTop: "2px",
    cursor: "pointer",
  },
  iconDisabled: {
    marginTop: "2px",
  },
  disabled: {
    paddingLeft: "6px",
    color: "#C2C8D1",
  },
  datePickerContainer: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E2E2FC",
    borderRadius: "4px",
    padding: "10.5px 13px 10.5px 7px",
  },
  error: {
    color: "#EE4360",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
  },
};

const ExampleCustomInput = forwardRef(({ value, onClick, disabled, onlyValue, isUS }, ref) => {
  const mockFormat = isUS ? "mm / dd / yy" : "dd / mm / yy";

  return (
    <span style={{ ...styles.wrapper }} ref={ref}>
      {onlyValue ? (
        <span>{value}</span>
      ) : (
        <Fragment>
          <CalendarIcon fill={disabled ? "#C2C8D1" : null} onClick={onClick} style={{ ...styles.icon }} />
          {disabled ? (
            <span style={{ ...styles.disabled }}>{value ? value : mockFormat}</span>
          ) : (
            <span onClick={onClick} style={{ ...styles.text }}>
              {value ? value : mockFormat}
            </span>
          )}
        </Fragment>
      )}
    </span>
  );
});

const OulinedCustomInput = forwardRef(({ value, onClick, disabled, onlyValue, isUS }, ref) => {
  const mockFormat = isUS ? "mm / dd / yy" : "dd / mm / yy";

  return (
    <span style={{ ...styles.wrapper }} ref={ref}>
      {onlyValue ? (
        <span>{value}</span>
      ) : (
        <Box width={"100%"} pb={1} style={{ ...styles.datePickerContainer }} cypress_id="campaignStart-datePicker">
          {disabled ? (
            <span style={{ ...styles.disabled }}>{value ? value : mockFormat}</span>
          ) : (
            <span onClick={onClick} style={{ ...styles.text }}>
              {value ? value : mockFormat}
            </span>
          )}
          <CalendarIcon
            fill={disabled ? "#C2C8D1" : "black"}
            onClick={onClick}
            style={disabled ? styles.disabledIcon : styles.icon}
          />
        </Box>
      )}
    </span>
  );
});

const DatePicker = ({ value, onChange, minDate, maxDate, disabled, error, onlyValue, isUS, outlined, className }) => {
  return (
    <div>
      <ReactDatePicker
        dateFormat={isUS ? "MMMM dd, yyyy" : "dd / MM / yyyy"}
        selected={value}
        onChange={onChange}
        customInput={
          outlined ? (
            <OulinedCustomInput onlyValue={onlyValue} isUS={isUS} />
          ) : (
            <ExampleCustomInput onlyValue={onlyValue} isUS={isUS} />
          )
        }
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        wrapperClassName={className}
      />
      {error && !onlyValue ? <div style={{ ...styles.error }}>{error}</div> : null}
    </div>
  );
};

export default DatePicker;
