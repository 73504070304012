import { withStyles } from "@material-ui/core";
import cn from "classnames";
import style from "pages/flyer/flyerTypePage/components/DragAndDropArea/style";
import React, { memo, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as FileStick } from "static/media/file-stick.svg";
import { ReactComponent as UploadDesign2 } from "static/media/upload-icon.svg";
interface IProps {
  classes: any;
  files: Array<File>;
  setFiles: (files: Array<File>) => void;
}

const DragAndDropArea: React.FC<IProps> = ({ classes, files, setFiles }) => {
  const [error, setError] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.some((f: Record<string, string | number>) => f.size > 25000000)) {
        setFiles([]);
        setError(`Error: The file exceeded the 25mb limit`);
      } else {
        setFiles(acceptedFiles);
      }
    },
    [files, setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,

    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-invalid-type") {
            setError(`Error: You can upload only PDF file`);
          }
        });
      });
    },
    maxFiles: 1,
    accept: "application/pdf",
    multiple: true,
  });

  return (
    <div className={classes.container}>
      <div {...getRootProps()} className={cn(classes.dropBox, isDragActive ? classes.dropBoxActive : null)}>
        <input {...getInputProps()} />
        {files.length ? (
          <div className={classes.fileName}>
            <FileStick></FileStick>
            {files[0].name}
          </div>
        ) : (
          <>
            <UploadDesign2 />
            <p>Click or move your file(s) here</p>
            {error ? <div className={classes.error}>{error}</div> : null}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(withStyles(style)(DragAndDropArea));
