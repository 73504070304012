import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import AudienceForm from "components/audience-form";
import { EDDMContext } from "components/post-hog-provider";

import { AudienceMapH2H, AudienceMapD2D } from "components/audience-map";
import { Badge } from "components/header/components/badge";
import Header from "components/header";
import BounceLoader from "components/loaders/bounce-loader";
import { IfElse } from "components/logic";
import { ProgressBarContext } from "components/progress-bar";
import { SnackBarContext } from "components/snack-bar";
import { DialogModalContext } from "components/dialog-modal";
import { LightTooltip } from "components/light-tooltip";
import { CustomTooltipContent } from "components/tooltip-content";
import { LoadingScreen } from "components/audience-map/components/LoadingScreen";
import CONFIG from "config/config";
import ROUTES from "config/routing";
import { gtagWrapper, checkIsNeedUpdateLastActiveStepState, validateCampaignName, formatCampaignName } from "utils";
import useExitPrompt from "Hooks/useExitPrompt";
import { NextButton } from "../../../components/next-button";
import { isDifferCityStates, handleCreateLocation } from "./utils";
import { CAMPAIGN_INTERNAL_STATUSES, NAVIGATION } from "../../constants";
import {
  updateCampaignExtraData,
  updateSelfServeCampaignData,
  createQuote,
  checkIsCampaignNameExist,
  updateCampaign as updateCampaignDB,
} from "../../../graphQL";
import BlockingOverlay from "../../../components/BlockingOverlay";
import style from "./style.js";
import { useStore } from "../../../store";
import { insertCampaignLogs } from "../../../Logs/campaign/gql";
import { CAMPAIGN_LOG_ENUM_TYPES } from "../../../shared/constants";
import ModalSelectChannel from "../../../pages/campaign/channel/Modal";

const libraries = ["geometry", "places", "drawing"];

const getAdditionalInfoH2H = (updatedLocations) => {
  const additionalInfoArr = updatedLocations.reduce((acc, address) => {
    const dates = address.startDate?.split("#") || [];
    const times = address.time?.split("#") || [];

    const datesString = dates.reduce((acc, date, index) => {
      const formattedDate = date
        ? `${moment(new Date(date.trim()).setHours(times[index])).format("YYYY-MM-DD HH")}:00`
        : " - ";

      acc.push(formattedDate);

      return acc;
    }, []);

    acc.push(`Location name: ${address.value}, id: ${address.id}, dates: ${datesString.join(", ")}`);

    return acc;
  }, []);

  return additionalInfoArr.join(", ");
};

const Audience = ({ classes }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY,
    libraries: libraries,
    language: "en",
  });

  const history = useHistory();
  const [, setShowExitPrompt] = useExitPrompt(false);

  const {
    costsCalculationData,
    country,
    errors: {
      cityMissionsLimitError,
      campaignNameDuplicates,
      costsCalculationError,
      existInvalidMissionsInH2H,
      haveLocationWithoutMission,
    },
    campaign: {
      channel,
      campaignName,
      flyerType,
      flyerWeight,
      campaignDuration,
      internalStatus,
      isSubmitted,
      id: campaignId,
      isD2D,
      lastActiveStep,
    },
    user: { accessDenied = true, id: userId },
    client: { id: clientId, name: clientName },
    city,
    missions,
    map: { loading, loadingPercentage },
    updateCampaign,
    setAddresses,
    updateErrors,
  } = useStore();
  const { isEddmEnabled } = useContext(EDDMContext);
  const fromHome = useMemo(() => lastActiveStep === "home", [lastActiveStep]);
  const isH2H = !isD2D;
  const isExistInvalidMissions = existInvalidMissionsInH2H;
  const isShowCosts = !!city;
  const { printing, distribution, tax, totalCost } = costsCalculationData?.detailedCost || {};

  // useContext
  const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);
  const runDialogModal = useContext(DialogModalContext);

  // useState
  const [shouldSaveData, setShouldSaveData] = useState(false);
  const [forceBadgeOpen, setForceBadgeOpen] = useState(false);
  const [googleMap, setGoogleMap] = useState(null);
  const [gTagEvents, setgTagEvents] = useState({ ss_campaign_aud_area: false, ss_campaign_aud_city: false });
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameModalOpen, setNameModalOpen] = useState(isEddmEnabled && fromHome);
  const [error, setError] = useState(true);
  const [validatingCampaignName, setValidatingCampaignName] = useState(false);
  const [tempCampaignName, setTempCampaignName] = useState("");

  /**
   * detect any change between the original city state from db and current city state
   */
  const [cityChanged, setCityChanged] = useState(false);
  const [oldAddresses, setOldAddresses] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [notEditedCity, setNotEditedCity] = useState(null);
  const [isEdited, setIsEdited] = useState(false);

  // useRef
  const badgeOpenedByForce = useRef(false);
  const cityFromDbRef = useRef(null);
  const copiedCityFromDbRef = useRef(false);
  const clickSaveButtonRef = useRef(false);

  // useMemo
  const geocoder = useMemo(
    () => (window?.google?.maps?.Geocoder ? new window.google.maps.Geocoder() : null),
    [window.google, isLoaded]
  );

  useEffect(() => {
    if (!notEditedCity) {
      setNotEditedCity(city);
    }
  }, [city]);

  useEffect(() => {
    if (!isEddmEnabled && nameModalOpen) {
      setNameModalOpen(false);
      setError(null);
    }
  }, [isEddmEnabled]);

  useEffect(() => {
    if (city && notEditedCity) {
      if (JSON.stringify(city.addresses) !== JSON.stringify(notEditedCity.addresses)) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [city && JSON.stringify(city.addresses), isSaved]);

  const isNextActive = useMemo(() => {
    return (
      !cityMissionsLimitError?.message &&
      (!!missions.length || isSubmitted) &&
      campaignName &&
      !campaignNameDuplicates &&
      !costsCalculationError &&
      !isExistInvalidMissions &&
      !haveLocationWithoutMission
    );
  }, [
    cityMissionsLimitError,
    missions.length,
    campaignName,
    campaignNameDuplicates,
    costsCalculationError,
    isExistInvalidMissions,
    haveLocationWithoutMission,
    isSubmitted,
  ]);

  const TooltipContent = useMemo(() => {
    if (!city) {
      return <CustomTooltipContent title="No city!" content="Please, choose a city to get started." />;
    }

    if (cityMissionsLimitError?.message) {
      return <CustomTooltipContent title={cityMissionsLimitError.title} content={cityMissionsLimitError.message} />;
    }

    if (isExistInvalidMissions) {
      return <CustomTooltipContent title="Invalid Dates!" content="Please delete the invalid dates." />;
    }

    if (haveLocationWithoutMission) {
      return (
        <CustomTooltipContent title="Not enough distributors!" content="One or more locations has no distributors." />
      );
    }

    return "";
  }, [city, cityMissionsLimitError, costsCalculationError, isExistInvalidMissions, haveLocationWithoutMission]);

  const formattedCity = useMemo(() => {
    if (!city) {
      return null;
    }

    const { addresses, id, isVisible, lat, lng, name, nameWithState, isAddressesPopupOpened } = city;
    const mappedAddresses = addresses.reduce((acc, address) => {
      if (address.id) {
        if ("showDeleteAddressPopup" in address) {
          delete address.showDeleteAddressPopup;
        }
        if ("deleteOnClick" in address) {
          delete address.deleteOnClick;
        }
        if ("draggable" in address) {
          delete address.draggable;
        }
        if (address.startDate && typeof address.startDate === "string") {
          // Remove user's timezone from the start dates stored in mongo.
          // Just save the date
          address.startDate = address.startDate
            .split("#")
            .map((date) => date.split("GMT")[0])
            .join("#");
        }
        acc.push(address);
      }
      return acc;
    }, []);

    return {
      addresses: mappedAddresses,
      id,
      isVisible,
      lat,
      lng,
      name,
      nameWithState,
      isAddressesPopupOpened,
    };
  }, [city]);

  // useEffect
  useEffect(() => {
    setShowExitPrompt(cityChanged);
  }, [cityChanged]);

  useEffect(() => {
    /**
     * copiedCityFromDbRef.current = true means that we already copied city state from db.
     * This ensures that we only copy city once.
     */
    if (copiedCityFromDbRef.current) {
      return;
    }

    cityFromDbRef.current = city ? { ...city } : null;
    copiedCityFromDbRef.current = true;
  }, [city]);

  useEffect(() => {
    if (!formattedCity) {
      setCityChanged(false);
      return;
    }

    if (copiedCityFromDbRef.current) {
      setCityChanged(isDifferCityStates(formattedCity, cityFromDbRef.current));
    }
  }, [formattedCity]);

  useEffect(() => {
    // should expand cost tooltip when client add mission at first
    let closeBadgeByForceTimer = null;

    if (forceBadgeOpen) {
      if (!missions.length) {
        setForceBadgeOpen(false);
      } else {
        closeBadgeByForceTimer = setTimeout(() => {
          setForceBadgeOpen(false);
        }, 3000);
      }
    } else {
      if (!badgeOpenedByForce.current && missions.length) {
        badgeOpenedByForce.current = true;
        setForceBadgeOpen(true);
      }
    }

    return () => {
      if (closeBadgeByForceTimer) {
        clearTimeout(closeBadgeByForceTimer);
      }
    };
  }, [forceBadgeOpen, missions.length]);

  useEffect(() => {
    if (costsCalculationError) {
      runDialogModal({
        disabledClose: true,
        title: "Internal Error",
        contentText: (
          <Box className={classes.internalErrorModal}>
            <div>Something went wrong</div>
            <div>Please, contact our support</div>
          </Box>
        ),
        ctaLabel: "Contact support",
        customCtaStyles: { width: "208px" },
        handleCTAClick: () => {
          window.open(
            `mailto:support@oppizi.com?subject=Internal problem - ${country.name}&body=Please,%20describe%20your%20problem`
          );
        },
      });
    }
  }, [costsCalculationError]);

  useEffect(() => {
    if (!campaignId && !channel) {
      // redirect home
      // history.push(generatePath(ROUTES.CAMPAIGN_CHANNEL, { campaignId, clientId }));
    }
  }, [accessDenied, channel, campaignId]);

  useEffect(() => {
    if (!shouldSaveData) return;
    saveCampaign();
  }, [shouldSaveData]);

  useEffect(() => {
    if (city) {
      if (city?.addresses?.length === 1 && !gTagEvents.ss_campaign_aud_area) {
        gtagWrapper({
          event: "ss_campaign_aud_area",
          client_id: clientId?.toString(),
          user_id: userId,
          campaign_id: campaignId,
          select_tool_type: city.addresses[0].type,
        });
        setgTagEvents((gTagEvents) => ({ ...gTagEvents, ss_campaign_aud_area: true }));
      }
      if (!gTagEvents.ss_campaign_aud_city) {
        gtagWrapper({
          event: "ss_campaign_aud_city",
          client_id: clientId?.toString(),
          user_id: userId,
          campaign_id: campaignId,
          city_id: city.id.toString(),
        });
        setgTagEvents((gTagEvents) => ({ ...gTagEvents, ss_campaign_aud_city: true }));
      }
    }
  }, [city]);

  useEffect(() => {
    if (!city && geocoder && googleMap) {
      geocoder.geocode({ address: country.name }, (results, status) => {
        if (status === "OK") {
          googleMap.setCenter(results[0].geometry.location);
          googleMap.fitBounds(results[0].geometry.viewport);
        }
      });
    } else if (!!city && !!googleMap) {
      setGoogleMap(null);
    }
  }, [city, geocoder, googleMap]);

  useEffect(() => {
    if (!oldAddresses.length && city && city.addresses.length) {
      setOldAddresses(city.addresses);
    }
  }, []);

  useEffect(() => {
    if (city && oldAddresses.length && city.addresses.length !== oldAddresses.length) {
      setIsSaved(false);
    }
  }, [city && city.addresses.length]);

  const cityAddressesTimes =
    !isD2D &&
    city &&
    city.addresses.reduce((acc, item) => {
      acc += item.time;
      return acc;
    }, "");
  const oldAddressesTimes =
    !isD2D &&
    oldAddresses.reduce((acc, item) => {
      acc += item.time;
      return acc;
    }, "");

  useEffect(() => {
    if (!isD2D && cityAddressesTimes !== oldAddressesTimes) {
      setIsSaved(false);
    }
  }, [oldAddressesTimes, cityAddressesTimes]);

  useEffect(() => {
    if (campaignNameDuplicates) {
      setError("Duplicate campaign name. Please choose another.");
      return;
    }
  }, [campaignNameDuplicates]);

  useEffect(() => {
    if (nameModalOpen) {
      if (!tempCampaignName) {
        setError(true);
        return;
      }

      if (validateCampaignName(tempCampaignName) !== null) {
        setError(true);
      } else {
        setError(null);
      }

      const timerId = setTimeout(() => {
        setError(validateCampaignName(tempCampaignName));
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    } else {
      setError(null);
    }
  }, [tempCampaignName]);

  const onChangeCampaignName = async (name) => {
    // updateCampaign({ campaignName: formatCampaignName(name) });
    setTempCampaignName(formatCampaignName(name));
    updateErrors({ campaignNameDuplicates: false });
  };

  // useCallback
  const saveCampaign = useCallback(
    async (goToNextPage) => {
      setShowExitPrompt(false);
      setIsBlocked(true);
      setShouldSaveData(false);
      runProgressBar(60);
      setIsLoading(true);

      try {
        const newSelfServeAddresses = formattedCity.addresses.filter(
          (address) => !address.isFixedLocation && !address.campaignId && address.id.includes("temp")
        );
        if (newSelfServeAddresses.length) {
          const savedLocations = [];
          for (const newSelfServeAddress of newSelfServeAddresses) {
            const savedLocation = await handleCreateLocation(newSelfServeAddress, city, campaignId);
            if (savedLocation) {
              savedLocations.push(savedLocation);
            }
          }

          const updatedAddresses = formattedCity.addresses.map((address) => {
            if (address.id.includes("temp")) {
              const savedLocationAddress = savedLocations.find((location) => location.name === address.value);
              return {
                ...address,
                value: savedLocationAddress.name,
                name: savedLocationAddress.name,
                id: savedLocationAddress.id,
                isFixedLocation: false,
              };
            } else {
              return address;
            }
          });
          formattedCity.addresses = updatedAddresses;
          cityFromDbRef.current = formattedCity;
          setAddresses(updatedAddresses);
        }
        const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
          stateLastActiveStep: lastActiveStep,
          newLastActiveStep: "audience",
          isDM: false,
        });

        const payloadUpdateExtraData = {
          campaignId: campaignId,
          campaignDuration,
          missionsCount: distribution.quantity,
          totalCosts: totalCost,
          taxes: tax,
          flyersCount: printing.quantity,
        };

        if (isNeedUpdateLastActiveStep && goToNextPage) {
          payloadUpdateExtraData.lastActiveStep = "audience";
        }

        await updateCampaignExtraData(payloadUpdateExtraData);

        await updateSelfServeCampaignData({
          campaignId: campaignId,
          selectedCities: [formattedCity],
        });

        if (isNeedUpdateLastActiveStep && goToNextPage) {
          updateCampaign({ lastActiveStep: "audience" });
        }

        if (!isSaved) {
          const logs = [];

          const deletedAddresses = oldAddresses.filter(
            (oldItem) => !city.addresses.find((newItem) => newItem.id === oldItem.id)
          );

          if (deletedAddresses.length) {
            if (isD2D) {
              logs.push({
                campaignId: campaignId,
                type: CAMPAIGN_LOG_ENUM_TYPES.DELETE_AREA,
                additionalInfo: `[CB] CAMPAIGN EDIT Items ids: ${deletedAddresses.map((item) => item.id).join(", ")}`,
              });
            } else {
              logs.push({
                campaignId: campaignId,
                type: CAMPAIGN_LOG_ENUM_TYPES.DELETE_LOCATION,
                additionalInfo: `[CB] CAMPAIGN EDIT ${getAdditionalInfoH2H(deletedAddresses)}`,
              });
            }
          }

          const addedAddresses = city.addresses.filter(
            (newItem) => !oldAddresses.find((oldItem) => oldItem.id === newItem.id)
          );

          if (addedAddresses.length) {
            if (isD2D) {
              logs.push({
                campaignId: campaignId,
                type: CAMPAIGN_LOG_ENUM_TYPES.ADD_AREA,
                additionalInfo: `[CB] CAMPAIGN EDIT Items ids: ${addedAddresses.map((item) => item.id).join(", ")}`,
              });
            } else {
              logs.push({
                campaignId: campaignId,
                type: CAMPAIGN_LOG_ENUM_TYPES.ADD_LOCATION,
                additionalInfo: `[CB] CAMPAIGN EDIT ${getAdditionalInfoH2H(addedAddresses)}`,
              });
            }
          }

          const editedAddress = city.addresses.filter((newItem) =>
            oldAddresses.find(
              (oldItem) =>
                newItem.id === oldItem.id && (newItem.startDate !== oldItem.startDate || newItem.time !== oldItem.time)
            )
          );

          if (editedAddress.length && !isD2D) {
            logs.push({
              campaignId: campaignId,
              type: CAMPAIGN_LOG_ENUM_TYPES.EDIT_LOCATION,
              additionalInfo: `[CB] CAMPAIGN EDIT ${getAdditionalInfoH2H(editedAddress)}`,
            });
          }

          if (logs.length) {
            setOldAddresses(city.addresses);
            await insertCampaignLogs(logs);
          }
        }

        await setQuoteNumber(campaignId);

        gtagWrapper({
          event: "ss_campaign_aud_submitted",
          client_id: clientId?.toString(),
          user_id: userId,
          campaign_id: campaignId,
          city_id: JSON.stringify(formattedCity?.id),
          nr_of_created_areas: formattedCity?.addresses?.length || 0,
          nr_of_missions: missions.length,
          nr_of_flyers: printing.quantity,
          total_cost: totalCost.toLocaleString(),
        });

        if (goToNextPage) {
          nextBtnClicked(campaignId);
        }

        runSnackBar({
          type: "success",
          msg: `Saved.`,
          vertical: "bottom",
          horizontal: "right",
        });
        runProgressBar(80);
        setNotEditedCity(city);
        setIsSaved(true);
      } catch (error) {
        runSnackBar({
          type: "error",
          msg: `Server error failed to save company. ${error.message}`,
          vertical: "bottom",
          horizontal: "right",
        });
      }

      runProgressBar(-1);
      setIsBlocked(false);
      setIsLoading(false);
    },
    [
      city,
      campaignName,
      country,
      campaignId,
      flyerType,
      flyerWeight,
      channel,
      distribution?.quantity,
      isSaved,
      oldAddresses.length,
    ]
  );

  const setQuoteNumber = async (campaignId) => {
    const quote = await createQuote(campaignId);
    updateCampaign({ quote });
  };

  const nextBtnClicked = (id) => {
    return !internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
      ? history.push(generatePath(ROUTES.FLYER, { campaignId: id, clientId }))
      : history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId: id, clientId }));
  };

  const headerActions = {
    BACK: {
      action: () => history.push(generatePath(ROUTES.CAMPAIGN_CHANNEL, { campaignId, clientId })),
    },
    SAVE: {
      action: () => setShouldSaveData(true),
    },
    NEXT: {
      isActive: !cityMissionsLimitError?.message && !!missions.length,
      action: async () => {
        if (isSubmitted) {
          runProgressBar(60);
          nextBtnClicked(campaignId);
          runProgressBar(80);
          runProgressBar(-1);
        } else {
          clickSaveButtonRef.current = false;
          await saveCampaign(true);
        }
      },
      hintPlacement: "bottom",
      hint: cityMissionsLimitError?.message || "",
    },
  };

  const handleClickSave = async () => {
    if (!cityChanged) {
      return;
    }

    clickSaveButtonRef.current = true;
    cityFromDbRef.current = formattedCity ? { ...formattedCity } : null;

    await saveCampaign();

    setCityChanged(false);
  };

  const persistCampaignName = async () => {
    await updateCampaignDB({ campaignId, name: tempCampaignName });
    await updateCampaignExtraData({ lastActiveStep: "audience", campaignId });
    updateCampaign({ lastActiveStep: "audience", campaignName: tempCampaignName });
    setNameModalOpen(false);
  };

  const handleNameSave = async () => {
    setValidatingCampaignName(true);
    if (tempCampaignName) {
      const { campaignIds } = await checkIsCampaignNameExist(clientId, tempCampaignName.trim());
      if (campaignIds.length && (!campaignId || !campaignIds.includes(campaignId))) {
        setValidatingCampaignName(false);
        return updateErrors({ campaignNameDuplicates: true });
      }
      updateErrors({ campaignNameDuplicates: false });
      await persistCampaignName();
      setValidatingCampaignName(false);
    }
  };

  return (
    <Fragment>
      {isBlocked ? <BlockingOverlay /> : null}
      <Box className={classes.scrollWrapper}>
        <Helmet>
          <title>Audience</title>
        </Helmet>
        <Header
          tabs={NAVIGATION}
          activeTabIndex={1}
          headerActions={headerActions}
          hideBackButton={isSubmitted || campaignId}
          hasError={!isNextActive || isEdited}
        />
        <Box className={classes.containerWrapper}>
          <Box className={classes.container}>
            <ModalSelectChannel
              isOpen={nameModalOpen}
              onClose={() => setNameModalOpen(false)}
              channel={channel}
              clientName={clientName}
              onChangeCampaignName={onChangeCampaignName}
              campaignStateName={tempCampaignName}
              error={error}
              onSave={handleNameSave}
              loading={loading}
            />
            <Box className={classes.leftContainer}>
              <IfElse condition={isLoaded}>
                <Box className={classes.mapWrapper}>
                  {loading && (
                    <div className={classes.locationsLoading}>
                      <LoadingScreen loadingPercentage={loadingPercentage} />
                    </div>
                  )}
                  <IfElse condition={!!city}>
                    {isH2H ? (
                      <AudienceMapH2H isSubmitted={isSubmitted} />
                    ) : (
                      <AudienceMapD2D isSubmitted={isSubmitted} />
                    )}
                    <GoogleMap
                      onLoad={(newMap) => {
                        if (!googleMap) setGoogleMap(newMap);
                      }}
                      mapContainerStyle={{
                        width: "100%",
                        marginLeft: 0,
                      }}
                      zoom={2}
                      options={{
                        clickableIcons: false,
                        zoomControl: true,
                        disableDefaultUI: true,
                        keyboardShortcuts: false,
                      }}
                    />
                  </IfElse>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <BounceLoader />
                </Box>
              </IfElse>
            </Box>
            <Box>
              <IfElse condition={isLoaded}>
                <Box className={classes.audienceFormWrapper}>
                  <div className={classes.header}>
                    <div className={classes.campaignName}>{campaignName}</div>
                    {isShowCosts ? <Badge totalClassName={classes.totalCost} forceOpen={forceBadgeOpen} /> : null}
                  </div>
                  {/** disable audience form while the map is loading */}
                  <AudienceForm isSubmitted={isSubmitted || loading} />
                  <Box className={classes.nextBtnWrapper} style={loading ? { pointerEvents: "none" } : {}}>
                    {!isSubmitted && (
                      <Box style={{ marginRight: "20px" }}>
                        <NextButton
                          label={isSaved ? "Saved" : "Save"}
                          loading={clickSaveButtonRef.current && isLoading}
                          isSaveButton={true}
                          isSaveActive={cityChanged}
                          onClick={handleClickSave}
                          isSaved={isSaved}
                        />
                      </Box>
                    )}
                    {!isNextActive ? (
                      <LightTooltip title={TooltipContent} placement="top">
                        <span>
                          <NextButton isNextActive={isNextActive} />
                        </span>
                      </LightTooltip>
                    ) : (
                      <NextButton
                        loading={!clickSaveButtonRef.current && isLoading}
                        isNextActive={isNextActive}
                        onClick={headerActions.NEXT.action}
                      />
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                  <BounceLoader />
                </Box>
              </IfElse>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default memo(withStyles(style)(Audience));
