/* eslint-disable react/no-unknown-property */
import { Box, Typography, makeStyles } from "@material-ui/core";
import cn from "classnames";
import { TemplateConfig } from "pages/flyer/builder/drawer";
import {
  getFlyerSizeDisplay,
  getFlyerWidthDisplay,
  getFlyerHeightDisplay,
  displayInInch,
} from "pages/flyer/builder/utils";
import { PDFPreview } from "./PDFPreview";
import { style } from "./style";

const useStyles = makeStyles(() => style);

type FlyerSizePreviewProps = {
  flyerTypeOption: TemplateConfig;
  countryCode?: string;
  file?: string;
  files?: any[];
  cypressId: string;
  isDM?: boolean;
};

export const FlyerSizePreview = ({
  flyerTypeOption,
  cypressId,
  countryCode = "US",
  file = "",
  files,
  isDM,
}: FlyerSizePreviewProps) => {
  const classes = useStyles();
  const {
    name,
    widthInMm,
    heightInMm,
    gapForArrow = 0,
    placeholder,
    horizontalArrow,
    verticalArrow,
    displayWidth,
    displayHeight,
    previewPadding,
    verticalArrowHeight,
    horizontalArrowWidth,
  } = flyerTypeOption;
  const isInInch = displayInInch(countryCode);
  const displaySize = getFlyerSizeDisplay(widthInMm, heightInMm, isInInch);

  const hasFile = !!file && !!files && !!files.length;

  return (
    <Box className={classes.previewWrapper} cypress_id={cypressId}>
      <Box className={classes.previewText}>{hasFile ? "Preview" : "Flyer size"}</Box>
      <Box display="flex" paddingTop={"30px"}>
        <Box className={classes.verticalArrowWrapper}>
          <img
            src={verticalArrow}
            style={{
              paddingTop: `${gapForArrow}px`,
              height: `${verticalArrowHeight}px`,
            }}
          />
          <Typography className={`${classes.dimensionText} ${classes.heightText}`}>
            {getFlyerHeightDisplay(heightInMm, isInInch)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box className={classes.imageWrapper} style={{ width: displayWidth, height: displayHeight }}>
            {hasFile && <PDFPreview file={file} width={displayWidth} height={displayHeight} padding={previewPadding} />}
            {hasFile ? (
              // <img
              //   src={preview}
              //   style={{ width: displayWidth, height: displayHeight, position: "absolute", top: 0, left: 0 }}
              // />
              <div
                style={{
                  width: displayWidth,
                  height: displayHeight,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  border: "1px solid gray",
                }}
              />
            ) : (
              <img
                src={placeholder}
                style={{ width: displayWidth, height: displayHeight, position: "absolute", top: 0, left: 0 }}
              />
            )}

            {!hasFile && (
              <Box className={cn(classes.nameWrapper, { [classes.dmNameWrapper]: isDM })}>
                <Typography>{name}</Typography>
                <Typography>{displaySize}</Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.horizontalArrowWrapper}>
            <img
              src={horizontalArrow}
              style={{
                paddingLeft: `${gapForArrow}px`,
                width: `${horizontalArrowWidth}px`,
              }}
            />
            <Typography className={`${classes.dimensionText} ${classes.widthText}`}>
              {getFlyerWidthDisplay(widthInMm, isInInch)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FlyerSizePreview;
