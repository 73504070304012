const style = {
  scrollWrapper: {
    // overflow: "scroll",
  },
  containerWrapper: {
    width: "100%",
    // minWidth: "1200px",
    height: "calc(100vh - 80px)",
    display: "flex",
    justifyContent: "center",
    overflowY: "scroll",
    paddingRight: "8px",
  },
  header: {
    paddingLeft: "25px",
    marginBottom: "25px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  campaignName: {
    fontWeight: "bold",
    fontSize: 28,
    marginRight: 24,
  },
  totalCost: { margin: 0 },
  // background: {
  //   width: "100vw",
  //   // minWidth: "1200px",
  //   height: "calc(100vh - 80px)",
  //   display: "flex",
  //   position: "absolute",
  // },

  // white: {
  //   background: "#FFFFFF",
  //   width: "50%",
  //   height: "100%",
  // },
  // gray: {
  //   background: "#F9F9FF",
  //   width: "50%",
  //   height: "100%",
  // },

  container: {
    width: "100%",
    zIndex: 2,
    display: "flex",

    "& > div": {
      width: "100%",
      height: "100%",
    },

    "& > div:nth-child(2)": {
      padding: "40px 0 86px 0",
      position: "relative",
    },
  },

  leftContainer: {
    width: "100%",
    height: "100%",
    minWidth: "500px",
  },

  asideText: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },

  mapWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    padding: 0,
    position: "relative",
  },

  welcomeModalRow: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    marginBottom: "6px",

    "& p": {
      color: "#011533",
    },

    "& > div": {
      display: "flex",
    },
  },

  welcomeModalRowNumber: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    marginRight: "6px",
    width: "12px",
  },

  noteTitle: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#EE4360",
    marginTop: "32px",
    marginBottom: "8px",
  },

  noteRow: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#011533",
  },

  noteImg: {
    display: "flex",
    margin: "32px auto 24px",
  },

  audienceFormWrapper: {
    width: "80%",
    height: "100%",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // height: "100%",
  },

  nextBtnWrapper: {
    display: "flex",
    justifyContent: "end",
    margin: "26px 0 0",
    position: "absolute",
    right: "20%",
    bottom: "80px",
  },

  navigationBtn: {
    border: "1px solid #273754",
    borderRadius: "16px",
    padding: "14px 52px",
    color: "#00112B",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    alignItems: "center",
    display: "flex",
    width: "208px",
    position: "relative",
  },

  navigationActive: {
    cursor: "pointer",
    background: "#EE4360",
    border: "none",
    paddingRight: "65px",
    color: "#FFFFFF",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  navigationDisabled: {
    cursor: "default",
    background: "#C2C8D1",
    border: "none",
    paddingRight: "60px",
    color: "#FFFFFF",
  },

  descriptionsContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "20px",
  },

  descriptionWrapper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "14px",
  },

  selectDescription: {
    display: "flex",
    alignItems: "Center",
    backgroundColor: "#F9F9FF",
    color: "#1D2943",
    padding: "2px 9px",
    gap: "4px",
    width: "fit-content",
    borderRadius: "8px",
  },

  locationsLoading: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff3d",
    position: "absolute",
    top: 0,
  },

  locationDescription: {
    display: "flex",
    alignItems: "Center",
    padding: "2px 9px",
    gap: "4px",
  },

  divider: {
    border: "1px solid #E2E2FC",
    height: "26px",
    width: "0px",
    margin: "0px 4px",
  },

  inputWrapper: {
    width: "385px",
    height: "34px",
    paddingLeft: "9px",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    backgroundColor: "white",
    color: "#707087",
  },

  welcomModalBody: {
    marginBottom: "28px",
  },

  marginTop_10: {
    marginTop: "10px",
  },

  internalErrorModal: {
    padding: "10px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default style;
