import { Fragment, useContext } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { CAMPAIGN_ENUM_TYPES } from "shared/constants";
import { IfElse } from "components/logic";
import { EDDMContext } from "components/post-hog-provider";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

type ChannelCardItem = {
  img: string;
  disabledImg: string;
  type: string;
  title: string;
  subTitle: string;
  benefits: Record<string, string>[];
};

interface ChannelCardProps {
  onClick?: (channel: string | undefined, disabled: boolean) => void;
  item: ChannelCardItem;
  active: boolean;
  disabled: boolean;
  available: boolean;
  countryName: string;
}

export const ChannelCard: React.FC<ChannelCardProps> = ({
  onClick = () => {},
  item,
  active,
  disabled: disabledProp,
  available,
  countryName,
}) => {
  const { isEddmEnabled } = useContext(EDDMContext);
  const classes = useStyles();
  const isDirectMail = item.type === CAMPAIGN_ENUM_TYPES.DIRECTMAIL;
  const disabled = !isEddmEnabled && isDirectMail ? true : disabledProp;

  return (
    <Box
      className={cn(classes.cardWrapper, disabled ? classes.disabled : {}, active ? classes.active : {})}
      onClick={() => onClick(item.type, disabled)}
      // @ts-ignore
      cypress_id={`channel-card-${item?.type.toLowerCase()}`}
    >
      <Box>
        <img src={disabled ? item.disabledImg : item.img} className={classes.image} />
        <Box display="flex" alignItems={"center"} justifyContent={"space-between"} className={classes.titleWrapper}>
          <Typography className={cn(classes.title, disabled ? classes.disabled : {})}>{item.title}</Typography>
          {available && !disabled && isDirectMail && isEddmEnabled ? (
            <div className={classes.newLabel}>NEW!</div>
          ) : null}
        </Box>
        <Typography className={cn(classes.subTitle, disabled ? classes.disabled : {})}>{item.subTitle}</Typography>
      </Box>
      <Box className={classes.footerWrapper}>
        <IfElse condition={isDirectMail ? available && isEddmEnabled : available}>
          <Box className={classes.benefitsWrapper}>
            {item.benefits?.map((benefit, index) => (
              <Box key={index} className={classes.benefitItem}>
                <img src={benefit.icon} />
                <Typography>{benefit.text}</Typography>
              </Box>
            ))}
          </Box>
          {/* { [classes.unavailable]: !isDirectMail } */}
          <Typography className={cn(classes.comingSoon)}>
            {isDirectMail ? (
              "Coming soon!"
            ) : (
              <Fragment>
                <span>Currently unavailable in</span> <span>{countryName}</span>
              </Fragment>
            )}
          </Typography>
        </IfElse>
      </Box>
    </Box>
  );
};
