export const styles = {
  containerWrapper: {
    width: "100%",
    height: "calc(100vh - 80px)",
    display: "flex",
    justifyContent: "center",
    overflowY: "scroll",
  },
  header: {
    paddingLeft: "25px",
    marginBottom: "25px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  // campaignName: {
  //   fontWeight: "bold",
  //   fontSize: 28,
  //   marginRight: 24,
  // },

  container: {
    width: "100%",
    height: "calc(100vh - 80px)",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
  },

  mapContainer: {
    width: "100%",
    height: "calc(100vh - 154px)",
    minWidth: "500px",
  },

  mapWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    padding: 0,
    position: "relative",
  },

  searchBarWrapper: {
    display: "flex",
    width: "700px",
  },

  segmentsHeaderWrapper: {
    width: "100%",
    height: "74px",
    background: "#FFFFFF",
    padding: "0 30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // height: "100%",
  },

  radiusWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
    "& span": {
      fontFamily: "'Proxima Nova', 'sans-serif'",
      marginRight: "10px",
    },
  },

  radiusSelect: {
    minWidth: "100px",
  },

  nextBtnWrapper: {
    display: "flex",
    justifyContent: "end",
  },

  locationsChipsWrapper: {
    padding: "0px 30px",
    background: "#ffffff",
    whiteSpace: "nowrap",
    overflow: "scroll",
    height: "50px",
  },

  locationChip: {
    marginRight: "10px",
  },

  locationsLoading: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff",
    position: "absolute",
    top: 0,
  },

  locationDescription: {
    display: "flex",
    alignItems: "Center",
    padding: "2px 9px",
    gap: "4px",
  },

  internalErrorModal: {
    padding: "10px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
