const style = {
  wrapper: {
    height: "100vh",
    background: "#F9F9FF",
  },
  page: {
    width: "100%",
    height: "calc(100vh - 80px)",
    overflow: "scroll",
  },
  contentWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    height: "100%",
    margin: "0 auto",
    minWidth: "1200px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginBottom: "6px",
  },
  rightSide: {
    position: "relative",
    padding: "32px",
    paddingBottom: "110px",
  },
  leftSide: {
    display: "flex",
    background: "#FFFFFF",
    paddingLeft: "10%",
  },

  leftSideDM: {
    paddingLeft: "0px",
  },
  calculationErrorWrapper: {
    top: 0,
    left: 0,
  },
  download: {
    fontStyle: "normal",
    fontWeight: 700,
    marginTop: 10,
    display: "flex",
    cursor: "pointer",
    textDecoration: "underline",
    color: "#EE4360",
  },
  downloadWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  downloadIcon: {
    objectFit: "contain",
    marginRight: 4,
  },
  header: { display: "flex", alignItems: "center" },
  campaignName: {
    fontWeight: "bold",
    fontSize: 28,
    marginRight: 24,
  },
  campaignPrice: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#0B2456",
    padding: "8px 6px",
    background: "#E8E8FD",
    borderRadius: "8px",
  },
  flyerSettings: {
    marginTop: 26,
    display: "flex",
  },
  settings_row: {
    marginRight: 24,
    width: 250,
  },
  flyerSettingsTip: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    textDecoration: "underline",
    maxWidth: "max-content",
    color: "#000000",
    cursor: "pointer",
  },
  uploadDesign: {
    display: "flex",
    flexDirection: "column",
    marginTop: 32,
    height: "60px",
  },
  qrCodeBlock: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    marginLeft: -14,
  },
  message: {
    marginTop: 10,
    fontSize: "16px",
    color: "#011533",

    "& > div": {
      marginTop: 10,
      display: "flex",
      alignItems: "center",
    },
  },
  marker: {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
    backgroundColor: "#1D2943",
    marginRight: "10px",
  },
  example: {
    fontWeight: "bold",
    color: "#EE4360",
    textDecoration: "underline",
  },
  infoIcon: {
    marginLeft: 10,
    cursor: "pointer",
  },

  nextBtn: {
    cursor: "pointer",
    background: "#EE4360",
    border: "none",
    borderRadius: "16px",
    padding: "14px 52px",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    transition: ".3s all",
    alignItems: "center",
    display: "flex",
    width: "208px",
    position: "relative",
    justifyContent: "center",
    marginTop: "174px",
    marginLeft: "350px",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },

  nextBtnDisabled: {
    background: "#C2C8D1",
    color: "#FFFFFF",
    "&:hover": {
      transform: "none",
    },
  },

  // nextBtn: {
  //   background: "#EE4360",
  //   color: "white",
  //   borderRadius: "16px",
  //   fontWeight: "bold",
  //   padding: "10px",
  //   width: "200px",
  //   textAlign: "center",
  //   transition: "all 0.3s",
  //   marginTop: "20px",
  //   "&:hover": {
  //     background: "#EE1760",
  //   },
  // },
  // nextBtnDisabled: {
  //   background: "black",
  // },

  qrCodeInput: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
  },
  error: {
    color: "#FC0202",
  },

  addfile: {
    borderRadius: 4,
    display: "flex",
    height: "56px",
    width: "56px",
    border: "1px dashed #798498",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 30,
    marginRight: 20,
    cursor: "pointer",
  },
  vlock: {
    marginTop: 8,
    height: 56,
    display: "flex",
    alignItems: "center",
    color: "#798498",
    cursor: "default",
  },
  uplgile: {
    display: "flex",
    marginTop: 20,
  },
  paprlc: {
    marginRight: 10,
  },
  popover: {
    padding: "10px",
    maxWidth: "300px",
  },
  linearProgress: {
    marginTop: 8,
    width: 194,
    height: 6,
    borderRadius: 4,
    background: "#EFEFEF",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#EE4360",
      borderRadius: 4,
    },
  },
  uploadingText: {
    marginTop: 6,
    color: "#798498",
  },
};

export default style;
